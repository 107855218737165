import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, Publish as PublishIcon } from '@material-ui/icons';
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import { NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { DateUtils } from "../../../../../utils/dateUtils";
import styles from "../../style";

const HomeLeaveDetails = ({ handleLeaveFileChange, getLeaveRowId, getLeaveDocumentsList, getLeaveRowDeleteId, handleStaffLeaveDetailsEdit, homeLeaveInfo }) => {
  const classes = styles();

  return (
    <Box p={2}>
      <Typography variant="h6">
        घर बिदा
      </Typography>
      <TableContainer >
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Educational Details">
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell colSpan={2}>मिति</TableCell>
              <TableCell rowSpan={2}>खर्च</TableCell>
              <TableCell rowSpan={2}>बाटो म्याद</TableCell>
              <TableCell rowSpan={2}>प्रमाणित गर्ने</TableCell>
              <TableCell rowSpan={2}>कागजात</TableCell>
              <TableCell rowSpan={2}>कैफियत</TableCell>
              {AppMisc.isRoleStaff() && homeLeaveInfo?.length !== 0 && <TableCell rowSpan={2}>सम्पादन</TableCell>}
            </TableRow>
            <TableRow>
              <TableCell>देखि</TableCell>
              <TableCell>सम्म</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {homeLeaveInfo?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9}>{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (homeLeaveInfo?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row?.leaveStartDate && DateUtils.getDateFromMilliseconds(row.leaveStartDate)}</TableCell>
                <TableCell>{row?.leaveEndDate && DateUtils.getDateFromMilliseconds(row.leaveEndDate)}</TableCell>
                <TableCell>{row?.totalLeaveTaken || "-"}</TableCell>
                <TableCell>{row?.roadTime || "-"}</TableCell>
                <TableCell>{row?.verifiedBy}</TableCell>
                <TableCell>
                  <Box p={2}>
                    {
                      row.documents?.length ? (
                        <Grid className="print-none" alignItems="flex-start" spacing={2}>
                          {getLeaveDocumentsList(row.documents, row.id)}
                        </Grid>
                      ) : "-"
                    }
                  </Box>
                </TableCell>
                <TableCell>{row?.remark}</TableCell>
                {AppMisc.isRoleStaff() && <TableCell>
                  <Box className="print-none" display="flex" justifyContent="center">
                    <IconButton className={classes.actionBtn} onClick={() => handleStaffLeaveDetailsEdit(row.id)} aria-label="edit">
                      <CreateIcon fontSize="small" />
                    </IconButton>
                    <Box className={classes.paperActionButtons}>
                      <input id={`uploadStaffLeaveDocuments~${row.id}`} className={classes.hiddenInput} type="file" accept=".pdf,.doc,.ppt,.pptx,.txt,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.gif,.bmp,.ico" onClick={(e) => { e.currentTarget.value = null }} onChange={(event) => handleLeaveFileChange(event)} multiple />
                      <label htmlFor={`uploadStaffLeaveDocuments~${row.id}`}>
                        <Tooltip title="सम्बन्धित कागजातहरु अपलोड गर्नुहोस्।" arrow placement="top">
                          <IconButton onClick={() => getLeaveRowId(row.id)} aria-label="upload files" component="span">
                            <PublishIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </Box>
                    <IconButton className={classes.actionBtn} color="secondary" onClick={() => getLeaveRowDeleteId(row.id)} aria-label="delete">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>}
              </TableRow>
            ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default HomeLeaveDetails
