import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { NO_RECORDS_FOUND } from '../../../../utils/constants';


export default function LeaveDetailsList({ leaveDetailsList }) {

  return (
    <TableContainer >
      <Table classes={{ root: "table-bordered normal-spacing" }} size="small">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>बिदाको प्रकार</TableCell>
            <TableCell>जम्मा दिन </TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {!leaveDetailsList ? (
            <TableRow>
              <TableCell colSpan={9}>{NO_RECORDS_FOUND}</TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow>
                <TableCell>भैपरी आउने/पर्व बिदा</TableCell>
                <TableCell>{leaveDetailsList?.immediateOrFestivalLeaveTotalDays || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>घर विदा</TableCell>
                <TableCell>{leaveDetailsList?.homeLeaveTotalDays || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>बिरामी बिदा</TableCell>
                <TableCell>{leaveDetailsList?.sickLeaveTotalDays || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>प्रसूति बिदा/प्रसूती स्याहार बिदा</TableCell>
                <TableCell>{leaveDetailsList?.pregnancyOrPregnancyCareLeaveTotalDays || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>किरिया बिदा</TableCell>
                <TableCell>{leaveDetailsList?.deathRitualLeaveTotalDays || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अध्ययन बिदा</TableCell>
                <TableCell>{leaveDetailsList?.studyLeaveTotalDays || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>असाधारण बिदा</TableCell>
                <TableCell>{leaveDetailsList?.abnormalLeaveTotalDays || "-"}</TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}