import { Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomModal from "../../../../components/modal/CustomModal";
import { REQUIRED_FIELD } from "../../../../utils/constants";

export default function AddCategoryModal({ showAddCategoryModal, handleAddCategoryModalClose, handleModalSubmit, ...props }) {
  const { errors, handleSubmit, reset, register } = useForm();

  useEffect(() => {
    !showAddCategoryModal && reset({});
  }, [showAddCategoryModal, reset])

  const onSubmit = data => {
    handleModalSubmit(data);
  };

  return (
    <CustomModal
      title="नयाँ श्रेणी/तह थप्नुहोस।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddCategoryModal}
      onModalClose={handleAddCategoryModalClose}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <TextField
            label="श्रेणी/तह:"
            name="category"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.category}
            inputRef={register({
              required: true
            })}
            fullWidth
          />
          {errors.category && (<span className="error-message">{REQUIRED_FIELD}</span>)}
        </Grid>
      </Grid>
    </CustomModal>
  );

}