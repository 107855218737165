import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Edit } from "@material-ui/icons";
import React from 'react';
import { AppMisc } from "../../../../misc/appMisc";
import { AppUtils } from '../../../../utils/appUtils';
import { NO_RECORDS_FOUND } from '../../../../utils/constants';

export default function OfficeList(props) {

  function editRow(id) {
    props.editRow(id);
  }

  return (
    <TableContainer>
      <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Office List">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>क्र.सं.</TableCell>
            <TableCell>कार्यालयको नाम</TableCell>
            <TableCell>ठेगाना</TableCell>
            <TableCell>सम्पर्क नम्बर</TableCell>
            <TableCell>कार्यालयको किसिम</TableCell>
            <TableCell>सम्पादन</TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {props.tableData.length === 0 ?
            (
              <TableRow>
                <TableCell colSpan={6} align="center" size="medium">{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (
              props.tableData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                  <TableCell><a href={`office-home?id=${row.id}`}>{row.name}</a></TableCell>
                  <TableCell>{AppMisc.getProvinceName(row.province) + ", " + AppMisc.getDistrictName(row.district) + ", " + AppMisc.getMunicipalityName(row.palikaName)}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{AppMisc.getOfficeType(row.type)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => editRow(row.id)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
