import { Box, Button, Paper, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { Fragment, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { API_URL } from "../../../api/api";
import { AppUtils } from "../../../utils/appUtils";
import { PALIKA } from "../../../utils/constants/forms";
import OtherDetails from '../staff-details/helpers/other-details/OtherDetails';
import StaffAddressDetails from '../staff-details/helpers/staff-address-details/StaffAddressDetails';
import StaffAppointedServiceDetails from '../staff-details/helpers/staff-appointed-service-details/StaffAppointedServiceDetails';
import StaffCurrentServiceDetails from '../staff-details/helpers/staff-current-service-details/StaffCurrentServiceDetails';
import StaffFamilyDetails from '../staff-details/helpers/staff-family-details/StaffFamilyDetails';
import StaffPersonalDetails from '../staff-details/helpers/staff-personal-details/StaffPersonalDetails';
import StaffRetirementDetails from '../staff-details/helpers/staff-retirement-details/StaffRetirementDetails';
import styles from './style';

export default function PdfForm() {
  const classes = styles();
  const printComponentRef = useRef();
  const officeType = AppUtils.getOfficeType();

  const formSections = [
    { label: "व्यक्तिगत विवरण", component: <StaffPersonalDetails hideUploadButton /> },
    { label: "पारिवारिक विवरण", component: <StaffFamilyDetails /> },
    { label: "ठेगाना बारे विवरण", component: <StaffAddressDetails /> },
    { label: "नियूक्त सेवा विवरण", component: <StaffAppointedServiceDetails /> },
    { label: "हालको सेवा विवरण", component: <StaffCurrentServiceDetails /> },
    { label: "अबकाश अग्रसुचन विवरण", component: <StaffRetirementDetails /> },
    { label: "अन्य सामान्य विवरण", component: <OtherDetails /> }
  ]

  return (
    <>
      <Box mb={1} textAlign="right">
        <Box mr={2} display="flex" justifyContent="end">
          {
            officeType === PALIKA &&
            <Box mr={2}>
              <Button href={API_URL.downloadExcelSample + "?fileName=PUBLIC_REPRESENTATIVE_EXCEL"} variant="contained" color="primary" startIcon={<GetApp />} className="print-none">Download जन-प्रतिनिधि Excel Sample</Button>
            </Box>
          }
          <Box mr={2}>
            <Button href={API_URL.downloadExcelSample + "?fileName=STAFF_EXCEL"} variant="contained" color="primary" startIcon={<GetApp />} className="print-none">Download कर्मचारी Excel Sample</Button>
          </Box>
          <ReactToPrint
            trigger={
              () => <Button variant="contained" color="primary" className="print-none">फारम प्रिन्ट गर्नुहोस्</Button>
            }
            content={() => printComponentRef.current}
          />
        </Box>
      </Box>
      <Paper>
        <Box ref={printComponentRef}>
          <Box display="flex" justifyContent="center" pt={2}>
            <Typography variant="h4">कर्मचारीको विवरण</Typography>
          </Box>
          {formSections.map((obj, index) => (
            <Fragment key={index}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">{obj.label}</Typography>
              </Box>
              {obj.component}
            </Fragment>
          ))}
        </Box>
      </Paper>
    </>
  )
}
