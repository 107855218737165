import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    row: {
        marginBottom: theme.spacing(1),
        position: "relative",
    },
    hiddenInput: {
        display: "none",
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    paperHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottom: "1px solid " + theme.palette.divider,
    }
}));
export default styles;