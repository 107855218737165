import { Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomModal from "../../../../components/modal/CustomModal";
import { REQUIRED_FIELD } from "../../../../utils/constants";
import DepartmentSelect from "../../components/DepartmentSelect/DepartmentSelect";

export default function AddSubDepartmentModal({ showAddSubDepartmentModal, handleAddSubDepartmentModalClose, handleModalSubmit, ...props }) {
  const { errors, handleSubmit, reset, register, setValue } = useForm();

  useEffect(() => {
    !showAddSubDepartmentModal && reset({});
  }, [showAddSubDepartmentModal, reset])

  const onSubmit = data => {
    handleModalSubmit(data);
  };

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  useEffect(() => {
    register({ name: "departmentId" }, { required: true });
  }, [register])

  return (
    <CustomModal
      title="कर्मचारी सूचना प्रणालीमा नयाँ उपशाखा थप्नुहोस।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddSubDepartmentModal}
      onModalClose={handleAddSubDepartmentModalClose}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <DepartmentSelect
            defaultValue={props.defaultValues.departmentId}
            onChange={handleCustomSelectChange}
          />
          {errors.departmentId && (<span className="error-message">{REQUIRED_FIELD}</span>)}
        </Grid>
        <Grid item xs>
          <TextField
            label="उप-शाखा/इकाइको नाम:"
            name="subDivisionName"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.subDivisionName}
            inputRef={register}
            fullWidth
          />
          {errors.subDivisionName && (<span className="error-message">{REQUIRED_FIELD}</span>)}
        </Grid>
      </Grid>
    </CustomModal>
  );

}