import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { ROLE_ADMIN, ROLE_OFFICE_ADMIN, ROLE_OFFICE_STAFF, ROLE_STAFF, USER_ROLE } from "../utils/constants/index";
import { Cookies } from "../utils/storage/cookies";

export default function RouteWrapper({ component: Component, path: urlPath, isPrivate, isWrongLink, ...rest }) {
  const userInfo = useSelector(state => state.userInfo);
  let staffId= (userInfo?.staffId);

  if (isPrivate && !Cookies.readCookie(USER_ROLE)) {
    return <Redirect to="/" />;
  } else if (!isPrivate && !isWrongLink && !urlPath.includes("/user-not-authorized") && Cookies.readCookie(USER_ROLE) === ROLE_ADMIN) {
    return <Redirect to="/admin/dashboard" />;
  } else if (!isPrivate && !isWrongLink && !urlPath.includes("/user-not-authorized") && (Cookies.readCookie(USER_ROLE) === ROLE_OFFICE_ADMIN)) {
    return <Redirect to="/user/dashboard" />;
  } else if (!isPrivate && !isWrongLink &&  !urlPath.includes("/user-not-authorized") && staffId && (Cookies.readCookie(USER_ROLE) === ROLE_STAFF)) {
    return <Redirect to={`/user/staff-details?id=${staffId}`} />;
  } else if (isPrivate && urlPath.includes("/admin") && Cookies.readCookie(USER_ROLE) !== ROLE_ADMIN) {
    return <Redirect to="/user-not-authorized" />;
  } else {
    return <Route {...rest} component={Component} />;
  }
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isWrongLink: PropTypes.bool
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isWrongLink: false
};
