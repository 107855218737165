import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  row: {
    marginBottom: theme.spacing(1)
  },
  banner: {
    margin: "-32px",
    minHeight: "164px",
    color: theme.palette.primary.contrastText,
  },
  contactDetails: {
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    height: "100%"
  },
  formDetailsContainer: {
    padding: theme.spacing(2),
  },
  btnContainer: {
    marginTop: theme.spacing(1)
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  },
  contactItem: {
    display: "flex",
    marginBottom: theme.spacing(1),
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.htmlFontSize,
  },
  socialLinks: {
    textAlign: "center"
  },
  socialIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export default styles;