import { makeStyles } from "@material-ui/core/styles";
const styles = makeStyles(theme => ({
  subDepartmentsHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between",
    borderBottom: "1px solid " + theme.palette.divider,
  }
}));
export default styles;