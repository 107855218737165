import { Box, Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddOffice from "../components/add-office/AddOffice";
import OfficeList from "./helpers/OfficeList";
import styles from "./style";

export default function AdminDashboard() {
  const classes = styles();
  const [openAddOfficeModal, setOpenAddOfficeModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [officeListData, setOfficeListData] = useState([]);

  const handleAddOfficeModalClose = () => {
    setOpenAddOfficeModal(false);
    setModalDefaultValues({});
  }

  const handleAddOfficeModalSubmit = data => {
    data.id = modalDefaultValues.id;
    PIS.post(API_URL.office, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          getOfficeList();
          handleAddOfficeModalClose();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      });
  }

  useEffect(() => {
    getOfficeList();
  }, []);

  const getOfficeList = () => {
    PIS.get(API_URL.office)
      .then(response => {
        setOfficeListData(response.data.data);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleRowEdit = id => {
    PIS.get(API_URL.office + "/" + id).then(response => {
      setModalDefaultValues(response.data.data);
      setOpenAddOfficeModal(true);
    }).catch(error => {
      AddAlertMessage({ type: "error", SOMETHING_WENT_WRONG });
    });
  }

  return (
    <>
      <Box className={classes.dashboardContainer} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
        <Typography variant="h5">
          कर्मचारी सूचना व्यवस्थापन प्रणाली - सबै कार्यालयहरु
        </Typography>
        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddOfficeModal(true) }}>नयाँ कार्यालय थप्नुहोस्</Button>
        </Box>
      </Box>
      <Box>
        <OfficeList tableData={officeListData} editRow={handleRowEdit} />
      </Box>
      <AddOffice showAddOfficeModal={openAddOfficeModal} onSubmit={handleAddOfficeModalSubmit} handleAddOfficeModalClose={handleAddOfficeModalClose} defaultValues={modalDefaultValues} />
    </>
  );
}