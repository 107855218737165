import { Box, Button, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import style from './style';
import AddEducationalQualificationModel from './helpers/AddEducationalQualificationModel';
import EducationalQualificationList from './helpers/EducationalQualificationList';
import CustomModal from '../../../components/modal/CustomModal';
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import PIS, { API_URL } from '../../../api/api';
import AddAlertMessage from "../../../components/alert/Alert";

export default function EducationalQualification() {
  const [openAddEducationalQualificationModel, setOpenAddEducationalModel] = useState(false);
  const [educationalQualificationList, setEducationalQualificationList] = useState([]);
  const [modelDefaultValues, setModalDefaultValues] = useState({});
  const [rowId, setRowId] = useState(null);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] = useState(false);
  const classes = style();

  useEffect(() => {
    getEducationalQualificationList()
  }, [])

  const getEducationalQualificationList = () => {
    PIS.get(API_URL.academicDegree)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setEducationalQualificationList(jsondata.data);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const onSubmit = data => {
    data.id = modelDefaultValues.id;
    PIS.post(API_URL.academicDegree, data)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          getEducationalQualificationList();
          handleModalClose();
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleModalClose = () => {
    setOpenAddEducationalModel(false);
    setModalDefaultValues({});
  }

  const handleEditRow = id => {
    PIS.get(`${API_URL.academicDegree}/${id}`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setModalDefaultValues(jsondata.data);
          setOpenAddEducationalModel(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleDeleteRow = id => {
    setRowId(id);
    setOpenDeleteConfirmationModel(true);
  }

  const deleteTableRow = () => {
    PIS.delete(`${API_URL.academicDegree}/${rowId}`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          handleDeleteConfirmationModelClose();
          getEducationalQualificationList();
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleDeleteConfirmationModelClose = () => {
    setOpenDeleteConfirmationModel(false);
    setRowId(null);
  }

  return (
    <>
      <Box className={classes.subPostHeading}>
        <Typography variant="h6">शैक्षिक उपाधि</Typography>
        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddEducationalModel(true) }}>शैक्षिक उपाधि थप्नुहोस्</Button>
        </Box>
      </Box>
      <AddEducationalQualificationModel
        modelDefaultValues={modelDefaultValues}
        submitEducationalDetails={onSubmit}
        handleModalClose={handleModalClose}
        openAddEducationalQualificationModel={openAddEducationalQualificationModel}
      />
      <EducationalQualificationList
        educationalQualificationList={educationalQualificationList}
        onEditRow={handleEditRow}
        onDeleteRow={handleDeleteRow}
        showActionColumn={Boolean(educationalQualificationList.length)}
      />
      <CustomModal
        title="शैक्षिक उपाधि विवरण हटाउनुहोस्।"
        onModalSubmit={deleteTableRow}
        showModal={openDeleteConfirmationModel}
        onModalClose={handleDeleteConfirmationModelClose}
        submitButtonText="हटाउनुहोस्"
      >
        <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित शैक्षिक उपाधि विवरण हटाउन चाहनुहुन्छ?</Typography>
        <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
      </CustomModal>
    </>
  )
}
