import { Box, Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Add as AddIcon, Print, GetApp, Publish } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../action/userInfo";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { DEFAULT_ROW_SIZE, ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddPublicRepresentativeModal from "./helpers/AddPublicRepresentativeModal";
import PublicRepresentativeList from "./helpers/PublicRepresentativeList";
import styles from "./style";
import { AppUtils } from "../../../utils/appUtils";

export default function PublicRepresentative() {
  const classes = styles();
  const dispatch = useDispatch();
  const [openAddPublicRepresentativeModal, setOpenAddPublicRepresentativeModal] = useState(false);
  const [publicRepresentativeTableData, setPublicRepresentativeTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector(state => state.userInfo);
  const [searchParam, setSearchParam] = useState('');
  const [size, setSize] = useState(0);
  const [rowSize, setRowSize] = useState(DEFAULT_ROW_SIZE);
  const [pageNumber, setPageNumber] = useState(1)
  const [file, setFile] = useState(null);
  const printComponentRef = useRef();
  const formerPublicRepresentative = AppUtils.getUrlParam('formerPublicRepresentative');

  useEffect(() => {
    !userInfo && dispatch(getUserInfo())
  }, [])

  useEffect(() => {
    pageNumber && getPublicRepresentatives();
  }, [pageNumber, searchParam, rowSize]);

  function getPaginationData(pageNumber) {
    setPageNumber(pageNumber);
  }

  function getSearchData(searchFilter) {
    setSearchParam(searchFilter);
  }

  function getRowSize(rowSize) {
    setRowSize(rowSize);
  }

  const getPublicRepresentatives = () => {
    setIsLoading(true);
    let url = API_URL.publicRepresentative + `?pageNumber=${pageNumber}&rowSize=${rowSize}`
    if (searchParam) {
      url += `&searchParam=${searchParam}`
    }
    if (formerPublicRepresentative) {
      url += `&formerPublicRepresentative=${formerPublicRepresentative}`
    }
    PIS.get(url).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setPublicRepresentativeTableData(jsondata.data?.objectList || []);
        setSize(jsondata.data.size);
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
      setIsLoading(false);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      setIsLoading(false);
    });
  };

  const headers = [
    { label: "नाम", key: "fullName" },
    { label: "ठेगाना", key: "address" },
    { label: "पद", key: "post" },
    { label: "इमेल", key: "email" },
    { label: "फोन", key: "phoneNumber" },
  ]

  const handleAddPublicRepresentativeModalClose = () => {
    setOpenAddPublicRepresentativeModal(false);
    setModalDefaultValues({});
  };

  const submitAddPublicRepresentativeModal = data => {
    if (modalDefaultValues.id) {
      data.id = modalDefaultValues.id;
    }
    data.publicRepresentativeLabel = data.formerPublicRepresentative ? "भूतपूर्व" : "कार्यरत"
    PIS.post(API_URL.publicRepresentative, data)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === "success") {
          handleAddPublicRepresentativeModalClose();
          getPublicRepresentatives();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const editPublicRepresentativeDetails = id => {
    PIS.get(API_URL.publicRepresentative + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data["data"]);
        setOpenAddPublicRepresentativeModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  };

  const uploadFiles = excelFile => {
    const formData = new FormData();
    for (let i = 0; i < excelFile.length; i++) {
      formData.append("file", excelFile[i]);
    }
    formData.append("officeId", userInfo?.officeId)
    PIS.post(API_URL.importFromExcel + "/public-representative", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          getPublicRepresentatives();
          setFile(null);
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  };

  useEffect(() => {
    file && userInfo?.officeId && uploadFiles(file);
  }, [file, userInfo]);

  function handleFileChange(e) {
    let files = e.target.files;
    let filesList = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        filesList.push(files[i]);
      }
    }
    setFile(filesList);
  }

  return (
    <>
      <Box className={classes.setting}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
          <Typography variant="h5">जन प्रतिनिधिहरू</Typography>
          <Box>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
              setOpenAddPublicRepresentativeModal(true);
            }}>नयाँ जन प्रतिनिधि विवरण थप गर्नुहोस्</Button>
            <>
              <input id="uploadDocuments" hidden={true} type="file" accept=".xlsx,.xls" onChange={(event) => { handleFileChange(event) }} />
              <label htmlFor="uploadDocuments">
                <Tooltip title="एक्सेल मार्फत जन प्रतिनिधिहरू थप्नुहोस्" arrow placement="top">
                  <IconButton aria-label="upload files" component="span">
                    <Publish fontSize="small" />
                  </IconButton>
                </Tooltip>
              </label>
            </>
            <Tooltip title="Excel डाउनलोड गर्नुहोस्।" placement="top" arrow>
              <CSVLink
                data={publicRepresentativeTableData}
                headers={headers}
                filename="PIS-जन प्रतिनिधिहरू.csv"
                uFEFF={false}
                target="_blank"
              >
                <IconButton>
                  <GetApp />
                </IconButton>
              </CSVLink>
            </Tooltip>
            <ReactToPrint
              trigger={
                () => <IconButton aria-label="print" className="print-none">
                  <Print />
                </IconButton>
              }
              content={() => printComponentRef.current}
            />
          </Box>
        </Box>
        <Box ref={printComponentRef}>
          <PublicRepresentativeList isLoading={isLoading} size={size} rowSize={rowSize} functionPagination={getPaginationData} functionSearchParam={getSearchData} functionRowSize={getRowSize} getTableData={getPublicRepresentatives} tableData={publicRepresentativeTableData} showActionColumn={publicRepresentativeTableData.length !== 0} editRow={editPublicRepresentativeDetails} pageSize={pageNumber} />
        </Box>
      </Box>
      <AddPublicRepresentativeModal modalDefaultValues={modalDefaultValues} showAddPublicRepresentativeModal={openAddPublicRepresentativeModal} handleAddPublicRepresentativeModalClose={handleAddPublicRepresentativeModalClose} onSubmit={submitAddPublicRepresentativeModal.bind(this)} />
    </>
  );
}
