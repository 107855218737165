import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { NO_RECORDS_FOUND } from "../../../../utils/constants";

const StaffLeaveReport = ({ staffLeaveReportDetails }) => {
  return (
    <Box p={2}>
      <TableContainer >
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small">
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell colSpan={3}>भैपरी आउने पर्व बिदा</TableCell>
              <TableCell colSpan={3}>घर विदा</TableCell>
              <TableCell colSpan={3}>बिरामी बिदा</TableCell>
              <TableCell colSpan={3}>प्रसूति बिदा/प्रसूती स्याहार बिदा</TableCell>
              <TableCell colSpan={3}>किरिया बिदा</TableCell>
              <TableCell colSpan={3}>अध्ययन बिदा</TableCell>
              <TableCell colSpan={3}>असाधारण बिदा</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
              <TableCell>जम्मा</TableCell>
              <TableCell>खर्च</TableCell>
              <TableCell>बाँकी</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {Object.keys(staffLeaveReportDetails).length === 0 ? (
              <TableRow>
                <TableCell colSpan={21}>
                  {NO_RECORDS_FOUND}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>{staffLeaveReportDetails?.immediateOrFestivalTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.immediateOrFestivalTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingImmediateOrFestivalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.homeTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.homeTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingHomeLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.sickTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.sickTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingSickLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.pregnancyOrPregnancyCareTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.pregnancyOrPregnancyCareTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingPregnancyOrPregnancyCareLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.deathRitualTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.deathRitualTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingDeathRitualLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.studyTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.studyTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingStudyLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.abnormalTotalLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.abnormalTakenLeave || "-"}</TableCell>
                <TableCell>{staffLeaveReportDetails?.remainingAbnormalLeave || "-"}</TableCell>
              </TableRow>
            )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default StaffLeaveReport;