import { Drawer, List } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../api/api";
// context
import { useLayoutState } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import { ERROR, ROLE_ADMIN, ROLE_OFFICE_ADMIN, SOMETHING_WENT_WRONG, SUCCESS, USER_INFO } from "../../utils/constants";
import { PALIKA } from "../../utils/constants/forms";
import { SessionStorage } from "../../utils/storage/sessionStorage";
import AddAlertMessage from "../alert/Alert";
import { PIS_ADMIN_SIDEBAR_LINKS, PIS_OFFICE_ADMIN_SIDEBAR_LINKS } from "./helpers/SidebarItems";
import SidebarLink from "./helpers/SidebarLink/SidebarLink";
import styles from "./style";

function Sidebar({ location }) {
  const classes = styles();
  const [officeType, setOfficeType] = useState(null);
  const userInfo = SessionStorage.getItem(USER_INFO);
  const [sidebarLinks, setSidebarLink] = useState(PIS_OFFICE_ADMIN_SIDEBAR_LINKS);

  // global
  var { isSidebarOpened } = useLayoutState();
  var { userRole } = useUserState();

  useEffect(() => {
    if (userRole === ROLE_ADMIN) {
      setSidebarLink(PIS_ADMIN_SIDEBAR_LINKS)
    }
  }, [userRole])

  const getUserInfo = () => {
    PIS.get(API_URL.user)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setOfficeType(jsondata.data.officeType);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  useEffect(() => {
    userInfo ? setOfficeType(userInfo.officeType) : getUserInfo();
  }, [userInfo]);

  useEffect(() => {
    if (userRole === ROLE_OFFICE_ADMIN) {
      if (officeType === PALIKA) {
        setSidebarLink(PIS_OFFICE_ADMIN_SIDEBAR_LINKS);
      } else {
        let links = PIS_OFFICE_ADMIN_SIDEBAR_LINKS;
        setSidebarLink(links.filter(item => item.link !== "public-representatives"))
      }
    }
  }, [officeType, userRole])

  // TODO: Sandeep - Get links from components/left-sidebar/helpers/sidebarItems.js and attach them dynamically.
  // TODO: Sandeep - Add dynamic variant based on the device width. i.e, for mobile devices use variant="temporary"

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <List className={classes.sidebarList}>
        {sidebarLinks.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;
