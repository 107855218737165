import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import ReactNotification from 'react-notifications-component';
import { Router, Switch } from "react-router-dom";
import "./assets/scss/app.scss";
import Layout from "./components/layout/Layout";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import AdminDashboard from "./pages/admin/dashboard/Dashboard";
import ImportPublicRepresentative from "./pages/admin/import-public-representative/ImportPublicRepresentative";
import ImportStaffDetails from "./pages/admin/import-staff/ImportStaff";
import OfficeHome from "./pages/admin/office-home/OfficeHome";
import AdminProfile from "./pages/admin/profile/Profile";
import SearchException from "./pages/admin/search-exception/SearchException";
import SystemSetup from "./pages/admin/system-setup/SystemSetup";
import CardValidation from "./pages/public/card-validation/CardValidation";
import Contact from "./pages/public/contact-us/ContactUs";
import ForgetPassword from "./pages/public/forget-password/ForgetPassword";
import LandingPage from "./pages/public/landing-page/LandingPage";
import Login from "./pages/public/login/Login";
import NotAuthorized from "./pages/public/not-authorized/NotAuthorized";
import GlobalPageNotFound from "./pages/public/not-found/GlobalPageNotFound";
import LandingPagePublicRepresentativeList from "./pages/public/public-representative-list/LandingPagePublicRepresentativeList";
import ResetPassword from "./pages/public/reset-password/ResetPassword";
import LandingPageStaffList from "./pages/public/staff-list/LandingPageStaffList";
import Category from "./pages/user/category/Category";
import PublicRepresentativeCardValidation from "./pages/user/components/public-representative-card/PublicRepresentativeCardValidation";
import Dashboard from "./pages/user/dashboard/Dashboard";
import Departments from "./pages/user/departments/Departments";
import EducationalQualification from "./pages/user/educational-qualification/EducationalQualification";
import LeaveDetails from "./pages/user/leave-details/LeaveDetails";
import LeaveReport from "./pages/user/leave-report/LeaveReport";
import PdfForm from "./pages/user/pdf-form/PdfForm";
import Post from "./pages/user/post/Post";
import Profile from "./pages/user/profile/Profile";
import PublicRepresentativeDetails from "./pages/user/public-representative-details/PublicRepresentativeDetails";
import PublicRepresentative from "./pages/user/public-representative/PublicRepresentative";
import SearchStaff from "./pages/user/search-staff/SearchStaff";
import Setting from "./pages/user/setting/Setting";
import StaffDetails from "./pages/user/staff-details/StaffDetails";
import Staffs from "./pages/user/staffs/Staffs";
import SubDepartments from "./pages/user/sub-departments/SubDepartments";
import UserManagement from "./pages/user/user-management/UserManagement";
import Route from "./routes/Route";
import history from "./services/history";

export default function App() {
  return (
    <>
      <ReactNotification />
      <Router history={history}>
        <CssBaseline />
        <Layout>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/bheemdatta" component={LandingPage} />
            <Route exact path="/public/staffs-list" component={LandingPageStaffList} />
            <Route exact path="/public/public-representative-list" component={LandingPagePublicRepresentativeList} />
            <Route exact path="/kailari" component={LandingPage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/page-not-found" component={GlobalPageNotFound} isWrongLink />
            <Route exact path="/user-not-authorized" component={NotAuthorized} />
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route exact path="/card-validation" component={CardValidation} />
            <Route exact path="/public-representative-card-validation" component={PublicRepresentativeCardValidation} />
            {/* User Route */}
            <Route exact path="/user/contact-us" component={Contact} isPrivate />
            <Route exact path="/user/dashboard" component={Dashboard} isPrivate />
            <Route exact path="/user/staffs" component={Staffs} isPrivate />
            <Route exact path="/user/profile" component={Profile} isPrivate />
            <Route exact path="/user/setting" component={Setting} isPrivate />
            <Route exact path="/user/staff-details/" component={StaffDetails} isPrivate />
            <Route exact path="/user/public-representatives" component={PublicRepresentative} isPrivate />
            <Route exact path="/user/public-representatives-details" component={PublicRepresentativeDetails} isPrivate />
            <Route exact path="/user/pdf-form" component={PdfForm} isPrivate />
            <Route exact path="/user/departments" component={Departments} isPrivate />
            <Route exact path="/user/user-management" component={UserManagement} isPrivate />
            <Route exact path="/user/search-staff" component={SearchStaff} isPrivate />
            <Route exact path="/user/category" component={Category} isPrivate />
            <Route exact path="/user/sub-departments" component={SubDepartments} isPrivate />
            <Route exact path="/user/post" component={Post} isPrivate />
            <Route exact path="/user/educational-qualification" component={EducationalQualification} isPrivate />
            <Route exact path="/user/leave-details" component={LeaveDetails} isPrivate />
            <Route exact path="/user/leave-report" component={LeaveReport} isPrivate />
            {/* Admin Route */}
            <Route exact path="/admin/dashboard" component={AdminDashboard} isPrivate />
            <Route exact path="/admin/office-home" component={OfficeHome} isPrivate />
            <Route exact path="/admin/profile" component={AdminProfile} isPrivate />
            <Route exact path="/admin/system-setup" component={SystemSetup} isPrivate />
            <Route exact path="/admin/search-business-exception" component={SearchException} isPrivate />
            <Route exact path="/admin/import-staff-details" component={ImportStaffDetails} isPrivate />
            <Route exact path="/admin/import-public-representative" component={ImportPublicRepresentative} isPrivate />
            <Route component={GlobalPageNotFound} isWrongLink />
          </Switch>
        </Layout>
      </Router>
      <ScrollToTop />
    </>
  );
}
