import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.divider,
  },
}));
export default styles;
