import { Box, Button, Grid, Paper, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import NepaliCalender from "../../../../components/nepali-calender";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import styles from "./style";

export default function AddRetirementDetails({ onRetirementDetailsUpdate, retirementDetails, ...props }) {
  const classes = styles();
  const { register, handleSubmit, errors, setValue, reset } = useForm();
  const staffId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    register({ name: "date" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (retirementDetails) {
      setValue("date", retirementDetails.date)
    }
  }, [retirementDetails]);

  const onSubmit = data => {
    data.staffId = AppUtils.getUrlParam(ID);
    let postData = {
      id: staffId,
      retirementDetail: {
        date: data.date
      }
    }

    PIS.post(API_URL.staff, postData)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onRetirementDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli)
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Tooltip title="अवकाशको मिति: यो कर्मचारी सेवानिवृत्त भएको वास्तविक मिति हो, यो मिति कर्मचारी सेवानिवृत्ति भएपछि मात्र प्रदान गर्नुपर्छ।" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="date"
                    id="date"
                    label="अवकाशको मिति"
                    onChange={handleDateChange}
                    defaultDate={retirementDetails?.date}
                  />
                </Box>
              </Tooltip>
              {errors.date && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !retirementDetails.date && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {retirementDetails.date ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
