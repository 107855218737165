import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, Publish as PublishIcon } from '@material-ui/icons';
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import { AppUtils } from "../../../../../utils/appUtils";
import { NO_RECORDS_FOUND, PUNISHMENT_TYPES } from "../../../../../utils/constants";
import { DateUtils } from "../../../../../utils/dateUtils";
import styles from "../../style";

const DepartmentalPunishmentDetails = ({ getDepartmentalPunishmentRowId, getDepartmentalPunishmentRowDeleteId, handleDepartmentalPunishmentFileChange, handleDepartmentalPunishmentDetailsEdit, getDepartmentalPunishmentDocumentsList, departmentalPunishmentDetails }) => {
  const classes = styles();

  return (
    <Box p={2}>
      <TableContainer >
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" >
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>सजायको प्रकार</TableCell>
              <TableCell>सजायको आदेश मिती</TableCell>
              <TableCell >पद</TableCell>
              <TableCell >तह</TableCell>
              <TableCell>सेवा समूह</TableCell>
              <TableCell>कार्यालय</TableCell>
              <TableCell>सजाय फुकुवा मिति</TableCell>
              <TableCell>कागजात</TableCell>
              {AppMisc.isRoleStaff() && departmentalPunishmentDetails.length !== 0 && <TableCell>सम्पादन</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {
              departmentalPunishmentDetails?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              ) : (
                departmentalPunishmentDetails.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.punishmentType ? AppMisc.getLabelFromValue(row.punishmentType, PUNISHMENT_TYPES) : "-"}</TableCell>
                    <TableCell>{row.punishmentAnnouncedDate ? DateUtils.getDateFromMilliseconds(row.punishmentAnnouncedDate) : "-"}</TableCell>
                    <TableCell>{row.post ? row.post : "-"}</TableCell>
                    <TableCell>{row.label ? row.label : "-"}</TableCell>
                    <TableCell>{row.serviceGroup ? row.serviceGroup : "-"}</TableCell>
                    <TableCell>{row.office ? row.office : "-"}</TableCell>
                    <TableCell>{row.sentenceReleaseDate ? DateUtils.getDateFromMilliseconds(row.sentenceReleaseDate) : "-"}</TableCell>
                    <TableCell>
                      <Box p={2}>
                        {
                          row.documents?.length ? (
                            <Grid className="print-none"  alignItems="center" spacing={2}>
                              {getDepartmentalPunishmentDocumentsList(row.documents, row.id)}
                            </Grid>
                          ) : "-"
                        }
                      </Box>
                    </TableCell>
                    {AppMisc.isRoleStaff() && <TableCell>
                      <Box className="print-none" display="flex" justifyContent="center">
                        <IconButton className={classes.actionBtn} onClick={() => handleDepartmentalPunishmentDetailsEdit(row.id)} aria-label="edit">
                          <CreateIcon fontSize="small" />
                        </IconButton>
                        <Box className={classes.paperActionButtons}>
                          <input id={`uploadMedicalExpensesDocuments~${row.id}`} className={classes.hiddenInput} type="file" accept=".pdf,.doc,.ppt,.pptx,.txt,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.gif,.bmp,.ico" onClick={(e) => { e.currentTarget.value = null }} onChange={(event) => handleDepartmentalPunishmentFileChange(event)} multiple />
                          <label htmlFor={`uploadMedicalExpensesDocuments~${row.id}`}>
                            <Tooltip title="सम्बन्धित कागजातहरु अपलोड गर्नुहोस्।" arrow placement="top">
                              <IconButton onClick={() => getDepartmentalPunishmentRowId(row.id)} aria-label="upload files" component="span">
                                <PublishIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </Box>
                        <IconButton className={classes.actionBtn} color="secondary" onClick={() => getDepartmentalPunishmentRowDeleteId(row.id)} aria-label="delete">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>}
                  </TableRow>
                ))
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default DepartmentalPunishmentDetails;
