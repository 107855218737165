import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(1.5),
    "@media print": {
      boxShadow: "none",
      background: "none",
    },
  },
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.divider,
  },
  cardContainer: {
    width: "100%",
    padding: theme.spacing(2),
  },
}));
export default styles;