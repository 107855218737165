
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import styles from "../../style";

export default function StaffAppointedServiceDetails({ categoryList=[], postOptions=[], ...props }) {
  const classes = styles();

  return (
    <Box className={classes.root} p={2}>
      <Grid container>
        <TableContainer className={classes.appointedServiceDetailsTable}>
          <Table size="small" className="table-bordered" aria-label="Staff Appointed Service Details">
            <TableBody>
              <TableRow>
                <TableCell>माथिल्लो निकाय: {props.appointedServiceDetails && (props.appointedServiceDetails.upperBody || "-")}</TableCell>
                <TableCell>जिल्ला: {props.appointedServiceDetails && (props.appointedServiceDetails.district || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>कार्यालय: {props.appointedServiceDetails && (props.appointedServiceDetails.office || "-")}</TableCell>
                <TableCell>सेवा परिमाण: {props.appointedServiceDetails && (props.appointedServiceDetails.serviceVolume || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>सेवा / समुह / उप समूह: {props.appointedServiceDetails && (props.appointedServiceDetails.serviceOrGroup || "-")}</TableCell>
                <TableCell>सेवाको किसिम: {props.appointedServiceDetails && (props.appointedServiceDetails.serviceType || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>श्रेणी/तह: {AppMisc.getLabelFromValue(props.appointedServiceDetails?.categoryAndLabel, categoryList)}</TableCell>
                <TableCell>पद: {AppMisc.getLabelFromValue(props.appointedServiceDetails?.post, postOptions)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>लागू हुने मिति: {props.appointedServiceDetails && (props.appointedServiceDetails.applicationDateInBS || "-")}</TableCell>
                <TableCell>निर्णय मिति: {props.appointedServiceDetails && (props.appointedServiceDetails.decisionDateInBS || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>कार्यालयमा हाजिरी मिति: {props.appointedServiceDetails && (props.appointedServiceDetails.officeAttendanceDateInBS || "-")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
