import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import CustomModal from "../../../../components/modal/CustomModal";
import NepaliCalender from "../../../../components/nepali-calender";
import { LEAVE_TYPES, REQUIRED_FIELD } from "../../../../utils/constants/index";
import styles from "./style";

const AddStaffLeaveDetailsModal = ({ modalDefaultValue, onLeaveDetailsSubmit, showAddStaffLeaveDetailModal, closeAddStaffLeaveDetailModal }) => {
  const classes = styles();
  const { register, handleSubmit, watch, errors, reset, setValue } = useForm();

  useEffect(() => {
    register({ name: "leaveType" }, { required: true });
    register({ name: "leaveStartDate" }, { required: true });
    register({ name: "leaveEndDate" }, { required: true });
    register({ name: "remark" });
    register({ name: "roadTime" });
    register({ name: "verifiedBy" }, { required: true });
  }, [register]);

  const watchField = watch(["leaveType"]);

  const handleLeaveTypeChange = (name, value) => {
    setValue(name, value);
  }

  useEffect(() => {
    if (modalDefaultValue) {
      setValue("leaveType", modalDefaultValue?.leaveType);
      setValue("leaveStartDate", modalDefaultValue?.leaveStartDate);
      setValue("leaveEndDate", modalDefaultValue?.leaveEndDate);
    }
  }, [modalDefaultValue]);

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  return (
    <CustomModal
      title="बिदा विवरण थप्नुहोस्"
      onModalSubmit={handleSubmit(onLeaveDetailsSubmit)}
      showModal={showAddStaffLeaveDetailModal}
      onModalClose={closeAddStaffLeaveDetailModal}
      maxWidth="lg"
    >
      <Box>
        <Grid container spacing={2} alignItems="center" className={classes.row}>
          <Grid item xs={3}>
            <CustomSelect
              options={LEAVE_TYPES}
              placeholder="बिदाको प्रकार"
              label="बिदाको प्रकार"
              name="leaveType"
              onChange={handleLeaveTypeChange}
              defaultValue={modalDefaultValue?.leaveType}
            />
            {errors.leaveType && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          {(watchField.leaveType === "HOME_LEAVE" || watchField.leaveType === "DEATH_RITUAL_LEAVE") && <Grid item xs={3}>
            <TextField
              defaultValue={modalDefaultValue?.roadTime || "-"}
              label="बाटो म्याद"
              name="roadTime"
              type="number"
              variant="outlined"
              size="small"
              inputRef={register({ required: true })}
              fullWidth
            />
            {errors.roadTime && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          }
          <Grid item xs={3}>
            <Box>
              <NepaliCalender
                defaultDate={modalDefaultValue?.leaveStartDate}
                id="leaveStartDate"
                name="leaveStartDate"
                label="मिति देखि"
                onChange={handleDateChange}
              />
              {errors.leaveStartDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <NepaliCalender
                defaultDate={modalDefaultValue?.leaveEndDate}
                id="leaveEndDate"
                name="leaveEndDate"
                label="मिति सम्म"
                onChange={handleDateChange}
              />
              {errors.leaveEndDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <TextField
              defaultValue={modalDefaultValue?.totalLeaveTaken}
              label="कुल बिदा लिएको"
              name="totalLeaveTaken"
              type="text"
              variant="outlined"
              inputProps={{ "step": "any" }}
              size="small"
              inputRef={register({ required: true })}
              fullWidth
            />
            {errors.totalLeaveTaken && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              defaultValue={modalDefaultValue?.accumulatedLeave}
              label="संचित बिदा"
              name="accumulatedLeave"
              type="text"
              variant="outlined"
              inputProps={{ "step": "any" }}
              size="small"
              inputRef={register({ required: true })}
              fullWidth
            />
            {errors.accumulatedLeave && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              defaultValue={modalDefaultValue?.verifiedBy}
              label="प्रमाणित गर्ने"
              name="verifiedBy"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register}
              fullWidth
            />
            {errors.verifiedBy && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              defaultValue={modalDefaultValue?.remark}
              label="कैफियत"
              name="remark"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  )
}

export default AddStaffLeaveDetailsModal;