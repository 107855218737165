import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { BSToAD, calendar_data, FIRST_DAY_OF_MONTH } from './AdToBsConverter/AdBsConverter';
import "./assets/app.css";
import { getDateFromMilliseconds, getDateMilliseconds, getSeparatedDateFromBsDate, getSeparatedDateFromMilliseconds, replaceWithNepaliDigit } from './utils/AppUtils';

let todayTimestamp = (new Date((new Date()).toISOString().slice(0, 10))).getTime()
let date = getSeparatedDateFromMilliseconds(Date.now());
const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const monthMap = ['बैशाख', 'जेठ', 'असार', 'श्रावन', 'भाद्र', 'असोज', 'कार्तिक', 'मंसिर', 'पौष', 'माघ', 'फाल्गुन', 'चैत्र'];
const weekDaysInShort = ['आइत', 'सोम', 'मंगल', 'बुध', 'विहि', 'शुक्र', 'शनि'];
const monthMapInEnglish = ["Baishakh", "Jestha", "Asar", "Shrawan", "Bhadra", "Ashwin", "Kartik", "Mangsir", "Poush", "Magh", "Falgun", "Chaitra"];
const weekDaysInShortInEnglish = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function NepaliCalender(props) {
  let inputRef = useRef();
  let datePickerRef = useRef();

  const [year, setYear] = useState(date.year);
  const [month, setMonth] = useState(date.month - 1);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [invalidDateError, setInvalidDateError] = useState({});
  const [selectedDay, setSelectedDay] = useState(todayTimestamp);

  let getMonthDetails = (year, month) => {
    let firstDay = FIRST_DAY_OF_MONTH[year][month];
    let numberOfDays = getNumberOfDays(year, month);
    let monthArray = [];
    let rows = 6;
    let currentDay = null;
    let index = 0;
    let cols = 7;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        currentDay = getDayDetails({
          index,
          numberOfDays,
          firstDay,
          year,
          month
        });
        monthArray.push(currentDay);
        index++;
      }
    }
    return monthArray;
  }

  const getDayDetails = args => {
    let date = args.index - args.firstDay;
    let day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0 && args.year !== 1970) {
      prevMonth = 11;
      prevYear--;
    }
    let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
    let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
    let timestamp = getDateMilliseconds(args.year + "-" + (args.month + 1) + "-" + _date)
    return {
      date: _date,
      day,
      month,
      timestamp,
      dayString: daysMap[day]
    }
  }

  const getNumberOfDays = (year, month) => {
    return calendar_data[year][month];
  }

  const [monthDetails, setMonthDetails] = useState(getMonthDetails(date.year, (date.month - 1)));

  useEffect(() => {
    window.addEventListener('click', addBackDrop);
  })

  useEffect(() => {
    if (props.defaultFormat ? props.defaultDate : (!isNaN(props.defaultDate) && (props.defaultDate !== null))) {
      let dateObject;
      let defaultDate;
      if (props.defaultFormat) {
        dateObject = getSeparatedDateFromBsDate(props.defaultDate);
        defaultDate = getDateMilliseconds(props.defaultDate);
      } else {
        dateObject = getSeparatedDateFromMilliseconds(props.defaultDate);
        defaultDate = props.defaultDate;
      }
      setYear(dateObject.year);
      setMonth(dateObject.month);
      updateDateToInput(defaultDate);
      updateDateFromInput();
    }
  }, [props.defaultDate])

  useEffect(() => {
    if (props.showDefaultDate && (props.defaultFormat ? !props.defaultDate : (isNaN(props.defaultDate) || props.defaultDate === null))) {
      updateDateToInput(selectedDay);
      updateDateFromInput();
    }
  }, [props.showDefaultDate])

  useEffect(() => {
    return () => {
      window.removeEventListener('click', addBackDrop);
    }
  }, [])

  const addBackDrop = e => {
    if (!ReactDOM.findDOMNode(datePickerRef.current)?.contains(e.target)) {
      setShowDatePicker(false);
    }
  }

  const isCurrentDay = day => {
    return (day.month === 0) && (getDateFromMilliseconds(day.timestamp) === getDateFromMilliseconds(todayTimestamp));
  }

  const isSelectedDay = day => {
    return (day.month === 0) && (day.timestamp === selectedDay);
  }

  const getDateFromDateString = dateValue => {
    let dateData = dateValue.split('-').map(d => parseInt(d, 10));

    if ((dateData.length < 3) || !((dateData[0] > 1969) && dateData[0] < 2100) || !((dateData[1] > 0) && (dateData[1] < 13)) || !((dateData[2] > 0) && (dateData[2] <= calendar_data[dateData[0]][dateData[1] - 1]))) {
      if (!!dateValue) {
        dateData.length < 3 ? setInvalidDateError({ invalidFormat: true }) : dateData[0] < 1970 ? setInvalidDateError({ minDateError: true }) : dateData[0] > 2099 ? setInvalidDateError({ maxDateError: true }) : setInvalidDateError({ invalidDate: true });
      }

      return null;
    }

    let year = dateData[0];
    let month = dateData[1];
    let date = dateData[2];

    return { year, month, date };
  }

  const getMonthStr = month => (!(props.locale === "en") ? monthMap : monthMapInEnglish)[Math.max(Math.min(11, month), 0)] || 'Month';

  const updateDate = dateData => {
    if (Boolean(dateData)) {
      let selectedDate = getDateMilliseconds(dateData.year + "-" + dateData.month + "-" + dateData.date)
      setSelectedDay(selectedDate);

      if (props.onChange) {
        props.onChange(props.name, selectedDate, getDateFromMilliseconds(selectedDate), BSToAD(getDateFromMilliseconds(selectedDate)));
      }
    } else {
      if (props.onChange) {
        props.onChange(props.name, null, null, null);
      }
    }
  }

  const updateDateFromInput = () => {
    setInvalidDateError({});
    let dateValue = inputRef.current.value;
    let dateData = getDateFromDateString(dateValue);
    updateDate(dateData);
    if (dateData !== null) {
      setYear(dateData.year);
      setMonth(dateData.month - 1);
      setMonthDetails(getMonthDetails(dateData.year, dateData.month - 1));
    }
  }

  const updateDateToInput = (timestamp) => {
    let dateString = getDateFromMilliseconds(timestamp);
    inputRef.current.value = dateString;
    setInvalidDateError({});
  }

  const onDateClick = day => {
    setSelectedDay(day.timestamp);
    updateDateToInput(day.timestamp);
    if (props.onChange) {
      props.onChange(props.name, day.timestamp, getDateFromMilliseconds(day.timestamp), BSToAD(getDateFromMilliseconds(day.timestamp)));
    }
    setShowDatePicker(false);
  }

  const updateYear = offset => {
    if ((year > 1970 && year < 2099) || ((year === 1970) && offset > 0) || (year === 2099 && offset < 0)) {
      let updatedYear = Number(year) + offset;
      let updatedMonth = month;
      setYear(updatedYear);
      setMonthDetails(getMonthDetails(updatedYear, updatedMonth));
    } else {
      alert("माफ गर्नुहोस हामीसंग १९७० देखि २०९९ सम्मको मात्र पात्रो मात्र अवस्थित छ");
    }
  }

  const updateMonth = offset => {
    let updatedYear = year;
    let updatedMonth = Number(month) + offset;
    if (updatedMonth === -1) {
      if (year > 1970) {
        updatedMonth = 11;
        updatedYear--;
      } else {
        updatedMonth -= offset;
        alert("माफ गर्नुहोस हामीसंग १९७० भन्दा कम सालको पात्रो अवस्थित छैन");
      }
    } else if (updatedMonth === 12) {
      if (year < 2099) {
        updatedMonth = 0;
        updatedYear++;
      } else {
        updatedMonth -= offset;
        alert("माफ गर्नुहोस हामीसंग २०९९ भन्दा बढी सालको पात्रो अवस्थित छैन ");
      }
    }
    setYear(updatedYear);
    setMonth(updatedMonth);
    setMonthDetails(getMonthDetails(updatedYear, updatedMonth));
  }

  const updateToCurrentDate = () => {
    updateDateToInput(todayTimestamp);
    updateDateFromInput();
  }

  const renderCalendar = () => {
    let days = monthDetails.map((day, index) => {
      return (
        <div className={'nc-day-container ' + (day.month !== 0 ? ' disabled' : '') +
          (isCurrentDay(day) ? ' highlight' : '') + (isSelectedDay(day) ? ' highlight-green' : '')} key={index}>
          <div className='cdc-day'>
            <span onClick={() => onDateClick(day)}>
              {(props.locale === "en") ? day.date : replaceWithNepaliDigit(day.date)}
            </span>
          </div>
        </div>
      )
    })

    return (
      <div className='inc-container'>
        <div className='cc-head'>
          {(!(props.locale === "en") ? weekDaysInShort : weekDaysInShortInEnglish).map((d, i) => <div key={i} className='cch-name'>{d}</div>)}
        </div>
        <div className='cc-body'>
          {days}
        </div>
      </div>
    )
  }

  return (
    <div ref={datePickerRef}>
      <div className={props.inputContainerClassName}>
        <input
          id={props.id}
          name={props.name}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          ref={inputRef}
          onChange={updateDateFromInput}
          autoComplete={props.autoComplete}
          disabled={props.disabled}
          onClick={() => setShowDatePicker(true)}
          onKeyDown={e => (e.key === "Tab") ? setShowDatePicker(false) : (!showDatePicker && setShowDatePicker(true))}
        />
        <label htmlFor={props.id}>
          {props.label}
        </label>
      </div>
      {invalidDateError.invalidFormat && <span className="error-message">{props.invalidFormatMessage}</span>}
      {invalidDateError.maxDateError && <span className="error-message">{props.maxDateErrorMessage}</span>}
      {invalidDateError.minDateError && <span className="error-message">{props.minDateErrorMessage}</span>}
      {invalidDateError.invalidDate && <span className="error-message">{props.invalidDateMessage}</span>}
      {showDatePicker ? (
        <div className='nc-container'>
          <div className='nc-head'>
            <div className='nc-button'>
              <div className='nc-inner' onClick={() => updateYear(-1)}>
                <span className='nc-left-arrows'></span>
              </div>
            </div>
            <div className='nc-button'>
              <div className='nc-inner' onClick={() => updateMonth(-1)}>
                <span className='nc-left-arrow'></span>
              </div>
            </div>
            <div className='ndpch-container'>
              <div className='ndpchc-year'>{(props.locale === "en") ? year : replaceWithNepaliDigit(year)}</div>
              <div className='ndpchc-month'>{getMonthStr(month)}</div>
            </div>
            <div className='nc-button'>
              <div className='nc-inner' onClick={() => updateMonth(1)}>
                <span className='nc-right-arrow'></span>
              </div>
            </div>
            <div className='nc-button' onClick={() => updateYear(1)}>
              <div className='nc-inner'>
                <span className='nc-right-arrows'></span>
              </div>
            </div>
            <div className='nc-button' onClick={updateToCurrentDate}>
              <div className='nc-inner'>
                <small>आज</small>
              </div>
            </div>
          </div>
          <div className='nc-body'>
            {renderCalendar()}
          </div>
        </div>
      ) : ''}
    </div>
  );
}

NepaliCalender.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  backgroundColor: PropTypes.string,
  showDefaultDate: PropTypes.bool,
  defaultDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  defaultFormat: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  locale: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  maxDateErrorMessage: PropTypes.string,
  minDateErrorMessage: PropTypes.string,
  invalidDateMessage: PropTypes.string,
  invalidFormatMessage: PropTypes.string,
  autoComplete: PropTypes.string,
};

NepaliCalender.defaultProps = {
  id: "nepali-calendar",
  label: "मिति",
  backgroundColor: "#fff",
  showDefaultDate: false,
  defaultFormat: false,
  disabled: false,
  placeholder: "YYYY-MM-DD",
  maxLength: 10,
  locale: "ne",
  inputContainerClassName: "input-label-container",
  maxDateErrorMessage: "२०९९ साल भन्दा बढी मिति समावेश हुने छैन",
  minDateErrorMessage: "१९७० साल भन्दा कम मिति समावेश हुने छैन",
  invalidFormatMessage: "मान्य ढाँचामा नभएको मिति समावेश हुने छैन",
  invalidDateMessage: "पात्रोमा नभएको मिति समावेश गरिने छैन",
  autoComplete: "off"
}