import { Box, Button, Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import NepaliCalender from "../../../../components/nepali-calender";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import styles from "./style";

export default function AddOtherDetails({ onOtherDetailsUpdate, otherDetails, ...props }) {
  const classes = styles();
  const { register, handleSubmit, setValue } = useForm();
  const staffId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    register({ name: "accumulationFundNumberReleaseDate" });
  }, [register]);

  useEffect(() => {
    if (otherDetails) {
      setValue("accumulationFundNumberReleaseDate", otherDetails.accumulationFundNumberReleaseDate);
    }
  }, [otherDetails])

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli)
  }

  const onSubmit = data => {
    let postdata = {
      id: staffId,
      othersInfo: data
    }

    PIS.post(API_URL.staff, postdata)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onOtherDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="उचाई"
                name="height"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                defaultValue={otherDetails.height}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="रक्त समूह"
                name="bloodGroup"
                type="text"
                variant="outlined"
                size="small"
                defaultValue={otherDetails.bloodGroup}
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="क.सं. कोष परिचय पत्र नं."
                name="accumulationFundNumber"
                type="text"
                variant="outlined"
                size="small"
                defaultValue={otherDetails.accumulationFundNumber}
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <Tooltip title="क.सं. कोष जारी मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="accumulationFundNumberReleaseDate"
                    id="accumulationFundNumberReleaseDate"
                    label="क.सं. कोष जारी मिति"
                    onChange={handleDateChange}
                    defaultDate={otherDetails?.accumulationFundNumberReleaseDate}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="नागरिक लगानी कोष नं."
                name="citizenInvestmentFundNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                defaultValue={otherDetails.citizenInvestmentFundNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="राहदानी नं."
                name="passportNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                defaultValue={otherDetails.passportNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="सवारी चालक अनुमती नं."
                name="drivingLicenceNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                defaultValue={otherDetails.drivingLicenceNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="फ्याक्स नं."
                name="faxNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                defaultValue={otherDetails.faxNumber}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs={3}>
              <TextField
                label="मोबाईल नम्बर"
                name="mobileNumber"
                type="text"
                variant="outlined"
                size="small"
                defaultValue={otherDetails.mobileNumber}
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="अशक्त विवरण"
                name="differentlyAbledDescription"
                type="text"
                variant="outlined"
                size="small"
                defaultValue={otherDetails.differentlyAbledDescription}
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="कैफियत"
                name="remarks"
                type="text"
                variant="outlined"
                size="small"
                defaultValue={otherDetails.remarks}
                inputRef={register}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !props.otherDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {props.otherDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
