import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete, PhotoCamera } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getSettingData } from "../../../action/settingData";
import PIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, FILE_SIZE_EXCEED, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import { LOGO, SIGNATURE, STAMP } from '../../../utils/constants/forms';
import styles from './style';

export default function Setting() {
  const classes = styles();
  const dispatch = useDispatch();
  const defaultValues = useSelector(state => state.settingData);

  useEffect(() => {
    !defaultValues && dispatch(getSettingData())
  }, [defaultValues])

  function handlePhotoChange(e) {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (2 * 1024 * 1024);
      if (fileSizeValid) {
        var postdata = new FormData();
        postdata.append("files", file);
        PIS.post(API_URL.setting + "/upload-signature", postdata)
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              dispatch(getSettingData());
            }
            AddAlertMessage({
              type: jsondata.type,
              message: jsondata.message,
            });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  function handleStampUploadChange(e) {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (2 * 1024 * 1024);
      if (fileSizeValid) {
        var postdata = new FormData();
        postdata.append("files", file);
        PIS.post(API_URL.setting + "/upload-stamp", postdata)
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              dispatch(getSettingData());
            }
            AddAlertMessage({
              type: jsondata.type,
              message: jsondata.message,
            });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  function handleLogoUploadChange(e) {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (2 * 1024 * 1024);
      if (fileSizeValid) {
        var postdata = new FormData();
        postdata.append("files", file);
        PIS.post(API_URL.setting + "/upload-logo", postdata)
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              dispatch(getSettingData());
            }
            AddAlertMessage({
              type: jsondata.type,
              message: jsondata.message,
            });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  const removeUploadedImage = (type) => {
    let postData = {
      filePath: type === SIGNATURE ? defaultValues?.signatureUrl?.path : type === LOGO ? defaultValues?.logoUrl?.path : defaultValues?.stampUrl?.path,
      fileName: type === SIGNATURE ? defaultValues?.signatureUrl?.name : type === LOGO ? defaultValues?.logoUrl?.name : defaultValues?.stampUrl?.name,
      fileType: type
    }
    PIS.post(API_URL.setting + "/delete", postData).then(response => {
      AddAlertMessage({ type: response.data.type, message: response.data.message })
      if (response.data.type === SUCCESS) {
        dispatch(getSettingData());
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, SOMETHING_WENT_WRONG });
    });
  }

  return (
    <>
      <Grid container className={classes.settingContainer} spacing={2}>
        <Grid item className="border-bottom-heading" xs={12} md={12}>
          <Typography variant="h6">कार्यालय सेटिङ</Typography>
        </Grid>
        <Grid item xs>
          <Box display="flex" alignItems="center">
            <Typography>कार्यालय प्रमुखको दस्तखत: </Typography>
            <Box className={classes.imageContainer} mx={2}>
              {defaultValues?.signatureUrl && <img className={classes.signature} src={defaultValues?.signatureUrl.absolutePath} alt="signature" />}
            </Box>
            <>
              <input name="photo" accept="image/*" className={classes.hiddenInput} id="photo" type="file" onChange={handlePhotoChange} />
              <label htmlFor="photo">
                <Tooltip title="कृपया दस्तखत चयन गर्दा 2MB भन्दा कम साइज भएको पारदर्शी (Transparent) फोटो चयन गर्नुहोस्।" placement="top" arrow>
                  <IconButton color="primary" aria-label="upload signature" component="span">
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </label>
            </>
            {defaultValues?.signatureUrl && <Tooltip title="दस्तखत हटाउनुहोस्।" placement="top" arrow>
              <IconButton color="secondary" aria-label="remove signature" component="span" onClick={() => removeUploadedImage(SIGNATURE)}>
                <Delete />
              </IconButton>
            </Tooltip>}
          </Box>
        </Grid>
        <Grid item xs>
          <Box display="flex" alignItems="center">
            <Typography>कार्यालयको छाप: </Typography>
            <Box className={classes.imageContainer} mx={2}>
              {defaultValues?.stampUrl && <img className={classes.signature} src={defaultValues?.stampUrl.absolutePath} alt="stamp" />}
            </Box>
            <>
              <input name="stamp" accept="image/*" className={classes.hiddenInput} id="stamp" type="file" onChange={handleStampUploadChange} />
              <label htmlFor="stamp">
                <Tooltip title="कृपया कार्यालयको छाप चयन गर्दा 2MB भन्दा कम साइज भएको पारदर्शी (Transparent) फोटो चयन गर्नुहोस्।" placement="top" arrow>
                  <IconButton color="primary" aria-label="upload stamp" component="span">
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </label>
            </>
            {defaultValues?.stampUrl && <Tooltip title="कार्यालयको छाप हटाउनुहोस्।" placement="top" arrow>
              <IconButton color="secondary" aria-label="remove stamp" component="span" onClick={() => removeUploadedImage(STAMP)}>
                <Delete />
              </IconButton>
            </Tooltip>}
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.settingContainer} spacing={2}>
        <Grid item xs sm={6}>
          <Box display="flex" alignItems="center">
            <Typography>कार्यालयको लोगो: </Typography>
            <Box className={classes.imageContainer} mx={2}>
              {defaultValues?.logoUrl && <img className={classes.signature} src={defaultValues?.logoUrl.absolutePath} alt="stamp" />}
            </Box>
            <>
              <input name="stamp" accept="image/*" className={classes.hiddenInput} id="logo" type="file" onChange={handleLogoUploadChange} />
              <label htmlFor="logo">
                <Tooltip title="कृपया कार्यालयको लोगो चयन गर्दा 2MB भन्दा कम साइज भएको पारदर्शी (Transparent) फोटो चयन गर्नुहोस्।" placement="top" arrow>
                  <IconButton color="primary" aria-label="upload stamp" component="span">
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </label>
            </>
            {defaultValues?.logoUrl && <Tooltip title="कार्यालयको लोगो हटाउनुहोस्।" placement="top" arrow>
              <IconButton color="secondary" aria-label="remove stamp" component="span" onClick={() => removeUploadedImage(LOGO)}>
                <Delete />
              </IconButton>
            </Tooltip>}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}