import { Box, Checkbox, FormControlLabel, Grid, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../../api/api";
import AddAlertMessage from "../../../../../components/alert/Alert";
import CustomSelect from "../../../../../components/custom-select/CustomSelect";
import CustomModal from "../../../../../components/modal/CustomModal";
import NepaliCalender from "../../../../../components/nepali-calender";
import { AppUtils } from "../../../../../utils/appUtils";
import { ENTER_VALID_EMAIL, ERROR, GENDER_OPTIONS, ID, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../../utils/constants";
import { EMPLOYEE_TYPE } from "../../../../../utils/constants/forms";
import styles from "./style";

export default function AddPersonalDetails({ onPersonalDetailsUpdate, showAddPersonalDetailModal, closeAddPersonalDetailModal, ...props }) {
  const classes = styles();
  const { register, setValue, handleSubmit, errors, reset } = useForm();
  const staffId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    register({ name: "gender" }, { required: true });
    register({ name: "employeeType" });
    register({ name: "dateOfBirth" }, { required: true });
  }, [register]);

  useEffect(() => {
    props.personalDetails && reset(props.personalDetails);
  }, [reset, props.personalDetails]);

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli)
  }

  const onSubmit = data => {
    let postdata = {
      id: staffId || props.personalDetails.id,
      personalInfo: data
    }

    PIS.post(API_URL.staff, postdata)
      .then(response => {
        if (response.data.type === SUCCESS) {
          reset({});
          onPersonalDetailsUpdate(response.data.data || postdata.id);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <CustomModal
      title="नयाँ कर्मचारी विवरण थप्नुहोस्"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddPersonalDetailModal}
      onModalClose={closeAddPersonalDetailModal}
      maxWidth="lg"
    >
      <Box>
        <Grid container alignItems="flex-start" spacing={2} className={classes.row}>
          <Grid item xs={3}>
            <TextField
              label="क्रम संख्या"
              name="serialNumber"
              type="number"
              variant="outlined"
              size="small"
              inputRef={register({
                required: true
              })}
              fullWidth
            />
            {errors.serialNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="पुरा नाम अङ्ग्रेजीमा"
              name="fullNameInEnglish"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register({
                required: true
              })}
              fullWidth
            />
            {errors.fullNameInEnglish && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="पुरा नाम नेपालीमा"
              name="fullNameInNepali"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register({
                required: true
              })}
              fullWidth
            />
            {errors.fullNameInNepali && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <CustomSelect
              label="लिङ्ग"
              name="gender"
              defaultValue={props.personalDetails && props.personalDetails.gender}
              options={GENDER_OPTIONS}
              onChange={handleCustomSelectChange}
            />
            {errors.gender && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="नागरिकता नं."
              name="citizenshipNumber"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register({
                required: true
              })}
              fullWidth
            />
            {errors.citizenshipNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <CustomSelect
              label="Employee Type"
              name="employeeType"
              defaultValue={props.personalDetails && props.personalDetails.employeeType}
              options={EMPLOYEE_TYPE}
              onChange={handleCustomSelectChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="कर्मचारी संकेत नं."
              name="staffCodeNumber"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="जन्म मिति (बि.सं.)" placement="top" arrow>
              <Box>
                <NepaliCalender
                  name="dateOfBirth"
                  id="dateOfBirth"
                  defaultDate={props.personalDetails && props.personalDetails.dateOfBirth}
                  onChange={handleDateChange}
                  label="जन्म मिति (बि.सं.)"
                />
              </Box>
            </Tooltip>
            {errors.dateOfBirth && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="राष्ट्रीय परिचय पत्र नं."
              name="nationalIdentityCardNumber"
              type="text"
              variant="outlined"
              size="small"
              inputRef={register}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="वडा मा कार्यरत कर्मचारीहरुको लागि मात्र" placement="top">
              <TextField
                label="कार्यरत वडा नं."
                name="workingWardNumber"
                type="number"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="ईमेल"
              size="small"
              variant="outlined"
              name="email"
              inputRef={register({
                pattern: /\S+@\S+\.\S+/
              })}
            />
            {errors.email && errors.email.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox color="primary" defaultChecked={props.personalDetails && props.personalDetails.showInPublicPage} />}
              label="पब्लिक गृहपृष्ठ मा देखाउनुहोस्।"
              name="showInPublicPage"
              inputRef={register}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox color="primary" defaultChecked={props.personalDetails && props.personalDetails.formerEmployee} />}
              label="भूतपूर्व कर्मचारी"
              name="formerEmployee"
              inputRef={register}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
};