import { Avatar, Box, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { Delete, Edit, PermIdentity, Print } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import CustomTable from "../../../../components/custom-table/CustomTable";
import CustomModal from "../../../../components/modal/CustomModal";
import { AppMisc } from "../../../../misc/appMisc";
import { AppUtils } from "../../../../utils/appUtils";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants";
import { DateUtils } from "../../../../utils/dateUtils";
import Card from "../../components/card/Card";
import SearchAndPagination from "../../HOC/searchandpagination/SearchandPagination";
import StaffDetails from "../../staff-details/StaffDetails";
import styles from "../style";

function StaffsList(props) {
  const classes = styles();
  const printCvRef = useRef();
  const printCardRef = useRef();
  const [postOptions, setPostOptions] = useState([]);
  const [staffData, setStaffData] = useState({});
  const [showDeleteStaffModal, setShowDeleteStaffModal] = useState(false);
  const [tableRowId, setTableRowId] = useState(null);

  const handleDepartmentDelete = (id) => {
    setShowDeleteStaffModal(true);
    setTableRowId(id);
  }

  const getAllPostList = () => {
    PIS.get(API_URL.post)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPostOptions((jsondata.data || []).map(item => {
            return { value: item.id, label: item.name }
          }));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  useEffect(() => {
    getAllPostList()
  }, [])

  const deleteTableRow = () => {
    PIS.delete(API_URL.staff + "/" + tableRowId).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        props.getTableData();
        setShowDeleteStaffModal(false);
      }
      AddAlertMessage({ type: jsondata.type, message: jsondata.message })
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    })
  }

  const getStaffDetails = async id => {
    await PIS.get(API_URL.staff + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        jsondata.data["personalInfo"]["dateOfBirthInBS"] = jsondata.data["personalInfo"]["dateOfBirth"] && DateUtils.getDateFromMilliseconds(jsondata.data["personalInfo"]["dateOfBirth"]);
        if (jsondata.data.appointedServiceInfo) {
          jsondata.data.appointedServiceInfo["applicationDateInBS"] = jsondata.data.appointedServiceInfo["applicationDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.appointedServiceInfo["applicationDate"]);
          jsondata.data.appointedServiceInfo["decisionDateInBS"] = jsondata.data.appointedServiceInfo["decisionDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.appointedServiceInfo["decisionDate"]);
          jsondata.data.appointedServiceInfo["officeAttendanceDateInBS"] = jsondata.data.appointedServiceInfo["officeAttendanceDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.appointedServiceInfo["officeAttendanceDate"]);
        }
        if (jsondata.data.currentServiceInfo) {
          jsondata.data.currentServiceInfo["applicationDateInBS"] = jsondata.data.currentServiceInfo["applicationDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["applicationDate"]);
          jsondata.data.currentServiceInfo["decisionDateInBS"] = jsondata.data.currentServiceInfo["decisionDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["decisionDate"]);
          jsondata.data.currentServiceInfo["officeAttendanceDateInBS"] = jsondata.data.currentServiceInfo["officeAttendanceDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["officeAttendanceDate"]);
          jsondata.data.currentServiceInfo["appointmentToCurrentPostDateInBS"] = jsondata.data.currentServiceInfo["appointmentToCurrentPostDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["appointmentToCurrentPostDate"]);
        }
        if (jsondata.data.othersInfo) {
          jsondata.data.othersInfo["accumulationFundNumberReleaseDateInBS"] = jsondata.data.othersInfo["accumulationFundNumberReleaseDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.othersInfo["accumulationFundNumberReleaseDate"]);
        }

        if (jsondata.data.retirementDetail) {
          jsondata.data.retirementDetail["dateInBS"] = jsondata.data.retirementDetail["date"] && DateUtils.getDateFromMilliseconds(jsondata.data.retirementDetail["date"]);
          jsondata.data.retirementDetail["estimatedDate"] = jsondata.data.retirementDetail["estimatedDate"] && new Date(jsondata.data.retirementDetail["estimatedDate"]).toLocaleDateString();
        }
        setStaffData(jsondata.data);
      }
    })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  function buildColumns() {
    let columns = [
      {
        Header: "क्र. सं.",
        Cell: (row) => <p>{AppUtils.replaceWithNepaliDigit((props.pageSize * props.rowSize) + row.row.index + 1 - props.rowSize)}</p>,
      },
      {
        Header: "फोटो",
        accessor: "personalInfo.photo",
        Cell: (row) => (
          <Box display="flex" justifyContent="center">
            <Avatar alt="Staff Photo" src={row.value?.absolutePath} />
          </Box>
        ),
      },
      {
        Header: "नाम",
        accessor: "personalInfo.fullNameInNepali",
        Cell: (row) => (
          <a href={`staff-details?id=${row.row.original.id}`}>
            {row.value || "-"}
          </a>
        ),
      },
      {
        Header: "लिङ्ग",
        accessor: "personalInfo.gender",
        Cell: (row) => (<>{row.value && AppMisc.getLabelFromValue(row.value, GENDER_OPTIONS)}</>)
      },
      {
        Header: "पद",
        accessor: "currentServiceInfo.post",
        Cell: (row) => <>{AppMisc.getLabelFromValue(row.value, postOptions)}</>,
      },
      {
        Header: "कर्मचारी संकेत नं.",
        accessor: "personalInfo.staffCodeNumber",
      },
      {
        Header: "फोन नं.",
        accessor: "othersInfo.mobileNumber",
      },
    ];
    if (props.showActionColumn) {
      columns.push({
        Header: "सम्पादन",
        accessor: "id",
        className: "print-none",
        Cell: (row) => (
          <Box className="print-none" display="flex" justifyContent="space-between">
            <IconButton className={classes.actionBtn} onClick={() => props.editRow(row.value)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
            <IconButton className={classes.actionBtn} color="secondary" onClick={() => handleDepartmentDelete(row.value)} aria-label="delete">
              <Delete fontSize="small" />
            </IconButton>
            <Tooltip title="Print CV" placement="top" arrow>
              <Box>
                <ReactToPrint
                  trigger={
                    () => <IconButton aria-label="print" className={classes.actionBtn}>
                      <Print fontSize="small" />
                    </IconButton>
                  }
                  content={() => printCvRef.current}
                  onBeforeGetContent={() => getStaffDetails(row.value)}
                />
              </Box>
            </Tooltip>
            <Tooltip title="Print ID Card" placement="top" arrow>
              <Box>
                <ReactToPrint
                  trigger={
                    () => <IconButton aria-label="print" className={classes.actionBtn}>
                      <PermIdentity fontSize="small" />
                    </IconButton>
                  }
                  content={() => printCardRef.current}
                  onBeforeGetContent={() => getStaffDetails(row.value)}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      });
    }
    return columns;
  }

  const columns = buildColumns();

  return (
    <>
      <Box className={`${classes.printOnlyBlock} print-none`}>
        <StaffDetails id={staffData.id} ref={printCvRef} staffData={staffData} />
      </Box>
      <Box className={`${classes.printOnlyBlock} print-none`}>
        <Card staffData={staffData} postOptions={postOptions} ref={printCardRef} />
      </Box>
      <Paper elevation={0}>
        <CustomTable isLoading={props.isLoading} hideGlobalFilter columns={columns} data={props.tableData} />
      </Paper>
      <CustomModal
        title="कर्मचारी विवरण हटाउनुहोस्।"
        onModalSubmit={deleteTableRow}
        showModal={showDeleteStaffModal}
        onModalClose={() => setShowDeleteStaffModal(false)}
        submitButtonText="हटाउनुहोस्"
      >
        <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित कर्मचारी विवरण हटाउन चाहनुहुन्छ?</Typography>
        <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
      </CustomModal>
    </>
  );
}

export default SearchAndPagination(StaffsList);