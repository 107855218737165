import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(1),
  },
  hiddenInput: {
    display: "none",
  },
  largeAvatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  pagination: {
    display: "flex",
    justifyContent:"center",
    padding: theme.spacing(2),
  },
  actionBtn: {
    padding: theme.spacing(.5)
  },
}));
export default styles;