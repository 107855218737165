import PropTypes from "prop-types";
import React from "react";
import { Line } from "react-chartjs-2";

export default function LineChart({ data, ...props }) {

  const chartData = {
    labels: props.labels,
    datasets: [
      {
        label: props.diagramLabel,
        data: data,
        fill: props.fill,
        backgroundColor: props.backgroundColor,
        borderColor: props.borderColor
      }
    ]
  };

  return (
    <Line
      data={chartData}
      options={props.options}
      {...props}
    />
  );
}

LineChart.propTypes = {
  diagramLabel: PropTypes.string,
  labels: PropTypes.array,
  data: PropTypes.array,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  fill: PropTypes.bool,
  options: PropTypes.object
};

LineChart.defaultProps = {
  label: "",
  labels: [],
  data: [],
  backgroundColor: 'rgb(255, 99, 132)',
  borderColor: 'rgba(255, 99, 132, 0.6)',
  fill: false,
  options: {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }
};
