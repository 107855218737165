import { Box, Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomModal from "../../../components/modal/CustomModal";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddSubDepartmentModal from "./helpers/AddSubDepartmentModal";
import SubDepartmentsList from "./helpers/SubDepartmentsList";
import styles from "./style";

export default function SubDepartments() {
  const classes = styles();
  const [openAddSubDepartmentModal, setOpenAddSubDepartmentModal] = useState(false);
  const [subDepartmentTableData, setSubDepartmentTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [showDeleteDepartmentModal, setShowDeleteSubDepartmentModal] = useState(false);
  const [tableRowId, setTableRowId] = useState(null);

  useEffect(() => {
    getSubDepartmentsData();
  }, []);

  const handleAddSubDepartmentModalClose = () => {
    setModalDefaultValues({});
    setOpenAddSubDepartmentModal(false);
  }

  const handleCustomModelClose = () => {
    setShowDeleteSubDepartmentModal(false)
    setTableRowId(null)
  }

  const getSubDepartmentsData = () => {
    PIS.get(API_URL.subDepartment)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setSubDepartmentTableData(response.data.data || []);
          setOpenAddSubDepartmentModal(false);
        }
        else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleAddSubDepartmentModalSubmit = data => {
    data.id = modalDefaultValues.id;
    PIS.post(API_URL.subDepartment, data)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          handleAddSubDepartmentModalClose();
          getSubDepartmentsData();
        }
        else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleEditSubDepartment = id => {
    PIS.get(API_URL.subDepartment + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setModalDefaultValues(jsondata.data);
        setOpenAddSubDepartmentModal(true);
      }
      else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleSubDepartmentDelete = (id) => {
    setShowDeleteSubDepartmentModal(true);
    setTableRowId(id);
  }

  const deleteTableRow = () => {
    PIS.delete(API_URL.subDepartment + "/" + tableRowId).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        getSubDepartmentsData();
        setShowDeleteSubDepartmentModal(false);
      }
      else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.subDepartmentsHeading}>
          <Typography variant="h6">उपशाखाहरु</Typography>
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddSubDepartmentModal(true) }}>नयाँ उपशाखा थप्नुहोस्</Button>
          </Box>
        </Box>
        <Box textAlign="center">
          <CustomModal
            title="उपशाखा हटाउनुहोस्।"
            onModalSubmit={deleteTableRow}
            showModal={showDeleteDepartmentModal}
            onModalClose={handleCustomModelClose}
            submitButtonText="हटाउनुहोस्"
          >
            <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ यस उपशाखालाई हटाउन चाहनुहुन्छ?</Typography>
            <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
          </CustomModal>
        </Box>
        <Box>
          <SubDepartmentsList tableData={subDepartmentTableData} onEditRow={handleEditSubDepartment} onDeleteRow={handleSubDepartmentDelete} showActionColumn={Boolean(subDepartmentTableData?.length)} />
        </Box>
        <AddSubDepartmentModal handleModalSubmit={handleAddSubDepartmentModalSubmit} showAddSubDepartmentModal={openAddSubDepartmentModal} handleAddSubDepartmentModalClose={handleAddSubDepartmentModalClose} defaultValues={modalDefaultValues} />
      </Box>
    </>
  );
}