import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ENTER_VALID_EMAIL, REQUIRED_FIELD } from "../../utils/constants";
import CustomSelect from "../custom-select/CustomSelect";
import CustomModal from "../modal/CustomModal";
import styles from "./style";

export default function AddUserModal({ showAddUserModal, handleAddUserModalClose, onSubmit, ...props }) {
  const classes = styles();
  const { register, setValue, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    register({ name: "role" }, { required: true });
  }, [register]);

  useEffect(() => {
    props.modalDefaultValues && reset(props.modalDefaultValues);
  }, [reset, props.modalDefaultValues])

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  function closeAddUserModal() {
    reset();
    handleAddUserModalClose();
  }

  return (
    <>
      <CustomModal
        title="नयाँ प्रयोगकर्ता थप्नुहोस्"
        onModalSubmit={handleSubmit(onSubmit)}
        showModal={showAddUserModal}
        onModalClose={closeAddUserModal}
      >
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              required
              label="User Full Name"
              type="text"
              variant="outlined"
              size="small"
              name="fullName"
              inputRef={register({
                required: true
              })}
            />
            {errors.fullName && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              required
              label="User Email"
              type="email"
              size="small"
              variant="outlined"
              name="email"
              inputRef={register({
                required: true,
                pattern: /\S+@\S+\.\S+/
              })}
            />
            {errors.email && errors.email.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            {errors.email && errors.email.type === "pattern" && <span className="error-message">{ENTER_VALID_EMAIL}</span>}
          </Grid>
          <Grid item xs>
            <CustomSelect
              label="प्रयोगकर्ताको भूमिका"
              name="role"
              defaultValue={props.modalDefaultValues.role}
              options={props.userRoles}
              onChange={handleCustomSelectChange}
            />
            {errors.role && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs>
            <FormControlLabel
              inputRef={register}
              control={
                <Switch color="primary" defaultChecked={props.modalDefaultValues.active} name="active" />
              }
              label="सक्रिय / निष्क्रिय"
              classes={{
                label: classes.switchLabelSmall,
              }}
            />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};