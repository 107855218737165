import { AccountCircle, Settings, AccountBalance, Assessment, ContactPhone, DateRange, Description, FileCopy, GroupAdd, Home, PeopleAlt, Search, Category, CastForEducation } from "@material-ui/icons";
import React from "react";

export const PIS_ADMIN_SIDEBAR_LINKS = [
  {
    id: 0,
    label: "Home",
    link: "dashboard",
    iconComponent: <Home fontSize="small" />
  },
  {
    id: 1,
    label: "Search Exception",
    link: "search-business-exception",
    iconComponent: <Search fontSize="small" />
  },
  {
    id: 2,
    label: "Execute DB Operation",
    link: "system-setup",
    iconComponent: <Settings fontSize="small" />
  },
  {
    id: 3,
    label: "Import Staff Details",
    link: "import-staff-details",
    iconComponent: <FileCopy fontSize="small" />
  },
  {
    id: 4,
    label: "Import Public Representative",
    link: "import-public-representative",
    iconComponent: <FileCopy fontSize="small" />
  }
];

export const PIS_OFFICE_ADMIN_SIDEBAR_LINKS = [
  {
    id: 0,
    label: "गृहपृष्ठ",
    link: "dashboard",
    iconComponent: <Home fontSize="small" />
  },
  {
    id: 1,
    label: "कर्मचारी विवरण",
    link: "staffs",
    iconComponent: <PeopleAlt fontSize="small" />
  },
  {
    id: 2,
    label: "जन प्रतिनिधि विवरण",
    link: "public-representatives",
    iconComponent: <PeopleAlt fontSize="small" />
  },
  {
    id: 3,
    label: "प्रयोगकर्ता व्यवस्थापन",
    link: "user-management",
    iconComponent: <GroupAdd fontSize="small" />
  },
  {
    id: 4,
    label: "कर्मचारी खोज्नुहोस",
    link: "search-staff",
    iconComponent: <Search fontSize="small" />
  },
  {
    id: 5,
    label: "बिदा रिपोर्ट",
    link: "leave-report",
    iconComponent: <Assessment fontSize="small" />
  },
  {
    id: 6,
    label: "नमुना फारम",
    link: "pdf-form",
    iconComponent: <Description fontSize="small" />
  },
  {
    id: 7,
    label: "समस्या रिपोर्ट गर्नुहोस्",
    link: "contact-us",
    iconComponent: <ContactPhone fontSize="small" />,
  },
  {
    id: 8,
    label: "प्रणाली सेट-अप",
    iconComponent: <Settings fontSize="small" />,
    children: [
      {
        id: 0,
        label: "विभाग／शाखा",
        link: "departments",
        iconComponent: <AccountBalance fontSize="small" />
      },
      {
        id: 1,
        label: "उपशाखा",
        link: "sub-departments",
        iconComponent: <AccountBalance fontSize="small" />
      },
      {
        id: 2,
        label: "श्रेणी/तह",
        link: "category",
        iconComponent: <Category fontSize="small" />
      },
      {
        id: 3,
        label: "पद",
        link: "post",
        iconComponent: <AccountCircle fontSize="small" />
      },
      {
        id: 4,
        label: "शैक्षिक उपाधि",
        link: "educational-qualification",
        iconComponent: <CastForEducation fontSize="small" />
      },
      {
        id: 5,
        label: "बिदा सेटिङ",
        link: "leave-details",
        iconComponent: <DateRange fontSize="small" />
      }
    ]
  }
];
