import { Avatar, Box, IconButton, Tooltip, TextField, Typography } from "@material-ui/core";
import { Delete, Edit, PhotoCamera } from "@material-ui/icons";
import React, { useState } from "react";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import CustomTable from "../../../../components/custom-table/CustomTable";
import CustomModal from "../../../../components/modal/CustomModal";
import { AppMisc } from "../../../../misc/appMisc";
import { AppUtils } from "../../../../utils/appUtils";
import SearchAndPagination from "../../HOC/searchandpagination/SearchandPagination";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, FILE_SIZE_EXCEED, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants";
import styles from "../style";

function PublicRepresentativeList(props) {
  const classes = styles();
  const [publicRepresentativeId, setPublicRepresentativeId] = useState();
  const [rowId, setRowId] = useState(null);
  const [showDeleteConfirmationModel, setShowDeleteConfirmationModel] = useState(false);

  const onDeleteRow = id => {
    setRowId(id);
    setShowDeleteConfirmationModel(true);
  }

  const deleteTableRow = () => {
    PIS.delete(`${API_URL.publicRepresentative}/${rowId}`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          handleDeleteConfirmationModelClose();
          props.getTableData();
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleDeleteConfirmationModelClose = () => {
    setShowDeleteConfirmationModel(false);
    setRowId(null)
  }

  function handlePhotoChange(e) {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (10 * 1024 * 1024);
      if (fileSizeValid) {
        // call photo upload API
        var postdata = new FormData();
        // Backend is using single DTO for staff and representative. Hence, field name id named "staffId".
        postdata.append("staffId", publicRepresentativeId)
        postdata.append("photo", file);

        PIS.post(API_URL.publicRepresentative + "/upload-photo", postdata)
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              props.getTableData();
            }
            AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  function buildColumns() {
    let columns = [
      {
        Header: "क्र. सं.",
        Cell: (row) => (
          <p>{AppUtils.replaceWithNepaliDigit((props.pageSize * props.rowSize) + row.row.index + 1 - props.rowSize)}</p>
        )
      },
      {
        Header: "फोटो",
        accessor: "photo.absolutePath",
        Cell: (row) => (
          <Box display="flex" justifyContent="center">
            <Avatar alt="Staff Photo" src={row.value} />
          </Box>
        )
      },
      {
        Header: "नाम",
        accessor: "fullName",
        Cell: (row) => (
          <a href={`public-representatives-details?id=${row.row.original.id}`}>{row.value || "-"}</a>
        )
      },
      {
        Header: "पद",
        accessor: "post",
      },
      {
        Header: "भूतपूर्व / कार्यारत",
        accessor: "publicRepresentativeLabel",
        className: "print-none",
      },
      {
        Header: "लिङ्ग",
        accessor: "gender",
        Cell: (row) => (
          <Typography>{AppMisc.getLabelFromValue(row.value, GENDER_OPTIONS)}</Typography>
        )
      },
      {
        Header: "उमेर",
        accessor: "age",
      },
      {
        Header: "राजनीतिक दल/स्वतन्त्र",
        accessor: "politicalPartyOrIndependent",
      },
      {
        Header: "ईमेल",
        accessor: "email",
      },
      {
        Header: "वडा",
        accessor: "wardNumber",
      },
      {
        Header: "फोन नं.",
        accessor: "phoneNumber",
      },
    ]
    if (props.showActionColumn) {
      columns.push({
        Header: "सम्पादन",
        accessor: "id",
        className: 'print-none',
        Cell: (row) => (
          <Box className="print-none">
            <IconButton className={classes.actionBtn} onClick={() => props.editRow(row.value)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
            <input name="photo" accept="image/*" className={classes.hiddenInput} id="photo" type="file" onChange={handlePhotoChange} />
            <label htmlFor="photo">
              <Tooltip title="कृपया फोटो चयन गर्दा, दुबै कान देखिने पासपोर्ट साइजको 10MB भन्दा कम साइज भएको फोटो चयन गर्नुहोस्।" placement="top" arrow>
                <IconButton className={classes.uploadIcon} color="primary" aria-label="upload picture" component="span" onClick={() => setPublicRepresentativeId(row.value)}>
                  <PhotoCamera />
                </IconButton>
              </Tooltip>
            </label>
            <IconButton className={classes.actionBtn} onClick={() => onDeleteRow(row.value)} aria-label="edit">
              <Delete fontSize="small" color="secondary" />
            </IconButton>
          </Box>
        )
      })
    }
    return columns;
  }

  const columns = buildColumns();

  return (
    <>
      <CustomTable isLoading={props.isLoading} hideGlobalFilter columns={columns} data={props.tableData} />
      <CustomModal
        title="जन प्रतिनिधि विवरण हटाउनुहोस्।"
        onModalSubmit={deleteTableRow}
        showModal={showDeleteConfirmationModel}
        onModalClose={handleDeleteConfirmationModelClose}
        submitButtonText="हटाउनुहोस्"
      >
        <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित जन प्रतिनिधि विवरण हटाउन चाहनुहुन्छ?</Typography>
        <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
      </CustomModal>
    </>
  );
}

export default SearchAndPagination(PublicRepresentativeList)