import { LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_USER_INFO } from "../utils/constants/redux";

const reducer = (userInfo = null, action) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return userInfo;
  }
}

export default reducer;