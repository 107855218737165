
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import styles from "../../style";

export default function StaffFamilyDetails(props) {
  const classes = styles();

  return (
    <Box className={classes.root} p={2}>
      <Grid container>
        <TableContainer className={classes.familyDetailsTable}>
          <Table size="small" className="table-bordered" aria-label="Staff Family Details">
            <TableBody>
              <TableRow>
                <TableCell>पति/पत्नीको नाम अङ्ग्रेजीमा: {props.familyDetails && (props.familyDetails.husbandOrWifeNameInEnglish || "-")}</TableCell>
                <TableCell>पति/पत्नीको नाम नेपालीमा: {props.familyDetails && (props.familyDetails.husbandOrWifeNameInNepali || "-")}</TableCell>
                <TableCell>पेशा: {props.familyDetails && (props.familyDetails.husbandOrWifeOccupation || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>हजुरबुवाको नाम अङ्ग्रेजीमा: {props.familyDetails && (props.familyDetails.grandfatherNameInEnglish || "-")}</TableCell>
                <TableCell>हजुरबुवाको नाम नेपालीमा: {props.familyDetails && (props.familyDetails.grandfatherNameInNepali || "-")}</TableCell>
                <TableCell>पेशा: {props.familyDetails && (props.familyDetails.grandfatherOccupation || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पिताको नाम अङ्ग्रेजीमा: {props.familyDetails && (props.familyDetails.fatherNameInEnglish || "-")}</TableCell>
                <TableCell>पिताको नाम नेपालीमा: {props.familyDetails && (props.familyDetails.fatherNameInNepali || "-")}</TableCell>
                <TableCell>पेशा: {props.familyDetails && (props.familyDetails.fatherOccupation || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>आमाको नाम अङ्ग्रेजीमा: {props.familyDetails && (props.familyDetails.motherNameInEnglish || "-")}</TableCell>
                <TableCell>आमाको नाम नेपालीमा: {props.familyDetails && (props.familyDetails.motherNameInNepali || "-")}</TableCell>
                <TableCell>पेशा: {props.familyDetails && (props.familyDetails.motherOccupation || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>छोरी संख्या: {props.familyDetails && (props.familyDetails.daughterCount || "-")}</TableCell>
                <TableCell colSpan={2}>छोरा संख्या: {props.familyDetails && (props.familyDetails.sonCount || "-")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
