import { Box, Button, Container, Grid, Link, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { Drafts, Facebook, LinkedIn, MyLocation, Phone, Public, Twitter } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import { ENTER_VALID_EMAIL, REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { FEEDBACK_SUBJECTS } from "../../../utils/constants/forms";
import styles from "./style";

export default function ContactUs(props) {
  const classes = styles();
  const { register, errors, setValue, handleSubmit, reset } = useForm();

  useEffect(() => {
    register({ name: "subject" }, { required: true });
  }, [register])

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  const onSubmit = data => {
    PIS.post(API_URL.contactUs, data)
      .then(response => {
        if (response.data.type === "success") {
          reset();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };
  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Box className={classNames(classes.banner, "gradient-primary")} display="flex" alignItems="center" justifyContent="center">
        <Box className={classes.bannerDetails} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography variant="h5">हामीलाई सम्पर्क गर्नुहोस</Typography>
          <Typography>हामीलाई सन्देश पठाउनुहोस, हामी तपाईलाई चाडै सम्पर्क गर्नेछौ</Typography>
        </Box>
      </Box>

      <Container className={classes.mainContent}>
        <Grid container className={classes.row}>
          <Grid item xs={8}>
            <Paper className={classes.formDetailsContainer} square>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" className={classes.row}>
                  <Grid item xs>
                    <Tooltip title="आफ्नो पुरा नाम र थर उल्लेख गर्नुहोस्।" placement="top" arrow>
                      <TextField
                        label="पुरा नाम थर"
                        type="text"
                        size="small"
                        variant="outlined"
                        name="fullName"
                        inputRef={register({
                          required: true
                        })}
                        fullWidth
                      />
                    </Tooltip>
                    {errors.fullName && <span className="error-message">{REQUIRED_FIELD}</span>}
                  </Grid>
                  <Grid item xs>
                    <Tooltip title="आफ्नो इ-मेल उल्लेख गर्नुहोस्।" placement="top" arrow>
                      <TextField
                        label="इ-मेल"
                        type="text"
                        size="small"
                        variant="outlined"
                        name="email"
                        inputRef={register({
                          pattern: /\S+@\S+\.\S+/,
                          required: true
                        })}
                        fullWidth
                      />
                    </Tooltip>
                    {errors.email && errors.email.type === "required" && (<span className="error-message">{REQUIRED_FIELD}</span>)}
                    {errors.email && errors.email.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className={classes.row}>
                  <Grid item xs>
                    <CustomSelect
                      label="कृपया विषय चयन गर्नुहोस"
                      name="subject"
                      size="small"
                      variant="outlined"
                      options={FEEDBACK_SUBJECTS}
                      onChange={handleCustomSelectChange}
                      disabledOptionSelectable
                      fullWidth
                    />
                    {errors.subject && <span className="error-message">{REQUIRED_FIELD}</span>}
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className={classes.row}>
                  <Grid item xs>
                    <TextField
                      label="सन्देशको विवरण"
                      placeholder="कृपया, विस्तृत रूपमा सन्देश टाइप गर्नुहोस्।"
                      type="text"
                      size="small"
                      rows={10}
                      variant="outlined"
                      name="description"
                      inputRef={register({
                        required: true
                      })}
                      fullWidth
                      multiline
                    />
                    {errors.description && <span className="error-message">{REQUIRED_FIELD}</span>}
                  </Grid>
                </Grid>
                <Box
                  item="true"
                  textAlign="right"
                  className={classes.btnContainer}
                >
                  <Button
                    className={classes.resetBtn}
                    variant="contained"
                    color="secondary"
                    type="reset"
                  >
                    रद्द गर्नुहोस
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    सुरक्षित गर्नुहोस
                  </Button>
                </Box>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.contactDetails} square>
              <Typography className="border-bottom-heading" variant="h6">सम्पर्क जानकारी</Typography>
              <Box className={classes.contactInfo} my={2}>
                <Box className={classes.contactItem}>
                  <MyLocation fontSize="small" className={classes.icon} />
                  <Typography>काठमाडौँ-१०, बुद्धनगर, नेपाल</Typography>
                </Box>
                <Box className={classes.contactItem}>
                  <Phone fontSize="small" className={classes.icon} />
                  <Typography>०१-४७९०२५८</Typography>
                </Box>
                <Box className={classes.contactItem}>
                  <Drafts fontSize="small" className={classes.icon} />
                  <Typography>support@codefusiontech.com</Typography>
                </Box>
                <Box className={classes.contactItem}>
                  <Public fontSize="small" className={classes.icon} />
                  <Link className={classes.link} href="https://www.codefusiontech.com">https://codefusiontech.com</Link>
                </Box>
              </Box>
              <Typography className="border-bottom-heading" variant="h6">Follow Us</Typography>
              <Box className={classes.socialLinks} mt={2}>
                <Link className={classes.socialIcon} color="inherit" href="https://www.facebook.com/codefusiontech">
                  <Facebook />
                </Link>
                <Link className={classes.socialIcon} color="inherit" href="https://twitter.com/codefusiontech">
                  <Twitter />
                </Link>
                <Link className={classes.socialIcon} color="inherit" href="https://www.linkedin.com/company/codefusiontech/">
                  <LinkedIn />
                </Link>
              </Box>
            </Paper>

          </Grid>
        </Grid>

      </Container>
    </Container>
  );
}