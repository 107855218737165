import { Box, Button, Grid, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import CustomSelect from '../../../components/custom-select/CustomSelect';
import { ERROR, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import { EMPLOYEE_TYPE } from '../../../utils/constants/forms';
import { DateUtils } from '../../../utils/dateUtils';
import DepartmentSelect from '../components/DepartmentSelect/DepartmentSelect';
import StaffsList from '../staffs/helpers/StaffsList';
import styles from './style';

export default function SearchStaff() {
  const { register, handleSubmit, setValue } = useForm();
  const [searchResult, setSearchResult] = useState(null);
  const [postOptions, setPostOptions] = useState([]);
  const classes = styles();

  useEffect(() => {
    getAllPostList()
  }, [])

  const getAllPostList = () => {
    PIS.get(API_URL.post)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPostOptions((jsondata.data || []).map(item => {
            return { value: item.id, label: item.name }
          }));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  useEffect(() => {
    register({ name: "gender" });
    register({ name: "employeeType" });
    register({ name: "departmentId" });
  }, [register])

  function getGenderLabel(gender) {
    return gender ? GENDER_OPTIONS.find(obj => obj.value === gender).label : "-";
  }

  const onSubmit = data => {
    PIS.get(`${API_URL.staff}/search?fullNameInEnglish=${data.fullNameInEnglish}&fullNameInNepali=${data.fullNameInNepali}&gender=${data.gender}&employeeType=${data.employeeType}&departmentId=${data.departmentId}&staffCodeNumber=${data.staffCodeNumber}&workingWardNumber=${data.workingWardNumber}`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          for (let i = 0; i < jsondata.data.length; i++) {
            jsondata.data[i].personalInfo.gender = getGenderLabel(jsondata.data[i].personalInfo.gender);
            jsondata.data[i].personalInfo.dateOfBirth = jsondata.data[i].personalInfo.dateOfBirth !== 0 ? DateUtils.getDateFromMilliseconds(jsondata.data[i].personalInfo.dateOfBirth) : "-";
            jsondata.data[i].personalInfo.citizenshipNumber = jsondata.data[i].personalInfo.citizenshipNumber || "-";
            jsondata.data[i].personalInfo.nationalIdentityCardNumber = jsondata.data[i].personalInfo.nationalIdentityCardNumber || "-";
          }
          setSearchResult(jsondata.data || []);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      }
      )
  }

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.heading}>
        <Typography variant="h6">कर्मचारी खोज्नुहोस</Typography>
      </Box>
      <Paper className={classes.paper} elevation={0}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs={3}>
              <TextField
                label="पुरा नाम अङ्ग्रेजीमा"
                name="fullNameInEnglish"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="पुरा नाम नेपालीमा"
                name="fullNameInNepali"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                label="लिङ्ग"
                name="gender"
                options={GENDER_OPTIONS}
                onChange={handleCustomSelectChange}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                label="Employee Type"
                name="employeeType"
                options={EMPLOYEE_TYPE}
                onChange={handleCustomSelectChange}
              />
            </Grid>
            <Grid item xs={3}>
              <DepartmentSelect
                onChange={handleCustomSelectChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="कर्मचारी संकेत नं."
                name="staffCodeNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="वडा मा कार्यरत कर्मचारीहरुको लागि मात्र" placement="top">
                <TextField
                  label="कार्यरत वडा नं."
                  name="workingWardNumber"
                  type="number"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  fullWidth
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Box item="true" textAlign="right" className={classes.btnContainer}>
            <Button
              type="reset"
              color="secondary"
              variant="contained"
              className={classes.resetBtn}
            >
              रद्द गर्नुहोस
            </Button>
            <Button type="submit" color="primary" variant="contained">
              खोज्नुहोस
            </Button>
          </Box>
        </form>
      </Paper>
      {searchResult && <StaffsList tableData={searchResult} postOptions={postOptions} search />}
    </Box>
  )
}
