import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import logo from "../../assets/img/logo.png";
import styles from "./style";

export default function PublicHeader(props) {
  const classes = styles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <img src={logo} alt="कर्मचारी सूचना व्यवस्थापन प्रणाली" width="36"></img>
        <Typography variant="h6" className={classes.brand}>
          <a href="/">
            कर्मचारी सूचना व्यवस्थापन प्रणाली
          </a>
        </Typography>
        <Box display="flex" className={classes.menuItems} justifyContent="center" alignItems="center">
          <a href="/"><Button variant="contained" color="primary">लग-इन</Button></a>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
