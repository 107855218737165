import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(1.5),
    "@media print": {
      boxShadow: "none",
      background: "none",
    },
  },
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.divider,
  },
  documentsNotUploadedMsg: {
    color: theme.palette.text.secondary,
  },
  hiddenInput: {
    display: "none",
  },
  documentRow: {
    position: "relative",
  },
  avatarContainer: {
    border: "1px solid #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
  },
  largeAvatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
  tableCaption: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  deleteDocumentIcon: {
    position: "absolute",
    top: 0,
    right: 0
  },
  cardContainer: {
    width: "100%",
    padding: theme.spacing(2),
  },
  cardWrapper: {
    display: "flex",
    justifyContent: "space-around",
    "@media print": {
      padding: 0,
      display: "block"
    }
  }
}));
export default styles;