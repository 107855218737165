import React, { useEffect, useState } from "react";
import PIS, { API_URL } from '../../../../api/api';
import AddAlertMessage from '../../../../components/alert/Alert';
import CustomSelect from '../../../../components/custom-select/CustomSelect';
import { ERROR, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from '../../../../utils/constants';

export default function DepartmentSelect(props) {
  const [departments, setDepartments] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    getDepartments();
  }, [])

  useEffect(() => {
    setShowErrorMessage(props.errors)
  }, [props.errors])

  const getDepartments = () => {
    PIS.get(API_URL.department)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          let departmentList = [];
          for (let i = 0; i < jsondata.data.length; i++) {
            departmentList.push({ value: jsondata.data[i].id, label: jsondata.data[i].name })
          }
          setDepartments(departmentList);
          props.totalDepartment && props.totalDepartment(departmentList.length);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  return (
    <>
      <CustomSelect
        label="विभागको नाम"
        name="departmentId"
        options={departments}
        defaultValue={props.defaultValue || (props.showDefaultValue && departments[0].value)}
        onChange={props.onChange}
        isMulti={props.isMulti}
        isClearable={Boolean(props.isMulti)}
        isDisabled={props.disabled}
      />
      {showErrorMessage && (<span className="error-message">{REQUIRED_FIELD}</span>)}
    </>
  );
}