import { Box, Container, Grid, Link, Paper, Typography } from "@material-ui/core";
import { Accessibility, Group, PeopleAlt, PeopleOutline, PeopleOutlineSharp, SupervisedUserCircle } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CountUp from 'react-countup';
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import BarDiagram from "../../../components/charts/BarDiagram";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import PieChart from "../../../components/charts/PieChart";
import { AppUtils } from "../../../utils/appUtils";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import { PALIKA } from "../../../utils/constants/forms";
import styles from "./style";

export default function Dashboard() {
  const classes = styles();
  const [genderReport, setGenderReport] = useState({});
  const [peopleRepresentativeCount, setPeopleRepresentativeCount] = useState(0);
  const [formerPeopleRepresentativeCount, setFormerPeopleRepresentativeCount] = useState(0);
  const [currentPeopleRepresentativeCount, setCurrentPeopleRepresentativeCount] = useState(0);
  const [ageGroupWiseReport, setAgeGroupWiseReport] = useState([]);
  const [employeeTypeReport, setEmployeeTypeReport] = useState([]);
  const [departmentLabels, setDepartmentLabels] = useState([]);
  const [departmentWiseReport, setDepartmentWiseReport] = useState([]);
  const officeType = AppUtils.getOfficeType();

  useEffect(() => {
    getGenderCount();
    getDepartmentWiseCount();
    getAgeGroupWiseStaffCount();
    getEmployeeTypeReport();
  }, [])

  useEffect(() => {
    (officeType === PALIKA) && getPeopleRepresentativeCount()
  }, [officeType])

  const getGenderCount = async () => {
    await PIS.get(`${API_URL.public}?method=GENDER_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setGenderReport(jsondata.data || {});
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getPeopleRepresentativeCount = async () => {
    await PIS.get(`${API_URL.public}?method=PEOPLE_REPRESENTATIVE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPeopleRepresentativeCount(jsondata.data.total);
          setFormerPeopleRepresentativeCount(jsondata.data.former);
          setCurrentPeopleRepresentativeCount(jsondata.data.current);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getDepartmentWiseCount = async () => {
    await PIS.get(`${API_URL.public}?method=DEPARTMENT_WISE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setDepartmentLabels(Object.keys(jsondata.data));
          setDepartmentWiseReport(Object.values(jsondata.data));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getAgeGroupWiseStaffCount = async () => {
    await PIS.get(`${API_URL.public}?method=AGE_GROUP`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          let report = jsondata.data;
          setAgeGroupWiseReport([report.ageGroupBetweenFifteenToTwentyFour, report.ageGroupBetweenTwentyFiveToThirtyFour, report.ageGroupBetweenThirtyFiveToFortyFour, report.ageGroupBetweenFortyFiveToFiftyFour, report.ageGroupBetweenFiftyFiveToSixtyFour, report.ageGroupAboveSixtyFive]);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getEmployeeTypeReport = async () => {
    await PIS.get(`${API_URL.public}?method=EMPLOYEE_TYPE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          let report = jsondata.data;
          setEmployeeTypeReport([report.permanent, report.temporary, report.karar, report.anya]);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getCounts = count => {
    return (
      <>
        <CountUp delay={1} end={count} duration={3.5} />
        <span> जना</span>
      </>
    )
  }

  return (
    <Container maxWidth="xl" className={classes.root} disableGutters>
      <Box className={classes.cardContainer} mb={3}>
        <Paper>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Link href='/user/staffs?filter=MALE'>
              <Box p={4} textAlign="center">
                <Accessibility color="primary" />
                <Typography variant="h5">पुरुष</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.maleEmployee)}</Typography>
              </Box>
            </Link>
            <Link href='/user/staffs?filter=FEMALE'>
              <Box p={4} textAlign="center">
                <SupervisedUserCircle color="primary" />
                <Typography variant="h5">महिला</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.femaleEmployee)}</Typography>
              </Box>
            </Link>
            <Link href='/user/staffs'>
              <Box p={4} textAlign="center">
                <Group color="primary" />
                <Typography variant="h5">जम्मा कर्मचारी</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.totalEmployee)}</Typography>
              </Box>
            </Link>
            <Link href='/user/staffs?formerEmployee=false'>
              <Box p={4} textAlign="center">
                <PeopleAlt color="primary" />
                <Typography variant="h5">कार्यरत कर्मचारी</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.currentStaff)}</Typography>
              </Box>
            </Link>
          </Box>
          {
            officeType === PALIKA &&
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Link href='/user/staffs?formerEmployee=true'>
                  <Box p={4} textAlign="center">
                    <PeopleOutline color="primary" />
                    <Typography variant="h5">भूतपूर्व कर्मचारी</Typography>
                    <Typography color="primary" variant="h4">{getCounts(genderReport.formerStaff)}</Typography>
                  </Box>
                </Link>
                <Link href='/user/public-representatives'>
                  <Box p={4} textAlign="center">
                    <PeopleOutlineSharp color="primary" />
                    <Typography variant="h5">जम्मा जन प्रतिनिधि</Typography>
                    <Typography color="primary" variant="h4">{getCounts(peopleRepresentativeCount)}</Typography>
                  </Box>
                </Link>
                <Link href='/user/public-representatives?formerPublicRepresentative=false'>
                  <Box p={4} textAlign="center">
                    <PeopleAlt color="primary" />
                    <Typography variant="h5">कार्यरत जन प्रतिनिधि</Typography>
                    <Typography color="primary" variant="h4">{getCounts(currentPeopleRepresentativeCount)}</Typography>
                  </Box>
                </Link>
                <Link href='/user/public-representatives?formerPublicRepresentative=true'>
                  <Box p={4} textAlign="center">
                    <PeopleOutline color="primary" />
                    <Typography variant="h5">भूतपूर्व जन प्रतिनिधि</Typography>
                    <Typography color="primary" variant="h4">{getCounts(formerPeopleRepresentativeCount)}</Typography>
                  </Box>
                </Link>
              </Box>
            </>
          }
        </Paper>
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper elevation={0}>
              <DoughnutChart
                chartData={employeeTypeReport}
                labels={['स्थाई', 'आस्थायी', 'करार', 'अन्य']}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} >
              <PieChart
                chartData={departmentWiseReport}
                labels={departmentLabels}
                options={{
                  title: {
                    display: true,
                    text: "शाखा अनुसार कर्मचारी संख्या"
                  },
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box mb={3}>
        <BarDiagram
          data={ageGroupWiseReport}
          labels={['15-24', '25-34', '35-44', '45-54', '55-64', '65+']}
          diagramLabel="उमेर समुह अनुसार कर्मचारी संख्या"
          options={{
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'उमेर समुह',
                }
              }],
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'कर्मचारी संख्या'
                },
              }]
            },
          }}
        />
      </Box>
    </Container>
  );
}
