import { Box, Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import { AppUtils } from "../../../../utils/appUtils";
import { ID, PROVINCE_DISTRICT_PALIKA_LIST, REQUIRED_FIELD, SOMETHING_WENT_WRONG, ERROR, SUCCESS } from "../../../../utils/constants/index";
import styles from "./style";

export default function AddAddressDetails({ onAddressDetailsUpdate, ...props }) {
  const classes = styles();
  const { register, handleSubmit, setValue, errors, reset } = useForm();
  const staffId = AppUtils.getUrlParam(ID);
  const [permanentAddressDistrictsOptions, setPermanentAddressDistrictsOptions] = useState([])
  const [temporaryAddressDistrictsOptions, setTemporaryAddressDistrictsOptions] = useState([])
  const [permanentAddressPalikaOptions, setPermanentAddressPalikaOptions] = useState([]);
  const [temporaryAddressPalikaOptions, setTemporaryAddressPalikaOptions] = useState([]);
  const [permanentAddressDistrictDisable, setIsPermanentAddressDistrictDisable] = useState(true);
  const [temporaryAddressDistrictDisable, setIsTemporaryAddressDistrictDisable] = useState(true);
  const [permanentAddressMunDisable, setIsPermanentAddressMunDisable] = useState(true);
  const [temporaryAddressMunDisable, setIsTemporaryAddressMunDisable] = useState(true);
  const [clearPermanentAddressPalika, setClearPermanentAddressPalika] = useState(false);
  const [clearTemporaryAddressPalika, setClearTemporaryAddressPalika] = useState(false);
  const [clearPermanentAddressDistrict, setClearPermanentAddressDistrict] = useState(false);
  const [clearTemporaryAddressDistrict, setClearTemporaryAddressDistrict] = useState(false);
  const [permanentAddressProvinceLabel, setPermanentAddressProvinceLabel] = useState();
  const [permanentAddressDistrictLabel, setPermanentAddressDistrictLabel] = useState();
  const [permanentAddressPalikaNameLabel, setPermanentAddressPalikaLabel] = useState();
  const [temporaryAddressProvinceLabel, setTemporaryAddressProvinceLabel] = useState();
  const [temporaryAddressDistrictLabel, setTemporaryAddressDistrictLabel] = useState();
  const [temporaryAddressPalikaNameLabel, setTemporaryAddressPalikaLabel] = useState();

  useEffect(() => {
    props.addressDetails && reset(props.addressDetails);
  }, [reset, props.addressDetails])

  useEffect(() => {
    populatePermanentAddress(props.addressDetails?.permanentAddressProvince, props.addressDetails?.permanentAddressDistrict, props.addressDetails?.permanentAddressPalikaName)
    populateTemporaryAddress(props.addressDetails?.temporaryAddressProvince, props.addressDetails?.temporaryAddressDistrict, props.addressDetails?.temporaryAddressPalikaName)
  }, [props.addressDetails])

  function populatePermanentAddress(province, district, palika) {
    let provinceObject = PROVINCE_DISTRICT_PALIKA_LIST.find(obj => obj.value === province);
    handlePermanentAddressProvinceSelectChange("permanentAddressProvince", province, provinceObject);
    let districtObject = provinceObject?.districts.find(obj => obj.value === district);
    handlePermanentAddressDistrictSelectChange("permanentAddressDistrict", district, districtObject);
    handlePermanentAddressPalikaSelectChange("permanentAddressPalikaName", palika);
  }

  function populateTemporaryAddress(province, district, palika) {
    let provinceObject = PROVINCE_DISTRICT_PALIKA_LIST.find(obj => obj.value === province);
    handleTemporaryAddressProvinceSelectChange("temporaryAddressProvince", province, provinceObject);
    let districtObject = provinceObject?.districts.find(obj => obj.value === district);
    handleTemporaryAddressDistrictSelectChange("temporaryAddressDistrict", district, districtObject);
    handleTemporaryAddressPalikaSelectChange("temporaryAddressPalikaName", palika);
  }

  useEffect(() => {
    register({ name: "permanentAddressProvince" }, { required: true });
    register({ name: "permanentAddressDistrict" }, { required: true });
    register({ name: "permanentAddressPalikaName" }, { required: true });
    register({ name: "temporaryAddressProvince" });
    register({ name: "temporaryAddressDistrict" });
    register({ name: "temporaryAddressPalikaName" });
  }, [register])

  const onSubmit = data => {
    let postdata = {
      id: staffId,
      addressDetail: data
    }

    PIS.post(API_URL.staff, postdata)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onAddressDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  function handlePermanentAddressProvinceSelectChange(name, value, option) {
    setClearPermanentAddressPalika(true);
    setClearPermanentAddressDistrict(true);
    setValue(name, value);
    setValue("permanentAddressDistrict", null);
    setValue("permanentAddressPalikaName", null);
    setIsPermanentAddressDistrictDisable(!value);
    setIsPermanentAddressMunDisable(true);
    setPermanentAddressDistrictsOptions(option?.districts || []);
    setPermanentAddressProvinceLabel(value)
  }

  function handlePermanentAddressDistrictSelectChange(name, value, option) {
    setClearPermanentAddressPalika(true);
    setClearPermanentAddressDistrict(false);
    setValue(name, value);
    setValue("permanentAddressPalikaName", null);
    setIsPermanentAddressMunDisable(!value);
    setPermanentAddressPalikaOptions(option?.palikas || [])
    setPermanentAddressDistrictLabel(value)
  }

  function handlePermanentAddressPalikaSelectChange(name, value) {
    setClearPermanentAddressPalika(false);
    setValue(name, value);
    setPermanentAddressPalikaLabel(value)
  }

  function handleTemporaryAddressProvinceSelectChange(name, value, option) {
    setClearTemporaryAddressPalika(true);
    setClearTemporaryAddressDistrict(true);
    setValue(name, value);
    setValue("temporaryAddressDistrict", null);
    setValue("temporaryAddressPalikaName", null);
    setIsTemporaryAddressDistrictDisable(!value);
    setIsTemporaryAddressMunDisable(true);
    setTemporaryAddressDistrictsOptions(option?.districts || []);
    setTemporaryAddressProvinceLabel(value)
  }

  function handleTemporaryAddressDistrictSelectChange(name, value, option) {
    setClearTemporaryAddressPalika(true);
    setClearTemporaryAddressDistrict(false);
    setValue(name, value);
    setValue("temporaryAddressPalikaName", null);
    setIsTemporaryAddressMunDisable(!value);
    setTemporaryAddressPalikaOptions(option?.palikas || []);
    setTemporaryAddressDistrictLabel(value)
  }

  function handleTemporaryAddressPalikaSelectChange(name, value) {
    setClearTemporaryAddressPalika(false);
    setValue(name, value);
    setTemporaryAddressPalikaLabel(value)
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant="h6" className={classes.subTitle}>स्थायी ठेगाना</Typography>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <CustomSelect
                label="कृपया, प्रदेशको नाम चयन गर्नुहोस्।"
                name="permanentAddressProvince"
                defaultValue={permanentAddressProvinceLabel}
                options={PROVINCE_DISTRICT_PALIKA_LIST}
                onChange={handlePermanentAddressProvinceSelectChange}
                creatable
              />
              {errors.permanentAddressProvince && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <CustomSelect
                label="कृपया जिल्लाको नाम चयन गर्नुहोस्।"
                name="permanentAddressDistrict"
                defaultValue={permanentAddressDistrictLabel}
                options={permanentAddressDistrictsOptions}
                onChange={handlePermanentAddressDistrictSelectChange}
                isDisabled={permanentAddressDistrictDisable}
                clearSelect={clearPermanentAddressDistrict}
                creatable
              />
              {errors.permanentAddressDistrict && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <CustomSelect
                label="कृपया, पालिकाको नाम चयन गर्नुहोस्।"
                name="permanentAddressPalikaName"
                defaultValue={permanentAddressPalikaNameLabel}
                options={permanentAddressPalikaOptions}
                onChange={handlePermanentAddressPalikaSelectChange}
                isDisabled={permanentAddressMunDisable}
                clearSelect={clearPermanentAddressPalika}
                creatable
              />
              {errors.permanentAddressPalikaName && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="वडा नं"
                name="permanentAddressWardNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.permanentAddressWardNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                name="permanentAddressGaunOrTole"
                label="गाँउ/टोल"
                variant="outlined"
                inputRef={register({
                  required: true
                })}
                size="small"
                fullWidth
              />
              {errors.permanentAddressGaunOrTole && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="घर नं."
                name="permanentAddressHomeNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="टेलीफोन नं :"
                name="permanentAddressPhoneNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="अन्य संपर्क विवरण"
                name="permanentAddressOthersContactDetail"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.subTitle}>अस्थायी ठेगाना</Typography>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <CustomSelect
                label="कृपया, प्रदेशको नाम चयन गर्नुहोस्।"
                name="temporaryAddressProvince"
                defaultValue={temporaryAddressProvinceLabel}
                options={PROVINCE_DISTRICT_PALIKA_LIST}
                onChange={handleTemporaryAddressProvinceSelectChange}
                creatable
              />
            </Grid>
            <Grid item xs>
              <CustomSelect
                label="कृपया जिल्लाको नाम चयन गर्नुहोस्।"
                name="temporaryAddressDistrict"
                defaultValue={temporaryAddressDistrictLabel}
                options={temporaryAddressDistrictsOptions}
                onChange={handleTemporaryAddressDistrictSelectChange}
                isDisabled={temporaryAddressDistrictDisable}
                clearSelect={clearTemporaryAddressDistrict}
                creatable
              />
            </Grid>
            <Grid item xs>
              <CustomSelect
                label="कृपया, पालिकाको नाम चयन गर्नुहोस्।"
                name="temporaryAddressPalikaName"
                defaultValue={temporaryAddressPalikaNameLabel}
                options={temporaryAddressPalikaOptions}
                onChange={handleTemporaryAddressPalikaSelectChange}
                isDisabled={temporaryAddressMunDisable}
                clearSelect={clearTemporaryAddressPalika}
                creatable
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="वडा नं"
                name="temporaryAddressWardNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                name="temporaryAddressGaunOrTole"
                label="गाँउ/टोल"
                variant="outlined"
                inputRef={register}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="घर नं."
                name="temporaryAddressHomeNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="टेलीफोन नं :"
                name="temporaryAddressPhoneNumber"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="अन्य संपर्क विवरण"
                name="temporaryAddressOthersContactDetail"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !props.addressDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {props.addressDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
