import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Select from "react-select";
import CustomSelect from '../../../../components/custom-select/CustomSelect';
import CustomModal from '../../../../components/modal/CustomModal';
import { ENTER_VALID_EMAIL, PHONE_NUMBER_MUST_NOT_BE_GREATER_THAN_TEN, PROVINCE_DISTRICT_PALIKA_LIST, REQUIRED_FIELD } from '../../../../utils/constants';
import { OFFICE_TYPES } from '../../../../utils/constants/forms';
import styles from "./style";

export default function AddOffice({ showAddOfficeModal, handleAddOfficeModalClose, onSubmit, ...props }) {
  const classes = styles();
  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const [provinceLabel, setProvinceLabel] = useState();
  const [districtLabel, setDistrictLabel] = useState();
  const [districtOptions, setDistrictOptions] = useState();
  const [provinceSelected, setProvinceSelected] = useState(false);
  const [palikaOptions, setPalikaOptions] = useState();
  const [palikaNameLabel, setPalikaNameLabel] = useState();
  const [districtSelected, setDistrictSelected] = useState(false);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [selectedPalikaName, setSelectedPalikaName] = useState();

  useEffect(() => {
    !showAddOfficeModal && reset({});
  }, [showAddOfficeModal, reset])

  useEffect(() => {
    register({ name: "type" }, { required: true })
    register({ name: "province" }, { required: true })
    register({ name: "district" }, { required: true })
    register({ name: "palikaName" }, { required: true })
  }, [register])

  useEffect(() => {
    if (JSON.stringify(props.defaultValues !== "{}")) {
      handleProvinceChange(PROVINCE_DISTRICT_PALIKA_LIST.find(option => option.value === props.defaultValues.province));
      setSelectedDistrictName(props.defaultValues.district);
      setSelectedPalikaName(props.defaultValues.palikaName);
    }
  }, [props.defaultValues]);

  useEffect(() => {
    selectedDistrictName && districtOptions && handleDistrictChange(districtOptions.find(option => option.value === selectedDistrictName));
  }, [selectedDistrictName, districtOptions]);

  useEffect(() => {
    selectedPalikaName && palikaOptions && handlePalikaNameChange(palikaOptions.find(option => option.value === selectedPalikaName));
  }, [selectedPalikaName, palikaOptions]);

  const handleProvinceChange = provinceOption => {
    provinceOption ? setProvinceSelected(true) : setProvinceSelected(false);
    setValue("province", provinceOption ? provinceOption.value : null);
    setValue("district", null);
    setValue("palikaName", null);
    setProvinceLabel(provinceOption ? provinceOption : null);
    setDistrictLabel(null);
    setPalikaNameLabel(null);
    provinceOption &&
      setDistrictOptions(provinceOption.districts);
  }

  const handleDistrictChange = (districtOption) => {
    districtOption ? setDistrictSelected(true) : setDistrictSelected(false);
    setValue("palikaName", null);
    setValue("district", districtOption ? districtOption.value : null);
    setPalikaNameLabel(null);
    setDistrictLabel(districtOption ? districtOption : "");
    districtOption &&
      setPalikaOptions(districtOption.palikas);
  }

  const handlePalikaNameChange = palikaOption => {
    setValue("palikaName", palikaOption ? palikaOption.value : null);
    setPalikaNameLabel(palikaOption ? palikaOption : "");
  }

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleModalClose = () => {
    reset();
    handleAddOfficeModalClose();
    setProvinceLabel(null);
    setDistrictLabel(null);
    setPalikaNameLabel(null);
  }

  return (
    <CustomModal
      title="कर्मचारी सूचना व्यवस्थापन प्रणालीमा नयाँ कार्यालय थप्नुहोस।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddOfficeModal}
      onModalClose={handleModalClose}
      maxWidth="lg"
    >
      <Grid container spacing={2} alignItems="center" className={classes.row}>
        <Grid item xs>
          <TextField
            label="कार्यालयको नाम"
            name="name"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.name}
            inputRef={register({
              required: true
            })}
            fullWidth
          />
          {errors.name && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <CustomSelect
            label="कार्यालयको किसिम"
            name="type"
            options={OFFICE_TYPES}
            defaultValue={props.defaultValues.type}
            onChange={handleCustomSelectChange}
          />
          {errors.type && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <TextField
            label="सम्पर्क नम्बर"
            name="phoneNumber"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.phoneNumber}
            inputRef={register({
              validate: value => value.trim().length <= 10
            })}
            fullWidth
          />
          {errors.phoneNumber && <span className="error-message">{PHONE_NUMBER_MUST_NOT_BE_GREATER_THAN_TEN}</span>}
        </Grid>
        <Grid item xs>
          <TextField
            label="ईमेल"
            name="email"
            type="email"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.email}
            inputRef={register({
              pattern: /\S+@\S+\.\S+/
            })}
            fullWidth
          />
          {errors.email?.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.row}>
        <Grid item xs>
          <Select
            className="select-sm"
            classNamePrefix="react-select"
            placeholder="कृपया, प्रदेशको नाम चयन गर्नुहोस्।"
            name="province"
            size="small"
            variant="outlined"
            options={PROVINCE_DISTRICT_PALIKA_LIST}
            value={provinceLabel}
            onChange={handleProvinceChange}
            isClearable
          />
          {errors.province && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <Select
            className="select-sm"
            classNamePrefix="react-select"
            placeholder="कृपया जिल्लाको नाम चयन गर्नुहोस्।"
            name="district"
            size="small"
            variant="outlined"
            options={districtOptions}
            value={districtLabel}
            onChange={handleDistrictChange}
            isDisabled={!provinceSelected}
            isClearable
          />
          {errors.district && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <Select
            className="select-sm"
            classNamePrefix="react-select"
            placeholder="कृपया, पालिकाको नाम चयन गर्नुहोस्।"
            name="palikaName"
            size="small"
            variant="outlined"
            options={palikaOptions}
            value={palikaNameLabel}
            onChange={handlePalikaNameChange}
            isDisabled={!districtSelected || !provinceSelected}
            isClearable
          />
          {errors.palikaName && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <TextField
            label="वडा नं."
            name="wardNumber"
            type="number"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.wardNumber}
            inputRef={register({
              required: true
            })}
            fullWidth
          />
          {errors.wardNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.row}>
        <Grid item xs>
          <TextField
            label="ठेगाना"
            name="address"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.address}
            fullWidth
            inputRef={register}
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="वेबसाइट"
            name="website"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.website}
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="फ्याक्स नम्बर"
            name="faxNumber"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.faxNumber}
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <FormControlLabel
            control={<Checkbox color="primary" defaultChecked={props.defaultValues && props.defaultValues.allowStaffLogin} />}
            label="कर्मचारी सम्पादन सक्षम गर्नुहोस्"
            name="allowStaffLogin"
            inputRef={register}
          />
        </Grid>
      </Grid>
    </CustomModal>
  )
}
