import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import CustomSelect from '../../../components/custom-select/CustomSelect';
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import StaffLeaveReport from './helpers/StaffLeaveReport';
import styles from "./style";

const LeaveReport = () => {
  const classes = styles();
  const [staffLeaveReportDetails, setStaffLeaveReportDetails] = useState({});
  const [staffOptions, setStaffOptions] = useState([]);

  const handleSelectChange = (name, id) => {
    let url = API_URL.leaveReport + `?staffId=${id}`;
    PIS.get(url).then((response) => {
      if (response.data.type === SUCCESS) {
        setStaffLeaveReportDetails(response.data.data);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const getStaffsList = async () => {
    PIS.get(API_URL.staffBasicInfo).then(response => {
      if (response) {
        let jsondata = response.data;
        let staffList = [];
        for (let i = 0; i < jsondata.length; i++) {
          staffList.push({ label: jsondata[i].fullName, value: jsondata[i].id })
        }
        setStaffOptions(staffList);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  useEffect(() => {
    getStaffsList();
  }, [])

  return (
    <Paper className={classes.paper}>
      <Box className={classes.paperHeader}>
        <Typography variant="h6">बिदा विवरणको रिपोर्ट</Typography>
        <Grid item xs={3}>
          <CustomSelect
            isClearable={false}
            label="कर्मचारीको नाम चयन गर्नुहोस्"
            name="staffId"
            options={staffOptions}
            onChange={handleSelectChange}
          />
        </Grid>
      </Box>
      <StaffLeaveReport staffLeaveReportDetails={staffLeaveReportDetails} />
    </Paper>
  )
}

export default LeaveReport;
