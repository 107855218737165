import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  emptyMsg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  publicRepresentativeCard:{
    width: "40%",
    marginRight: theme.spacing(2),
  },
  employeCard:{
    width: "60%",
  },
  cardImage:{
    height: "115px",
    width: "119px",
    marginBottom: "10px",
    objectFit: "cover",
    borderRadius: "4px"
  },
  cardHeading:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-root": {
      lineHeight: 1.2
    },
  },
  card:{
    height: theme.spacing(36)
  },
  details:{
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  subTitle: {
    borderBottom: "1px solid " + theme.palette.divider,
    paddingBottom: theme.spacing(.5),
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      color: theme.palette.text.primary
    }
  },
}));
export default styles;
