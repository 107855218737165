import { Avatar, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomTable from "../../../components/custom-table/CustomTable";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { AppMisc } from "../../../misc/appMisc";
import { AppUtils } from "../../../utils/appUtils";
import { ERROR, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";

export default function LandingPagePublicRepresentativeList() {
  const [publicRepresentativeData, setPublicRepresentativeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const palikaName = AppUtils.getUrlParam('mun');

  const getPublicRepresentativeList = () => {
    setIsLoading(true);
    PIS.get(`${API_URL.public}?office=${palikaName}&method=PEOPLE_REPRESENTATIVE_LIST`).then(response => {
      if (response.data.type === SUCCESS) {
        setPublicRepresentativeData(response.data.data);
        setIsLoading(false);
      }
      else {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        setIsLoading(false);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getPublicRepresentativeList();
  }, []);

  function buildColumns() {
    let columns = [
      {
        Header: "क्र. सं.",
        Cell: (row) => <p>{AppUtils.replaceWithNepaliDigit(row.row.index + 1)}</p>,
      },
      {
        Header: "फोटो",
        accessor: "photo",
        Cell: (row) => (
          <Box display="flex" justifyContent="center">
            <Avatar alt="Staff Photo" src={row.value} />
          </Box>
        ),
      },
      {
        Header: "नाम",
        accessor: "name",
      },
      {
        Header: "पद",
        accessor: "post",
      },
      {
        Header: "भूतपूर्व / कार्यारत",
        accessor: "publicRepresentativeLabel",
        className: "print-none",
      },
      {
        Header: "लिङ्ग",
        accessor: (data) => AppMisc.getLabelFromValue(data.gender, GENDER_OPTIONS)
      },
      {
        Header: "उमेर",
        accessor: "age",
      },
      {
        Header: "राजनीतिक दल/स्वतन्त्र",
        accessor: "politicalPartyOrIndependent",
      },
      {
        Header: "ईमेल",
        accessor: "email",
      },
      {
        Header: "वडा",
        accessor: "wardNumber",
      },
      {
        Header: "फोन नं.",
        accessor: "phone",
      },
    ];
    return columns;
  }

  const columns = buildColumns();

  return (
    <>
      <PublicHeader />
      <Box mt={8}>
        <CustomTable isLoading={isLoading} columns={columns} data={publicRepresentativeData} />
      </Box>
    </>
  );
}

