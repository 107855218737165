import { Box, IconButton, Paper, Typography } from "@material-ui/core"
import { Print } from "@material-ui/icons"
import React, { useEffect, useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import PIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import EmptyContainer from "../../../components/empty-container/EmptyContainer"
import Spinner from "../../../components/loader/Loader"
import { AppUtils } from "../../../utils/appUtils"
import { ERROR, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import PublicRepresentativeCard from "../components/public-representative-card/PublicRepresentativeCard"
import styles from "./style";

export default function PublicRepresentativeDetails() {
  const classes = styles();
  const printCardRef = useRef();
  const [publicRepresentativeDataLoading, setPublicRepresentativeDataLoading] = useState(false);
  const [publicRepresentativeInfo, setPublicRepresentativeInfo] = useState({})
  const id = AppUtils.getUrlParam(ID);

  const getPublicRepresentativeDetail = () => {
    setPublicRepresentativeDataLoading(true)
    PIS.get(API_URL.publicRepresentative + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setPublicRepresentativeInfo(jsondata.data);
        setPublicRepresentativeDataLoading(false)
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  };

  useEffect(()=>{
    getPublicRepresentativeDetail();
  },[])

  return (
    <>
      <Paper className={`${classes.paper} print-none`} >
        <Box className={classes.paperHeader}>
          <Typography variant="h6">जन प्रतिनिधि परिचयपत्र</Typography>
          <Box className={classes.paperActionButtons}>
            <>
              <ReactToPrint
                trigger={
                  () => <IconButton aria-label="print" className="print-none">
                    <Print />
                  </IconButton>
                }
                content={() => printCardRef.current}
              />
            </>
          </Box>
        </Box>
        <Box className={classes.cardContainer} >
          <Box ref={printCardRef}>
            {
              publicRepresentativeDataLoading ?
                (
                  <EmptyContainer>
                    <Spinner />
                  </EmptyContainer>
                ) : (
                 <PublicRepresentativeCard PublicRepresentativeInfo={publicRepresentativeInfo} />
            )
            }
          </Box>
        </Box>
      </Paper>
    </>
  )
}