import PIS, { API_URL } from "../api/api";
import AddAlertMessage from "../components/alert/Alert";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../utils/constants";
import { SAVE_SETTING_DATA } from "../utils/constants/redux";

export const getSettingData = () => async (dispatch) => {
  try {
    let response = await PIS.get(API_URL.setting);
    let jsondata = response.data;
    if (jsondata.type === SUCCESS) {
      dispatch({ type: SAVE_SETTING_DATA, payload: jsondata.data })
    } else {
      AddAlertMessage({ type: jsondata.type, message: jsondata.message })
    }
  } catch (error) {
    AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
  }
}
