import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form';
import CustomModal from '../../../../components/modal/CustomModal'
import { REQUIRED_FIELD } from '../../../../utils/constants';

export default function AddEducationalQualificationModel({ modelDefaultValues, submitEducationalDetails, handleModalClose, openAddEducationalQualificationModel }) {
  const { register, errors, handleSubmit } = useForm();
  return (
    <CustomModal
      title="शैक्षिक उपाधि थप्नुहोस।"
      onModalSubmit={handleSubmit(submitEducationalDetails)}
      showModal={openAddEducationalQualificationModel}
      onModalClose={handleModalClose}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <TextField
            label="शैक्षिक उपाधि"
            name="name"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={modelDefaultValues.name}
            inputRef={register({
              required: true
            })}
            fullWidth
          />
          {errors.name && (<span className="error-message">{REQUIRED_FIELD}</span>)}
        </Grid>
      </Grid>
    </CustomModal>
  )
}
