import { Box, Button, Paper, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { ERROR, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";

export default function SearchException() {
  const { handleSubmit, register, errors } = useForm();
  const [stackTrace, setStackTrace] = useState(null);

  const onSubmit = data => {
    PIS.get(API_URL.businessException + "?exceptionId=" + data.exceptionId).then(response => {
      if (response.data.type === SUCCESS) {
        setStackTrace(response.data.data);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    })
  }

  return (<>
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <TextField
            type="number"
            label="Business Exception ID"
            name="exceptionId"
            size="small"
            variant="outlined"
            fullWidth
            inputRef={register({
              required: true
            })}
          />
          <Button startIcon={<Search />} type="submit" color="primary" variant="contained">
            खोज्नुहोस्
          </Button>
        </Box>
        {errors.exceptionId && <span className="error-message">{REQUIRED_FIELD}</span>}
      </form>
    </Paper>
    {
      stackTrace && (
        <Paper>
          <Box mt={4}>
            {stackTrace}
          </Box>
        </Paper>)
    }
  </>)
}