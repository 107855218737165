import { Box, Grid, TextField } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import React from 'react';
import CustomSelect from '../../../../components/custom-select/CustomSelect';
import { ROW_SIZE } from '../../../../utils/constants';
import styles from "./styles";

const SearchAndPagination = (Component) => (props) => {
    const classes = styles();
    const pages = Math.ceil(props.size / props.rowSize);

    const delaySearch = delaySearchCallback(val => { props.functionSearchParam(val) }, 1000);

    const handlePagination = (e, currentPage) => {
        props.functionPagination(currentPage);
    }

    const handleSearchChange = e => {
        delaySearch(e.target.value)
    }

    const handleRowSizeChange = (name, value) => {
        props.functionRowSize(value);
    }

    function delaySearchCallback(fun, delay) {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fun(...args);
            }, delay);
        };
    }

    return (
        <div>
            {!props.search && <Box display="flex" className="print-none" justifyContent="flex-end" mb={2}>
                <TextField
                    label="Search"
                    name="searchText"
                    type="text"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleSearchChange}
                />
            </Box>}
            <Component getTableData={props.getTableData} isLoading={props.isLoading} editRow={props.editRow} tableData={props.tableData} showActionColumn={props.showActionColumn} pageSize={props.pageSize} rowSize={props.rowSize} />
            {!props.search && <Grid container spacing={2} alignItems="center" className={`${classes.pagination} print-none`}>
                <Grid item xs={11} >
                    <Pagination onChange={handlePagination} count={pages} variant="outlined" shape="rounded" color="primary" />
                </Grid>
                <Grid item xs={1} className={classes.customSelect}>
                    <CustomSelect
                        label=""
                        defaultValue={50}
                        options={ROW_SIZE}
                        onChange={handleRowSizeChange}
                        isClearable={false}
                    />
                </Grid>
            </Grid>}
        </div>
    )
}

export default SearchAndPagination;
