import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  mainHeading: {
    marginTop: 0,
    paddingBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: "1px solid " + theme.palette.grey[500],
  },
  actionBtn: {
    padding: theme.spacing(0.5),
  },
  pagination: {
    display: "flex",
    justifyContent:"center",
    padding: theme.spacing(2),
  },
  printOnlyBlock: {
    display: "none",
    "@media print": {
      display: "block",
    },
  },
}));
export default styles;
