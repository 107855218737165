import { AppUtils } from "../utils/appUtils";
import { OFFICE_TYPES } from "../utils/constants/forms";
import { BHEEMDATTA_PIS, PALIKA_TYPES, PROVINCE_DISTRICT_PALIKA_LIST, USER_ROLES, KAILARI_PIS, API_WITHOUT_LOADER, ROLE_STAFF } from "../utils/constants/index";
import { DateUtils } from "../utils/dateUtils";

export const AppMisc = {
  getProvinceName(provinceValue) {
    return PROVINCE_DISTRICT_PALIKA_LIST.find(obj => obj.value === provinceValue)?.label || provinceValue;
  },

  getLabelFromValue(value, options) {
    return options.find(obj => obj.value === value)?.label || value;
  },

  getDistrictName(districtValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].value === districtValue) {
          return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].label;
        }
      }
    }
    return districtValue;
  },

  // TODO: sandeep - refactor the function to minimize loops.
  getMunicipalityName(municipalityValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value === municipalityValue) {
            return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label;
          }
        }
      }
    }
    return municipalityValue;
  },

  getMunicipalityValueFromLabel(municipalityName) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label === municipalityName) {
            return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value;
          }
        }
      }
    }
  },

  getPalikaTypeName(palikaTypeValue) {
    return palikaTypeValue ? PALIKA_TYPES.find(obj => obj.value === palikaTypeValue).label : "";
  },

  getMunicipalityInfo(municipalityValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value === municipalityValue) {
            return {
              municipalityName: PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label,
              District: PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].label,
              Province: PROVINCE_DISTRICT_PALIKA_LIST[i].label,
            }
          }
        }
      }
    }
  },

  getUserRole(userRoleValue) {
    return userRoleValue ? USER_ROLES.find(obj => obj.value === userRoleValue).label : "";
  },
  isRoleStaff() {
    return (AppUtils.getUserRole() !== ROLE_STAFF);
  },
  getDistrictOptions() {
    let districts = [];
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        districts.push(PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j]);
      }
    }
    return districts;
  },

  getOfficeType(officeType) {
    return officeType ? OFFICE_TYPES.find(obj => obj.value === officeType).label : "";
  },
  getPalikaNameFromHost() {
    switch (AppUtils.getPathName()) {
      case BHEEMDATTA_PIS:
        return "KANCHANPUR_BHEEMDATTA_MUN";
      case KAILARI_PIS:
        return "KAILALI_KAILARI_MUN";
      default: {
        return null;
      }
    }
  },
  searchByStaffName(fullName, searchText) {
    return Boolean(fullName.toLowerCase().includes(searchText.toLowerCase()));
  },
  getDateLabelFromMillisecond(dateInMilli) {
    return dateInMilli ? AppUtils.replaceWithNepaliDigit(DateUtils.getDateFromMilliseconds(Number(dateInMilli))) : "-"
  },
  hideFullBodyLoader(url, method) {
    if (method !== "get") return false;
    for (let api of API_WITHOUT_LOADER) {
      if (url.includes(api)) return true
    }
  }
};


