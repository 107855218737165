import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { AppUtils } from "../../../../utils/appUtils";
import { ID, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import styles from "./style";

export default function AddFamilyDetails({ onFamilyDetailsUpdate, ...props }) {
  const classes = styles();
  const { register, handleSubmit, errors, reset } = useForm();
  const staffId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    props.familyDetails && reset(props.familyDetails);
  }, [reset, props.familyDetails]);

  const onSubmit = data => {
    let postdata = {
      id: staffId,
      familyInfo: data
    }

    PIS.post(API_URL.staff, postdata)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onFamilyDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="Husband Or Wife name in english"
                name="husbandOrWifeNameInEnglish"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="पति/पत्नीको नाम नेपालीमा"
                name="husbandOrWifeNameInNepali"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="पेशा"
                name="husbandOrWifeOccupation"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="Grandfather name in english"
                name="grandfatherNameInEnglish"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.grandfatherNameInEnglish && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="हजुरबुवाको नाम नेपालीमा"
                name="grandfatherNameInNepali"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.grandfatherNameInNepali && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="पेशा"
                name="grandfatherOccupation"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="Father name in english"
                name="fatherNameInEnglish"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.fatherNameInEnglish && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="पिताको नाम नेपालीमा"
                name="fatherNameInNepali"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.fatherNameInNepali && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="पेशा"
                name="fatherOccupation"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="Mother name in english"
                name="motherNameInEnglish"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.motherNameInEnglish && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="आमाको नाम नेपालीमा"
                name="motherNameInNepali"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.motherNameInNepali && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="पेशा"
                name="motherOccupation"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <TextField
                label="छोरी संख्या"
                name="daughterCount"
                type="number"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="छोरा संख्या"
                name="sonCount"
                type="number"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !props.familyDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {props.familyDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
