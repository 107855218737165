import { Box, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react';
import CustomTable from '../../../../components/custom-table/CustomTable';

export default function EducationalQualificationList({ educationalQualificationList, onEditRow, onDeleteRow, showActionColumn }) {
  function buildColumns() {
    let columns = [
      {
        Header: "शैक्षिक उपाधि",
        accessor: "name",
      },
    ]
    if (showActionColumn) {
      columns.push({
        Header: "सम्पादन",
        accessor: "id",
        className: 'print-none',
        Cell: (row) => (
          <Box display="flex" justifyContent="space-around">
            <IconButton onClick={() => onEditRow(row.value)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onDeleteRow(row.value)} aria-label="delete">
              <Delete fontSize="small" color="secondary" />
            </IconButton>
          </Box>
        )
      })
    }
    return columns;
  }

  const columns = buildColumns();

  return (
    <CustomTable columns={columns} data={educationalQualificationList} />
  )
}
