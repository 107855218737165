import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import CustomTable from "../../../../components/custom-table/CustomTable";

export default function DepartmentsList({ tableData, onEditRow, onDeleteRow, ...props }) {

  function buildColumns() {
    let columns = [
      {
        Header: "विभाग／शाखा को नाम",
        accessor: "name",
      },
      {
        Header: "विभाग／शाखा कोड",
        accessor: "prefix",
        Cell: (row) => (
          <p>{row.value || "-"}</p>
        )
      },
    ]
    if (props.showActionColumn) {
      columns.push({
        Header: "सम्पादन",
        accessor: "id",
        className: 'print-none',
        Cell: (row) => (
          <>
            <IconButton onClick={() => onEditRow(row.value)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onDeleteRow(row.value)} aria-label="delete">
              <Delete fontSize="small" color="secondary" />
            </IconButton>
          </>
        )
      })
    }
    return columns;
  }

  const columns = buildColumns();

  return (
    <CustomTable columns={columns} data={tableData} />
  );
}