import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(1),
    position: "relative",
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  btnContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderTop: "1px solid " + theme.palette.divider,
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  },
  awardAndTestimonialDetailsContainer: {
    paddingTop: theme.spacing(2),
    position: "relative"
  },
  removeAwardAndTestimonialDetailsContainer: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    color: theme.palette.secondary.main,
  },
  addAwardAndTestimonialDetailsBtnContainer: {
    paddingTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2)
  }
}));
export default styles;