import { Box, Button, Typography } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddUserModal from "../../../components/add-user/AddUserModal";
import AddAlertMessage from "../../../components/alert/Alert";
import UserList from "../../../components/user-list/UserList";
import { ADD_USER_ALLOWED_ROLES_FOR_OFFICE_ADMIN, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import styles from "./style";

export default function UserManagement() {
  const classes = styles();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [userTableData, setUserTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});

  useEffect(() => {
    getOfficeUsers();
  }, []);

  const getOfficeUsers = () => {
    PIS.get(API_URL.officeUsers).then(response => {
      setUserTableData(response.data.data);
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    });
  };

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
    setModalDefaultValues({});
  };

  const submitAddUserModal = data => {
    if (modalDefaultValues.id) {
      data.id = modalDefaultValues.id;
    }
    PIS.post(API_URL.user, data)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === "success") {
          handleAddUserModalClose();
          getOfficeUsers();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const editUserDetails = id => {
    PIS.get(API_URL.user + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data["data"]);
        setOpenAddUserModal(true);
      }).catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      })
  };

  return (
    <>
      <Box className={classes.setting}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
          <Typography variant="h5">प्रयोगकर्ताहरू</Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
            setOpenAddUserModal(true);
          }}>नयाँ प्रयोगकर्ता थप गर्नुहोस्</Button>
        </Box>
        <UserList tableData={userTableData} showActionColumn={userTableData.length !== 0} onEditRow={editUserDetails} />
      </Box>
      <AddUserModal modalDefaultValues={modalDefaultValues} userRoles={ADD_USER_ALLOWED_ROLES_FOR_OFFICE_ADMIN} showAddUserModal={openAddUserModal} handleAddUserModalClose={handleAddUserModalClose} onSubmit={submitAddUserModal.bind(this)} />
    </>
  );
}
