import { Avatar, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomTable from "../../../components/custom-table/CustomTable";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { AppMisc } from "../../../misc/appMisc";
import { AppUtils } from "../../../utils/appUtils";
import { ERROR, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";

export default function LandingPageStaffList() {
  const [staffData, setStaffData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const palikaName = AppUtils.getUrlParam('mun');

  const getStaffs = () => {
    setIsLoading(true);
    PIS.get(`${API_URL.public}?office=${palikaName}&method=STAFF_LIST`).then(response => {
      if (response.data.type === SUCCESS) {
        setStaffData(response.data.data);
        setIsLoading(false);
      }
      else {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      setIsLoading(false)
    });
  }

  useEffect(() => {
    getStaffs();
  }, []);

  function buildColumns() {
    let columns = [
      {
        Header: "फोटो",
        accessor: "photo",
        Cell: (row) => (
          <Box display="flex" justifyContent="center">
            <Avatar alt="Staff Photo" src={row.value} />
          </Box>
        ),
      },
      {
        Header: "नाम",
        accessor: "name",
      },
      {
        Header: "लिङ्ग",
        accessor: (data) => AppMisc.getLabelFromValue(data.gender, GENDER_OPTIONS)
      },
      {
        Header: "पद",
        accessor: "post",
      },
      {
        Header: "शाखा",
        accessor: "departmentName",
      },
      {
        Header: "कर्मचारी संकेत नं.",
        accessor: "staffCodeNumber",
      },
      {
        Header: "फोन नं.",
        accessor: "phone",
      }
    ];
    return columns;
  }

  const columns = buildColumns();

  return (
    <>
      <PublicHeader />
      <Box mt={8}>
        <CustomTable isLoading={isLoading} columns={columns} data={staffData} />
      </Box>
    </>
  );
}

