import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import { AppUtils } from "../../../../../utils/appUtils";
import { NO_RECORDS_FOUND } from "../../../../../utils/constants";

export default function EducationalDetails({ educationalDetails, educationalQualificationList }) {
  return (
    <Box p={2}>
      <TableContainer>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Educational Details">
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>क्र.स</TableCell>
              <TableCell>शैक्षिक योग्यता वा उपाधि</TableCell>
              <TableCell>अध्ययनको विषय वा संकाय</TableCell>
              <TableCell>उतिर्ण गरेको साल</TableCell>
              <TableCell>प्राप्त श्रेणी</TableCell>
              <TableCell>शिक्षण संस्था /परिषद /विश्वविद्यालयको नाम र देश</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {educationalDetails.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (
              educationalDetails.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                  <TableCell>{AppMisc.getLabelFromValue(row.educationalQualificationOrDegree, educationalQualificationList)}</TableCell>
                  <TableCell>{row.studySubjectOrFaculty}</TableCell>
                  <TableCell>{row.yearPassed}</TableCell>
                  <TableCell>{row.recievedGradingCategory}</TableCell>
                  <TableCell>{row.educationalInstitutionNameAndCountry}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
