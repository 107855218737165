import { Box, Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomModal from "../../../components/modal/CustomModal";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddPostModal from "./helpers/AddPostModal";
import PostList from "./helpers/PostList";
import styles from "./style";

export default function Post() {
  const classes = styles();
  const [openAddPostModal, setOpenAddPostModal] = useState(false);
  const [postTableData, setPostTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [showDeletePostModal, setShowDeletePostModal] = useState(false);
  const [tableRowId, setTableRowId] = useState(null);

  useEffect(() => {
    getPostData();
  }, []);

  const handleAddPostModelClose = () => {
    setModalDefaultValues({});
    setOpenAddPostModal(false);
  }

  const handleDeleteConfirmationModelClose = () => {
    setShowDeletePostModal(false)
    setTableRowId(null)
  }

  const getPostData = () => {
    PIS.get(API_URL.post)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPostTableData(jsondata.data);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleAddPostModalSubmit = data => {
    data.id = modalDefaultValues.id;
    PIS.post(API_URL.post, data)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          getPostData();
          handleAddPostModelClose();
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleEditSubDepartment = id => {
    PIS.get(`${API_URL.post}/${id}`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setModalDefaultValues(jsondata.data);
          setOpenAddPostModal(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handlePostDelete = (id) => {
    setShowDeletePostModal(true);
    setTableRowId(id);
  }

  const deleteTableRow = () => {
    PIS.delete(`${API_URL.post}/${tableRowId}`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          getPostData();
          handleDeleteConfirmationModelClose()
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.subPostHeading}>
          <Typography variant="h6">पदहरु</Typography>
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddPostModal(true) }}>नयाँ पद थप्नुहोस्</Button>
          </Box>
        </Box>
        <Box textAlign="center">
          <CustomModal
            title="पद हटाउनुहोस्।"
            onModalSubmit={deleteTableRow}
            showModal={showDeletePostModal}
            onModalClose={handleDeleteConfirmationModelClose}
            submitButtonText="हटाउनुहोस्"
          >
            <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ यस पद हटाउन चाहनुहुन्छ?</Typography>
            <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
          </CustomModal>
        </Box>
        <Box>
          <PostList tableData={postTableData} onEditRow={handleEditSubDepartment} onDeleteRow={handlePostDelete} showActionColumn={postTableData.length !== 0} />
        </Box>
        <AddPostModal handleModalSubmit={handleAddPostModalSubmit} showAddPostModal={openAddPostModal} handleAddPostModalClose={handleAddPostModelClose} defaultValues={modalDefaultValues} />
      </Box>
    </>
  );
}