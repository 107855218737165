import { Avatar, Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettingData } from "../../../../../action/settingData";
import logo from "../../../../../assets/img/nepal-government.png";
import { AppMisc } from "../../../../../misc/appMisc";
import { AppUtils } from "../../../../../utils/appUtils";
import { PUBLIC_REPRESENTATIVE_CARD_VALIDATION_URL, USER_INFO } from "../../../../../utils/constants";
import { PALIKA } from "../../../../../utils/constants/forms";
import { SessionStorage } from "../../../../../utils/storage/sessionStorage";
import styles from "../style";
import QrCode from "./QrCode";

export default function Card({ publicRepresentativeInfo, ...props }) {
  const userInfo = SessionStorage.getItem(USER_INFO);
  const officeName = userInfo.officeType === PALIKA ? AppMisc.getMunicipalityName(userInfo.officeName) : userInfo.officeName;
  const dispatch = useDispatch();
  const defaultValues = useSelector(state => state.settingData);

  useEffect(() => {
    !defaultValues && dispatch(getSettingData())
  }, [defaultValues])

  const classes = styles();
  return (
    <Box className={classes.cardWrapper}>
      <Box className={classes.card}>
        <Box className={classes.cardHead}>
          <Box className={classes.logo}>
            <img className={classes.officeLogo} src={logo} alt="government logo" />
          </Box>
          <Box className={classes.officeName}>
            {userInfo.officeName &&
              <>
                <Typography variant="body1">{officeName}</Typography>
                {
                  userInfo.officeType === PALIKA &&
                  <Typography variant="body1">{officeName.split(" ").pop() === "गाउँपालिका" ? "गाउँ कार्यपालिकाको कार्यालय" : "नगर कार्यपालिकाको कार्यालय"}</Typography>
                }
                <Typography>{AppMisc.getProvinceName(userInfo.province)}, {AppMisc.getDistrictName(userInfo.district)}, नेपाल</Typography>
              </>
            }
          </Box>
          <Box className={classes.logo}>
            {defaultValues?.logoUrl &&
              <img className={classes.officeLogo} src={defaultValues?.logoUrl.absolutePath} alt="logo" />
            }
          </Box>
        </Box>
        <Box className={classes.cardBody}>
          <Box className={classes.cardContent}>
            <Box className={classes.cardImage}>
              <Box>
                <Avatar variant="rounded" alt={publicRepresentativeInfo.photo?.name} src={publicRepresentativeInfo?.photo?.absolutePath} className={classes.image} />
              </Box>
              {defaultValues?.signatureUrl &&
                <img className={classes.signature} src={defaultValues?.signatureUrl.absolutePath} alt="signature" />
              }
              {defaultValues?.stampUrl &&
                <img className={classes.stamp} src={defaultValues?.stampUrl.absolutePath} alt="signature" />
              }
            </Box>
            <Box className={classes.cardDetails}>
              <Typography>नाम: {publicRepresentativeInfo.fullName}</Typography>
              <Typography>पद: {publicRepresentativeInfo.post}</Typography>
              <Typography>फोन नम्बर: {publicRepresentativeInfo.phoneNumber ? AppUtils.replaceWithNepaliDigit(publicRepresentativeInfo.phoneNumber) : "-"}</Typography>
              <Typography>इमेल: {publicRepresentativeInfo.email || "-"} </Typography>
              <Typography>ठेगाना: {publicRepresentativeInfo.address || "-"}</Typography>
            </Box>
            <Box className={classes.cardQr}>
              <Box className={classes.qr}>
                <QrCode
                  value={`${PUBLIC_REPRESENTATIVE_CARD_VALIDATION_URL}?id=${publicRepresentativeInfo.id}`}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}