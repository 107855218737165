import { Avatar, Box, Card, CardContent, Link, Container, Grid, Paper, Typography } from "@material-ui/core";
import { Accessibility, Group, PeopleAlt, PeopleOutline, SupervisedUserCircle } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import CountUp from 'react-countup';
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import BarDiagram from "../../../components/charts/BarDiagram";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import LineChart from "../../../components/charts/LineChart";
import PieChart from "../../../components/charts/PieChart";
import EmptyContainer from "../../../components/empty-container/EmptyContainer";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { AppMisc } from "../../../misc/appMisc";
import { AppUtils } from "../../../utils/appUtils";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import styles from "./style";

export default function LandingPage(props) {
  const classes = styles();
  const [genderReport, setGenderReport] = useState({});
  const [peopleRepresentativeCount, setPeopleRepresentativeCount] = useState(0);
  const [wardLabels, setWardLabels] = useState([]);
  const [wardWiseEmployeeCount, setWardWiseEmployeeCount] = useState([]);
  const [ageGroupWiseReport, setAgeGroupWiseReport] = useState([]);
  const [employeeTypeReport, setEmployeeTypeReport] = useState([]);
  const [wardWiseGenderReport, setWardWiseGenderReport] = useState({});
  const [peopleRepresentativeInfo, setPeopleRepresentativeInfo] = useState([]);
  const [staffBrief, setStaffBrief] = useState([]);
  const [departmentLabels, setDepartmentLabels] = useState([]);
  const [departmentWiseReport, setDepartmentWiseReport] = useState([]);
  const palikaName = AppMisc.getPalikaNameFromHost();

  useEffect(() => {
    getGenderCount();
    getPeopleRepresentativeCount();
    getWardWiseStaff();
    getDepartmentWiseCount();
    getAgeGroupWiseStaffCount();
    getEmployeeTypeReport();
    getWardWiseGenderReport();
    getPeopleRepresentativeBrief();
    getStaffBrief();
  }, [])

  const getGenderCount = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=GENDER_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setGenderReport(jsondata.data || {});
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getPeopleRepresentativeCount = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=PEOPLE_REPRESENTATIVE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPeopleRepresentativeCount(jsondata.data);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getWardWiseStaff = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=WARD_WISE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setWardLabels(Object.keys(jsondata.data));
          setWardWiseEmployeeCount(Object.values(jsondata.data));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getDepartmentWiseCount = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=DEPARTMENT_WISE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setDepartmentLabels(Object.keys(jsondata.data));
          setDepartmentWiseReport(Object.values(jsondata.data));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getAgeGroupWiseStaffCount = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=AGE_GROUP`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          let report = jsondata.data;
          setAgeGroupWiseReport([report.ageGroupBetweenFifteenToTwentyFour, report.ageGroupBetweenTwentyFiveToThirtyFour, report.ageGroupBetweenThirtyFiveToFortyFour, report.ageGroupBetweenFortyFiveToFiftyFour, report.ageGroupBetweenFiftyFiveToSixtyFour, report.ageGroupAboveSixtyFive]);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getEmployeeTypeReport = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=EMPLOYEE_TYPE_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          let report = jsondata.data;
          setEmployeeTypeReport([report.permanent, report.temporary, report.karar, report.anya]);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getWardWiseGenderReport = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=WARD_WISE_GENDER_COUNT`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          let report = jsondata.data;
          let wardLabels = Object.keys(report);
          let values = Object.values(report);
          let femaleEmployee = values.map(item => item.femaleEmployee);
          let maleEmployee = values.map(item => item.maleEmployee);
          let otherEmployee = values.map(item => item.otherEmployee);
          let stackedBarData = {
            labels: wardLabels,
            datasets: [
              {
                label: 'महिला',
                data: femaleEmployee,
                backgroundColor: 'rgb(255, 99, 132)',
                stack: 'Stack 0',
              },
              {
                label: 'पुरुष',
                data: maleEmployee,
                backgroundColor: 'rgb(54, 162, 235)',
                stack: 'Stack 0',
              },
              {
                label: 'अन्य',
                data: otherEmployee,
                backgroundColor: 'rgb(75, 192, 192)',
                stack: 'Stack 1',
              },
            ],
          };
          setWardWiseGenderReport(stackedBarData);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getPeopleRepresentativeBrief = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=PEOPLE_REPRESENTATIVE_BRIEF`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPeopleRepresentativeInfo(jsondata.data || []);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getStaffBrief = async () => {
    await PIS.get(`${API_URL.public}?office=${palikaName}&method=STAFF_BRIEF`)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setStaffBrief(jsondata.data || []);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getCounts = count => {
    return (
      <>
        <CountUp delay={1} end={count} duration={3.5} />
        <span> जना</span>
      </>
    )
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <PublicHeader />
      <Box className={classes.cardContainer} mb={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Paper>
            <Link href={`/public/staffs-list?filter=पुरुष&mun=${palikaName}`}>
              <Box p={4} textAlign="center">
                <Accessibility color="primary" />
                <Typography variant="h5">पुरुष कर्मचारी</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.maleEmployee)}</Typography>
              </Box>
            </Link>
          </Paper>
          <Paper>
            <Link href={`/public/staffs-list?filter=महिला&mun=${palikaName}`}>
              <Box p={4} textAlign="center">
                <SupervisedUserCircle color="primary" />
                <Typography variant="h5">महिला कर्मचारी</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.femaleEmployee)}</Typography>
              </Box>
            </Link>
          </Paper>
          <Paper>
            <Link href={`/public/staffs-list?filter=अन्य&mun=${palikaName}`}>
              <Box p={4} textAlign="center">
                <PeopleAlt color="primary" />
                <Typography variant="h5">अन्य कर्मचारी</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.otherEmployee)}</Typography>
              </Box>
            </Link>
          </Paper>
          <Paper>
            <Link href={`/public/staffs-list?mun=${palikaName}`}>
              <Box p={4} textAlign="center">
                <Group color="primary" />
                <Typography variant="h5">जम्मा कर्मचारी</Typography>
                <Typography color="primary" variant="h4">{getCounts(genderReport.totalEmployee)}</Typography>
              </Box>
            </Link>
          </Paper>
          <Paper>
            <Link href={`/public/public-representative-list?mun=${palikaName}`}>
              <Box p={4} textAlign="center">
                <PeopleOutline color="primary" />
                <Typography variant="h5">जम्मा जन प्रतिनिधि</Typography>
                <Typography color="primary" variant="h4">{getCounts(peopleRepresentativeCount)}</Typography>
              </Box>
            </Link>
          </Paper>
        </Box>
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper elevation={0} >
              <LineChart
                data={wardWiseEmployeeCount}
                labels={wardLabels}
                diagramLabel="वडा अनुसार कर्मचारी संख्या"
                options={{
                  title: {
                    display: true,
                    text: "वडा अनुसार कर्मचारी वर्गिकरण"
                  },
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'वडा नं',
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'कर्मचारी संख्या'
                      },
                    }]
                  },
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} >
              <PieChart
                chartData={departmentWiseReport}
                labels={departmentLabels}
                options={{
                  title: {
                    display: true,
                    text: "शाखा अनुसार कर्मचारी संख्या"
                  },
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box mb={3}>
        <BarDiagram
          data={ageGroupWiseReport}
          labels={['15-24', '25-34', '35-44', '45-54', '55-64', '65+']}
          diagramLabel="उमेर समुह अनुसार कर्मचारी संख्या"
          options={{
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'उमेर समुह',
                }
              }],
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'कर्मचारी संख्या'
                },
              }]
            },
          }}
        />
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          {employeeTypeReport.some(item => Boolean(item)) && (
            <Grid item xs>
              <Paper elevation={0}>
                <DoughnutChart
                  chartData={employeeTypeReport}
                  labels={['स्थाई', 'आस्थायी', 'करार', 'अन्य']}
                />
              </Paper>
            </Grid>
          )}
          <Grid item xs>
            <Paper elevation={0} >
              <Bar
                data={wardWiseGenderReport}
                options={{
                  title: {
                    display: true,
                    text: "Ward Wise Gender Report"
                  },
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'वडा नं',
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'कर्मचारी संख्या'
                      },
                    }]
                  },
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" >
          <Box className={classes.publicRepresentativeCard}>
            <Box className={classes.subTitle} py={2}>
              <Typography variant="h5">जन-प्रतिनिधि</Typography>
            </Box>
            {peopleRepresentativeInfo.length ?
              (
                <Grid container spacing={1}>
                  {peopleRepresentativeInfo.map((item, index) => (
                    <Grid key={index} item xs={6}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Box className={classes.cardHeading}>
                            <Avatar className={classes.cardImage} src={item.photo} alt="" />
                            <Typography gutterBottom>{item.name}</Typography>
                            <Typography gutterBottom>{item.post}</Typography>
                          </Box>
                          <Box className={classes.details}>
                            <Typography>ईमेल: {item.email}</Typography>
                            <Typography>फोन: {item.phone ? AppUtils.replaceWithNepaliDigit(item.phone) : ""}</Typography>
                            <Typography>ठेगाना: {item.address}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )
              :
              (
                <EmptyContainer>
                  <Typography align="center">
                    माफ गर्नुहोस्, प्रणालीमा कुनै पनि रेकर्ड फेला परेन।
                  </Typography>
                </EmptyContainer>
              )
            }
          </Box>
          <Box className={classes.employeCard}>
            <Box className={classes.subTitle} py={2}>
              <Typography variant="h5">कर्मचारीहरु</Typography>
            </Box>
            {staffBrief.length ?
              (
                <Grid container spacing={1}>
                  {staffBrief.map((item, index) => (
                    <Grid key={index} item xs={4}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Box className={classes.cardHeading}>
                            <Avatar className={classes.cardImage} src={item.photo} alt="" />
                            <Typography gutterBottom>{item.name}</Typography>
                            <Typography gutterBottom>{item.post}</Typography>
                          </Box>
                          <Box className={classes.details}>
                            <Typography>ईमेल: {item.email}</Typography>
                            <Typography>फोन: {item.phone ? AppUtils.replaceWithNepaliDigit(item.phone) : ""}</Typography>
                            <Typography>ठेगाना: {item.address}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )
              :
              (
                <EmptyContainer>
                  <Typography align="center">
                    माफ गर्नुहोस्, प्रणालीमा कुनै पनि रेकर्ड फेला परेन।
                  </Typography>
                </EmptyContainer>
              )
            }
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
