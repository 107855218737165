
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import { DateUtils } from "../../../../../utils/dateUtils";
import styles from "../../style";

export default function StaffRetirementDetails(props) {
  const classes = styles();

  return (
    <Box className={classes.root} p={2}>
      <Grid container>
        <TableContainer className={classes.retirementDetailsTable}>
          <Table size="small" className="table-bordered" aria-label="Staff Other Details">
            <TableBody>
              <TableRow>
                <TableCell>अवकाशको स्थिति: {props.retirementDetails && (props.retirementDetails.status || "-")}</TableCell>
                <TableCell>अवकाश: {props.retirementDetails && (props.retirementDetails.type || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अवकाशको मिति (बि.सं.): {props.retirementDetails?.dateInBS || "-"}</TableCell>
                <TableCell>अवकाशको मिति (ई.सं.): {props.retirementDetails?.dateInBS ? DateUtils.getAdDateFromBs(props.retirementDetails.dateInBS) : "-"}</TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell>अनुमानित अवकाशको मिति (बि.सं.): {props.retirementDetails && (props.retirementDetails.estimatedDate || "-")}</TableCell> */}
                <TableCell colSpan={2}>अनुमानित अवकाशको मिति (ई.सं.): {props.retirementDetails?.estimatedDate || "-"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
