import { Box, Button, Divider, Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import { Add, Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import NepaliCalender from "../../../../components/nepali-calender";
import styles from "./style";

export default function AddAwardAndTestimonialDetails({ onAwardAndTestimonialDetailsUpdate, awardAndTestimonialDetails }) {
  const classes = styles();
  const staffId = AppUtils.getUrlParam(ID);
  const [awardAndTestimonialDetailsList, setAwardAndTestimonialDetailsList] = useState(awardAndTestimonialDetails || [{}]);

  const handleAwardAndTestimonialDetailsChange = (index, name, value) => {
    const values = [...awardAndTestimonialDetailsList];
    values[index][name] = value;
    setAwardAndTestimonialDetailsList(values);
  };

  const handleRemoveawardAndTestimonialDetails = index => {
    const values = [...awardAndTestimonialDetailsList];
    values.splice(index, 1);
    setAwardAndTestimonialDetailsList(values);
  };

  const handleAddawardAndTestimonialDetails = () => {
    const values = [...awardAndTestimonialDetailsList];
    values.push({});
    setAwardAndTestimonialDetailsList(values);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let postdata = {
      id: staffId,
      awardAndTestimonialDetailList: awardAndTestimonialDetailsList
    }

    PIS.post(API_URL.staff, postdata)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onAwardAndTestimonialDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={(e) => onSubmit(e)}>
        {awardAndTestimonialDetailsList.map((awardAndTestimonialDetail, index) => (
          <React.Fragment key={`${awardAndTestimonialDetail}~${index}`}>
            <Box className={classes.awardAndTestimonialDetailsContainer}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="विभूषण , प्रशंसा पत्रको विवरण"
                    name="awardOrTestimonialDescription"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleAwardAndTestimonialDetailsChange(index, event.target.name, event.target.value)}
                    defaultValue={awardAndTestimonialDetail.awardOrTestimonialDescription}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <NepaliCalender
                    id={`receivedDate~${index}`}
                    name="dateReceived"
                    label="प्राप्त मिति"
                    defaultDate={awardAndTestimonialDetail.dateReceived}
                    onChange={(name, dateInMilli) => handleAwardAndTestimonialDetailsChange(index, name, dateInMilli)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="विभूषण / प्रशंसा पत्र पाएको कारण"
                    name="reasonToRecieve"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleAwardAndTestimonialDetailsChange(index, event.target.name, event.target.value)}
                    defaultValue={awardAndTestimonialDetail.reasonToRecieve}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="सहुलियत"
                    name="convenience"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleAwardAndTestimonialDetailsChange(index, event.target.name, event.target.value)}
                    defaultValue={awardAndTestimonialDetail.convenience}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {
                (index > 0) &&
                <Tooltip title="शैक्षिक विवरण हटाउनुहोस्।" placement="top" arrow><Cancel className={classes.removeAwardAndTestimonialDetailsContainer} onClick={() => handleRemoveawardAndTestimonialDetails(index)} fontSize="small" /></Tooltip>
              }
              <Divider variant="middle" className={classes.divider} />
            </Box>
          </React.Fragment>
        ))}
        <Grid container justify="center" alignItems="center" className={classes.addAwardAndTestimonialDetailsBtnContainer}>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { handleAddawardAndTestimonialDetails() }}>नयाँ विवरण थप्नुहोस्</Button>
        </Grid>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !awardAndTestimonialDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {awardAndTestimonialDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  )
}
