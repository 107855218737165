export const PALIKA = "PALIKA";
export const SIGNATURE = "SIGNATURE";
export const STAMP = "STAMP";
export const LOGO = "LOGO";

//TODO: bikash --OTHER is a constant-keep it in constant later
export const FEEDBACK_SUBJECTS = [
  { value: "CLIENT_SERVICE_RELATED", label: "सामान्य ग्राहक सेवा" },
  { value: "SUGGESTIONS", label: "सुझावहरु" },
  { value: "SOFTWARE_RELATED", label: "सफ्टवेर सम्बन्धि जानकारी" },
  { value: "OTHER", label: "अन्य" }
];

export const OFFICE_TYPES = [
  { value: PALIKA, label: "पालिका" },
  { value: "GOVERNMENTAL_ORGANIZATION", label: "अन्य सरकारी संस्था " },
];

export const EMPLOYEE_TYPE = [
  { value: "PERMANENT", label: "स्थाई" },
  { value: "TEMPORARY", label: "आस्थायी" },
  { value: "KARAR", label: "करार" },
  { value: "OTHER", label: "अन्य" }
];