import { AppBar, Avatar, Box, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { ExitToApp as LogOutIcon, Menu as MenuIcon, Person as AccountIcon, Settings as SettingIcon } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../../action/userInfo";
import { getSettingData } from "../../action/settingData";
import PIS, { API_URL } from "../../api/api";
import logo from "../../assets/img/logo.png";
// context
import { toggleSidebar, useLayoutDispatch } from "../../context/LayoutContext";
import { useUserDispatch } from "../../context/UserContext";
import { AppMisc } from "../../misc/appMisc";
import { AppUtils } from "../../utils/appUtils";
import { ERROR, LOGOUT_SUCCESS, ROLE_ADMIN, ROLE_OFFICE_ADMIN, ROLE_OFFICE_STAFF, ROLE_STAFF, SOMETHING_WENT_WRONG, SUCCESS } from "../../utils/constants";
import AddAlertMessage from "../alert/Alert";
import styles from "./style";

export default function Header(props) {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const defaultValues = useSelector(state => state.settingData);

  const classes = styles();
  let history = useHistory();

  // global
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  useEffect(() => {
    !userInfo && dispatch(getUserInfo())
  }, [])

  useEffect(() => {
    (!defaultValues && userInfo && userInfo.role !== ROLE_ADMIN) && dispatch(getSettingData());
  }, [defaultValues, userInfo])

  const logOut = () => {
    PIS.post(API_URL.logout)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          AppUtils.removeUserRef();
          userDispatch({ type: LOGOUT_SUCCESS });
          dispatch({ type: LOGOUT_SUCCESS })
          history.push("/");
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {AppMisc.isRoleStaff(AppUtils.getUserRole()) && <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          <MenuIcon
            classes={{
              root: classNames(
                classes.headerIcon,
                classes.headerIconCollapse,
              ),
            }}
          />
        </IconButton>}
        <img src={defaultValues?.logoUrl?.absolutePath ? defaultValues?.logoUrl?.absolutePath : logo} alt="PIS" width="36"></img>
        <Box variant="h6" className={classes.brand}>
          <Typography>{AppMisc.getMunicipalityName(userInfo?.officeName)}</Typography>
          <Typography className={classes.address}>{userInfo?.role === ROLE_ADMIN ? <Typography variant="h6">PIS</Typography> : `${AppMisc.getProvinceName(userInfo?.province)}, ${AppMisc.getDistrictName(userInfo?.district)}, नेपाल`}</Typography>
        </Box>
        <Box display="flex" className={classes.userProfileMenu} justifyContent="center" alignItems="center" onClick={e => setProfileMenu(e.currentTarget)}>
          <Typography variant="body2" className={classes.username}>
            {userInfo?.fullName || "PIS"}
          </Typography>
          <Avatar alt="Avatar" src={logo} />
        </Box>
        <Menu anchorEl={profileMenu} open={Boolean(profileMenu)} onClose={() => setProfileMenu(null)} classes={{ paper: classes.profileMenu }} disableAutoFocusItem>
          <MenuItem className={classes.profileMenuItem}>
            <Link href="profile" variant="body1" className={classes.profileMenuLink}>
              <AccountIcon className={classes.profileMenuIcon} />
              Profile
            </Link>
          </MenuItem>
          {(userInfo?.role === ROLE_OFFICE_ADMIN || userInfo?.role === ROLE_OFFICE_STAFF) &&
            <MenuItem className={classes.profileMenuItem}>
              <Link href="setting" variant="body1" className={classes.profileMenuLink}>
                <SettingIcon className={classes.profileMenuIcon} />
                Setting
              </Link>
            </MenuItem>
          }
          <MenuItem className={classes.profileMenuItem}>
            <Link onClick={logOut} variant="body1" className={classes.profileMenuLink}>
              <LogOutIcon className={classes.profileMenuIcon} />
              Logout
            </Link>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
