import React from 'react';
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { NO_RECORDS_FOUND } from '../../utils/constants';
import EmptyContainer from '../empty-container/EmptyContainer';
import Spinner from '../loader/Loader';
import GlobalFilter from './helpers/GlobalFilter';
import styles from './style';

export default function CustomTable({ columns, data, isLoading, hideGlobalFilter }) {
  const classes = styles();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, useSortBy)
  const { globalFilter } = state;

  return (
    <>
      {!hideGlobalFilter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} isLoading={isLoading} />}
      <div className={classes.scroll}>
        <table {...getTableProps()}>
          <thead className={classes.tableHead}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps([{
                    className: column.className
                  },
                  column.getSortByToggleProps({})])}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              isLoading ?
                (
                  <tr>
                    <td colSpan="100%">
                      <EmptyContainer>
                        <Spinner />
                      </EmptyContainer>
                    </td>
                  </tr>
                )
                :
                (
                  rows.length === 0 ?
                    (
                      <tr>
                        <td colSpan="100%">{NO_RECORDS_FOUND}</td>
                      </tr>
                    )
                    :
                    (
                      rows.map(
                        (row, i) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td {...cell.getCellProps({
                                    className: cell.column.className
                                  })}>{cell.render('Cell')}</td>
                                )
                              })}
                            </tr>
                          )
                        }
                      )
                    )
                )}
          </tbody>
        </table>
      </div>
    </>
  );
}
