import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import CustomModal from "../../../../components/modal/CustomModal";
import { AppUtils } from "../../../../utils/appUtils";
import { ENTER_VALID_EMAIL, GENDER_OPTIONS, PHONE_NUMBER_MUST_NOT_BE_GREATER_THAN_TEN, REQUIRED_FIELD } from "../../../../utils/constants";
import { PALIKA } from "../../../../utils/constants/forms";
import styles from "../style";

export default function AddPublicRepresentativeModal({ showAddPublicRepresentativeModal, handleAddPublicRepresentativeModalClose, onSubmit, ...props }) {
  const classes = styles();
  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const officeType = AppUtils.getOfficeType();

  useEffect(() => {
    props.modalDefaultValues && reset(props.modalDefaultValues);
  }, [reset, props.modalDefaultValues])

  useEffect(() => {
    register({ name: "gender" })
  }, [register])

  function closeAddPublicRepresentativeModal() {
    reset();
    handleAddPublicRepresentativeModalClose();
  }

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  return (
    <>
      <CustomModal
        title="नयाँ जन प्रतिनिधि विवरण थप्नुहोस्"
        onModalSubmit={handleSubmit(onSubmit)}
        showModal={showAddPublicRepresentativeModal}
        onModalClose={closeAddPublicRepresentativeModal}
        maxWidth="lg"
      >
        <Grid container alignItems="flex-start" spacing={2} className={classes.row}>
          <Grid item xs={4}>
            <TextField
              label="क्रम संख्या"
              name="serialNumber"
              type="number"
              variant="outlined"
              size="small"
              inputRef={register({
                required: true
              })}
              fullWidth
            />
            {errors.serialNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="पुरा नाम"
              type="text"
              variant="outlined"
              size="small"
              name="fullName"
              inputRef={register({
                required: true
              })}
            />
            {errors.fullName && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="पद"
              type="text"
              variant="outlined"
              size="small"
              name="post"
              inputRef={register({
                required: true
              })}
            />
            {errors.post && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="राजनीतिक दल/स्वतन्त्र"
              variant="outlined"
              size="small"
              name="politicalPartyOrIndependent"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              label="लिङ्ग"
              name="gender"
              defaultValue={props.modalDefaultValues?.gender}
              options={GENDER_OPTIONS}
              onChange={handleCustomSelectChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="उमेर"
              type="number"
              variant="outlined"
              size="small"
              name="age"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              size="small"
              variant="outlined"
              name="email"
              inputRef={register({
                pattern: /\S+@\S+\.\S+/
              })}
            />
            {errors.email && errors.email.type === "pattern" && <span className="error-message">{ENTER_VALID_EMAIL}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="फोन नं."
              type="text"
              size="small"
              variant="outlined"
              name="phoneNumber"
              inputRef={register({
                validate: value => value.trim().length <= 10
              })}
            />
            {errors.phoneNumber && <span className="error-message">{PHONE_NUMBER_MUST_NOT_BE_GREATER_THAN_TEN}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="वडा नं."
              type="number"
              variant="outlined"
              size="small"
              name="wardNumber"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="ठेगाना"
              type="text"
              size="small"
              variant="outlined"
              name="address"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={4}>
            {
              officeType === PALIKA &&
              <FormControlLabel
                control={<Checkbox color="primary" defaultChecked={props.modalDefaultValues && props.modalDefaultValues.showInPublicPage} />}
                label="पब्लिक गृहपृष्ठ मा देखाउनुहोस्।"
                name="showInPublicPage"
                inputRef={register}
              />
            }
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={<Checkbox color="primary" defaultChecked={props.modalDefaultValues?.formerPublicRepresentative} />}
              label="भूतपूर्व जन प्रतिनिधि"
              name="formerPublicRepresentative"
              inputRef={register}
            />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};