import { makeStyles } from "@material-ui/core/styles";
const styles = makeStyles(theme => ({
  settingContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hiddenInput: {
    display: "none",
  },
  imageContainer: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.divider,
    borderRadius: theme.spacing(.5),
    minHeight: theme.spacing(13),
    minWidth: theme.spacing(19)
  },
  signature: {
    maxWidth: theme.spacing(16)
  }
}));
export default styles;