import { Box, Button, Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import NepaliCalender from "../../../../components/nepali-calender";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import CategorySelect from "../category-select/CategorySelect";
import PostSelect from "../post-select/PostSelect";
import styles from "./style";

export default function AddAppointedServiceDetails({ onAppointedServiceDetailsUpdate, appointedServiceDetails, ...props }) {
  const classes = styles();
  const { register, handleSubmit, errors, setValue } = useForm();
  const staffId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    register({ name: "applicationDate" }, { required: true });
    register({ name: "decisionDate" }, { required: true });
    register({ name: "officeAttendanceDate" }, { required: true });
    register({ name: "categoryAndLabel" }, { required: true });
    register({ name: "post" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (appointedServiceDetails) {
      setValue("applicationDate", appointedServiceDetails.applicationDate);
      setValue("decisionDate", appointedServiceDetails.decisionDate);
      setValue("officeAttendanceDate", appointedServiceDetails.officeAttendanceDate);
    }
  }, [appointedServiceDetails]);

  const onSubmit = data => {
    data.staffId = AppUtils.getUrlParam(ID);
    let postData = {
      id: staffId,
      appointedServiceInfo: data
    }

    PIS.post(API_URL.staff, postData)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onAppointedServiceDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli)
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Tooltip title="जस्तै: स्वास्थ्य तथा जनसंख्या मन्त्रालय ,काठमाण्डौ" placement="top" arrow>
                <TextField
                  label="माथिल्लो निकाय"
                  name="upperBody"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={appointedServiceDetails.upperBody}
                  fullWidth
                />
              </Tooltip>
              {errors.upperBody && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="जिल्ला"
                name="district"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                defaultValue={appointedServiceDetails.district}
                fullWidth
              />
              {errors.district && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="जस्तै: उपस्वास्थ्य चौकी , बुद्धनगर, काठमाडौं" placement="top" arrow>
                <TextField
                  label="कार्यालय"
                  name="office"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={appointedServiceDetails.office}
                  fullWidth
                />
              </Tooltip>
              {errors.office && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="जस्तै: नेपाल स्वास्थ्य सेवा / हेल्थ इन्सपेक्सन" placement="top" arrow>
                <TextField
                  label="सेवा/समुह/उप-समूह"
                  name="serviceOrGroup"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={appointedServiceDetails.serviceOrGroup}
                  fullWidth
                />
              </Tooltip>
              {errors.serviceOrGroup && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Tooltip title="जस्तै: नियुक्ति" placement="top" arrow>
                <TextField
                  label="सेवा परिमाण"
                  name="serviceVolume"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={appointedServiceDetails.serviceVolume}
                  fullWidth
                />
              </Tooltip>
              {errors.serviceVolume && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="जस्तै: प्राबिधिक" placement="top" arrow>
                <TextField
                  label="सेवाको किसिम"
                  name="serviceType"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={appointedServiceDetails.serviceType}
                  fullWidth
                />
              </Tooltip>
              {errors.serviceType && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <CategorySelect
                onChange={handleCustomSelectChange}
                defaultValue={appointedServiceDetails.categoryAndLabel}
                error={errors.categoryAndLabel}
              />
            </Grid>
            <Grid item xs>
              <PostSelect
                onChange={handleCustomSelectChange}
                defaultValue={appointedServiceDetails.post}
                error={errors.post}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Tooltip title="लागू हुने मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    id="applicationDate"
                    name="applicationDate"
                    label="लागू हुने मिति"
                    onChange={handleDateChange}
                    defaultDate={appointedServiceDetails?.applicationDate}
                  />
                </Box>
              </Tooltip>
              {errors.applicationDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="निर्णय मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="decisionDate"
                    id="decisionDate"
                    label="निर्णय मिति"
                    onChange={handleDateChange}
                    defaultDate={appointedServiceDetails?.decisionDate}
                  />
                </Box>
              </Tooltip>
              {errors.decisionDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="कार्यालयमा हाजिरी मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="officeAttendanceDate"
                    id="officeAttendanceDate"
                    label="कार्यालयमा हाजिरी मिति"
                    onChange={handleDateChange}
                    defaultDate={appointedServiceDetails?.officeAttendanceDate}
                  />
                </Box>
              </Tooltip>
              {errors.officeAttendanceDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !appointedServiceDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {appointedServiceDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
