import { Box, Button, Divider, Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import { Add, Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import styles from "./style";

export default function AddEducationalDetails({ onEducationalDetailsUpdate, educationalDetails, educationalQualificationList }) {
  const classes = styles();
  const staffId = AppUtils.getUrlParam(ID);
  const [educationalDetailsList, setEducationalDetailsList] = useState(educationalDetails || [{}]);

  const handleEducationalDetailsChange = (index, event) => {
    const values = [...educationalDetailsList];
    values[index][event.target.name] = event.target.value;
    setEducationalDetailsList(values);
  };

  const handleEducationalQualificationChange = (index, name, value) => {
    const values = [...educationalDetailsList];
    values[index][name] = value;
    setEducationalDetailsList(values);
  };

  const handleRemoveEducationalDetails = index => {
    const values = [...educationalDetailsList];
    values.splice(index, 1);
    setEducationalDetailsList(values);
  };

  const handleAddEducationalDetails = () => {
    const values = [...educationalDetailsList];
    values.push({});
    setEducationalDetailsList(values);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let postdata = {
      id: staffId,
      educationalDetailList: educationalDetailsList
    }

    PIS.post(API_URL.staff, postdata)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onEducationalDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={(e) => onSubmit(e)}>
        {educationalDetailsList.map((educationalDetail, index) => (
          <React.Fragment key={`${educationalDetail}~${index}`}>
            <Box className={classes.educationalDetailsContainer}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <CustomSelect
                    label="शैक्षिक योग्यता वा उपाधि"
                    name="educationalQualificationOrDegree"
                    options={educationalQualificationList || []}
                    defaultValue={educationalDetail.educationalQualificationOrDegree}
                    onChange={(name, value) => handleEducationalQualificationChange(index, name, value)}
                    creatable
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="अध्ययनको विषय वा संकाय"
                    name="studySubjectOrFaculty"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleEducationalDetailsChange(index, event)}
                    defaultValue={educationalDetail.studySubjectOrFaculty}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="उतिर्ण गरेको साल"
                    name="yearPassed"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleEducationalDetailsChange(index, event)}
                    defaultValue={educationalDetail.yearPassed}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="प्राप्त श्रेणी"
                    name="recievedGradingCategory"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleEducationalDetailsChange(index, event)}
                    defaultValue={educationalDetail.recievedGradingCategory}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="शिक्षण संस्था / परिषद / विश्वविद्यालयको नाम र देश"
                    name="educationalInstitutionNameAndCountry"
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={event => handleEducationalDetailsChange(index, event)}
                    defaultValue={educationalDetail.educationalInstitutionNameAndCountry}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {
                (index > 0) &&
                <Tooltip title="शैक्षिक विवरण हटाउनुहोस्।" placement="top" arrow><Cancel className={classes.removeEducationalDetailsContainer} onClick={() => handleRemoveEducationalDetails(index)} fontSize="small" /></Tooltip>
              }
              <Divider variant="middle" className={classes.divider} />
            </Box>
          </React.Fragment>
        ))}
        <Grid container justify="center" alignItems="center" className={classes.addEducationalDetailsBtnContainer}>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { handleAddEducationalDetails() }}>नयाँ विवरण थप्नुहोस्</Button>
        </Grid>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !educationalDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {educationalDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  )
}
