import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(1),
    border: "1px solid #BDBDBD",
    borderRadius: theme.spacing(.5),
    // International standard size of card is: 85.60mm * 53.98mm
    width: "126mm",
    height: "78.58mm",
    "@media print": {
      border: 0,
    },
  },
  cardHead: {
    height: "70px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
  officeLogo: {
    width: "60px",
    marginBottom: "8px"
  },
  officeName: {
    textAlign: "center",
  },
  cardContent: {
    paddingTop: "4px",
    height: "194px",
    display: "flex",
    justifyContent: "flex-start",
  },
  cardImage: {
    width: "160px",
    left: "0",
    paddingRight: "10px",
    display: "flex",
    position: "relative"
  },
  image: {
    height: "170px",
    width: "150px",
    objectFit: "cover",
    border: "1px solid #BDBDBD"
  },
  signature: {
    width: theme.spacing(10),
    position: "absolute",
    bottom: "10px",
    left: "0"
  },
  stamp: {
    width: theme.spacing(6),
    position: "absolute",
    top: "0",
    right: "0"
  },
  cardDetails: {
    width: "206px",
    paddingLeft: "6px"
  },
  cardBody: {
    "& .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "13px",
      lineHeight: 2
    },
    paddingTop: "16px"
  },
  cardQr: {
    width: "95px",
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  qr: {
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  cardWrapper:{
    display: "flex",
    justifyContent: "space-around",
    "@media print": {
      padding: 0,
      display: "block"
    }
  },
  successContainer: {
    background: "#008000",
    padding: theme.spacing(5) 
  },
  errorContainer: {
    background: "#FF6347",
    padding: theme.spacing(5)
  },
}));
export default styles;
