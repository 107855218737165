import { Box, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { AppUtils } from '../../../utils/appUtils'

export default function GlobalFilter({ filter, setFilter, isLoading }) {
  useEffect(() => {
    !isLoading && setFilter(AppUtils.getUrlParam('filter') || "")
  }, [isLoading, setFilter, AppUtils.getUrlParam('filter')])

  useEffect(() => {
    setFilter(null);
  }, [setFilter])

  return (
    <Box display="flex" className="print-none" justifyContent="flex-end" mb={2}>
      <TextField
        label="Search"
        name="searchText"
        type="text"
        size="small"
        variant="outlined"
        value={filter}
        InputLabelProps={{ shrink: true }}
        onChange={e => setFilter(e.target.value)}
      />
    </Box>
  )
}
