import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./style";
import PIS, { API_URL } from "../../../../api/api";
import { ERROR, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants";
import AddAlertMessage from "../../../../components/alert/Alert";
import { AppUtils } from "../../../../utils/appUtils";

export default function PublicRepresentativeCardValidation() {
  const classes = styles();
  const id = AppUtils.getUrlParam(ID);
  const [cardDetails, setCardDetails] = useState({});

  useEffect(() => {
    validateCard();
  }, [])

  const closeWindow = () => {
    window.open("about:blank", "_self");
    window.close();
  }

  const validateCard = () => {
    let url = `${API_URL.validatePublicRepresentativeCard}?id=${id}`;
    PIS.get(url)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setCardDetails(jsondata.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      {cardDetails.validated ? (
        <Box className={classes.successContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Typography><b>पूरा नाम :</b> {cardDetails.fullName}</Typography>
              <Typography><b>ठेगाना :</b> {cardDetails.address || "-"}</Typography>
              <Typography><b>पद :</b> {cardDetails.post || "-"}</Typography>
              <Typography><b>इमेल :</b> {cardDetails.email || "-"}</Typography>
              <Typography><b>फोन नम्बर :</b> {cardDetails.phoneNumber ? AppUtils.replaceWithNepaliDigit(cardDetails.phoneNumber.trim()) : "-"}</Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className={classes.errorContainer}>
          <Typography variant="h5">माफ गर्नुहोस यो कार्ड हाम्रो सिस्टममा रेजिस्टर छैन</Typography>
        </Box>
      )}
      <Box display="flex" mt={2} justifyContent="flex-end">
        <Button onClick={closeWindow} size="medium" variant="contained" color="primary">
          OK
        </Button>
      </Box>
    </>
  )
}