import { Button, Grid } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import React, { useState } from "react";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import { ERROR, OPERATION_LIST_BEFORE_DEPLOYMENT, SOMETHING_WENT_WRONG } from "../../../utils/constants";

export default function SystemSetup() {
  const [operation, setOperation] = useState(null);

  const handleExecution = () => {
    PIS.get(API_URL.databaseOperations + "?operation=" + operation).then(response => {
      let jsondata = response.data;
      AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      setOperation(null);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CustomSelect
            label="Operations"
            defaultValue={operation}
            name="operations"
            options={OPERATION_LIST_BEFORE_DEPLOYMENT}
            onChange={(...params) => setOperation(params[1])}
          />
        </Grid>
        <Grid item xs>
          <Button startIcon={<PlayArrow />} onClick={handleExecution} disabled={!Boolean(operation)} color="primary" variant="contained">
            Start Execution
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
