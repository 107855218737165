import { Box, Button, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import PIS, { API_URL } from '../../../api/api';
import AddUserModal from '../../../components/add-user/AddUserModal';
import AddAlertMessage from '../../../components/alert/Alert';
import { AppUtils } from '../../../utils/appUtils';
import { ADD_USER_ALLOWED_ROLES_FOR_ADMIN, ERROR, ID, ROLE_ADMIN, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import OfficeUserList from './helpers/OfficeUserList';

export default function OfficeHome() {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [officeUserTableData, setOfficeUserTableData] = useState([]);

  const officeId = AppUtils.getUrlParam(ID);
  const userRole = AppUtils.getUserRole();

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
    setModalDefaultValues({});
  }

  const handleAddUserModalSubmit = (data) => {
    data.id = modalDefaultValues.id;
    delete data.departmentIdList;
    if (officeId && userRole === ROLE_ADMIN) {
      data.officeId = officeId;
    }
    PIS.post(API_URL.user, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          getOfficeUserList();
          handleAddUserModalClose();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      });
  }

  useEffect(() => {
    getOfficeUserList();
  }, [])

  const getOfficeUserList = () => {
    PIS.get(API_URL.officeUsers + "?officeId=" + officeId)
      .then(response => {
        if (response.data.type === SUCCESS) {
          setOfficeUserTableData(response.data.data);
        } else {
          AddAlertMessage({ type: response.data.type, message: response.data.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleRowEdit = id => {
    PIS.get(API_URL.user + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data.data);
        setOpenAddUserModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
        <Typography variant="h5">प्रयोगकर्ताहरू</Typography>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddUserModal(true) }}>नयाँ प्रयोगकर्ता थप्नुहोस्</Button>
      </Box>
      <Box>
        <OfficeUserList tableData={officeUserTableData} editRow={handleRowEdit} />
      </Box>
      <AddUserModal userRoles={ADD_USER_ALLOWED_ROLES_FOR_ADMIN} showAddUserModal={openAddUserModal} handleAddUserModalClose={handleAddUserModalClose} onSubmit={handleAddUserModalSubmit} modalDefaultValues={modalDefaultValues} />
    </>
  )
}
