
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import styles from "../../style";

export default function OtherDetails(props) {
  const classes = styles();

  return (
    <Box className={classes.root} p={2}>
      <Grid container>
        <TableContainer className={classes.otherDetailsTable}>
          <Table size="small" className="table-bordered" aria-label="Staff Other Details">
            <TableBody>
              <TableRow>
                <TableCell>उचाई: {props.otherDetails && (props.otherDetails.height || "-")}</TableCell>
                <TableCell>रक्त समूह: {props.otherDetails && (props.otherDetails.bloodGroup || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>क.सं. कोष परिचय पत्र नं.: {props.otherDetails && (props.otherDetails.accumulationFundNumber || "-")}</TableCell>
                <TableCell>क.सं. कोष जारी मिति  : {props.otherDetails && (props.otherDetails.accumulationFundNumberReleaseDateInBS || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>नागरिक लगानी कोष नं.: {props.otherDetails && (props.otherDetails.citizenInvestmentFundNumber || "-")}</TableCell>
                <TableCell>राहदानी नं: {props.otherDetails && (props.otherDetails.passportNumber || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>सवारी चालक अनुमती नं.: {props.otherDetails && (props.otherDetails.drivingLicenceNumber || "-")}</TableCell>
                <TableCell>फ्याक्स नं.: {props.otherDetails && (props.otherDetails.faxNumber || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अशक्त विवरण: {props.otherDetails && (props.otherDetails.differentlyAbledDescription || "-")}</TableCell>
                <TableCell>मोबाईल नम्बर: {props.otherDetails && (props.otherDetails.mobileNumber || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>कैफियत: {props.otherDetails && (props.otherDetails.remarks || "-")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
