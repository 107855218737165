import { Box, Button, FormLabel, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import { REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import styles from "./style";

export default function ImportStaffDetails() {
  const classes = styles();
  const { register, handleSubmit, setValue, errors, reset } = useForm();
  const [attachment, setAttachment] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  useEffect(() => {
    register({ name: "officeId" }, { required: true });
  }, [register])

  useEffect(() => {
    getOffices();
  }, [])

  const getOffices = () => {
    let officeList = [];
    PIS.get(API_URL.office)
      .then(response => {
        response.data.data.forEach(item => {
          officeList.push({ "value": item.id, "label": item.name });
        });
        setOfficeList(officeList);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const onSubmit = data => {
    const formData = new FormData();
    for (let i = 0; i < attachment.length; i++) {
      formData.append("file", attachment[i]);
    }
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    PIS.post(API_URL.importFromExcel, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(response => {
        if (response.data.type === SUCCESS) {
          reset({});
          AddAlertMessage({ type: response.data.type, message: response.data.message });
        } else {
          AddAlertMessage({ type: response.data.type, message: (response.data.data?.join(", ") || response.data.message) });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value);
  }

  function handleDocumentsChange(e) {
    let files = e.target.files;
    let filesList = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        filesList.push(files[i]);
      }
    }
    setAttachment(filesList);
  }

  return (
    <>
      <Box className={classes.dashboardContainer} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
        <Typography variant="h6">कर्मचारी विवरण आयात गर्नुहोस्</Typography>
      </Box>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid container spacing={2} alignItems="center" className={classes.row}>
              <Grid item xs>
                <CustomSelect
                  label="कार्यालयको नाम"
                  name="officeId"
                  options={officeList}
                  onChange={handleCustomSelectChange}
                />
                {errors.officeId && (
                  <span className="error-message">{REQUIRED_FIELD}</span>
                )}
              </Grid>
              <Grid item xs>
                <Box mb={1}>
                  <FormLabel component="legend">Select Excel File</FormLabel>
                </Box>
                <Box className={classes.filesContainer}>
                  <input
                    type="file"
                    name="documents"
                    accept=".xlsx,.xls"
                    onChange={handleDocumentsChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box item="true" textAlign="right" className={classes.btnContainer}>
            <Button
              type="reset"
              color="secondary"
              variant="contained"
              className={classes.resetBtn}
            >
              रद्द गर्नुहोस
            </Button>
            <Button type="submit" color="primary" variant="contained">सुरक्षित गर्नुहोस</Button>
          </Box>
        </form>
      </Paper>
    </>
  );
}