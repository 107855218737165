import { Box } from '@material-ui/core';
import React, { forwardRef } from 'react';
import Card from './helpers/Card';
import styles from './style';

function PublicRepresentativeCard(props, ref) {
  const classes = styles();
  return (
    <Box className={classes.cardWrapper} ref={ref}>
      <Card
        publicRepresentativeInfo={props.PublicRepresentativeInfo}
      />
    </Box>
  )
}

export default forwardRef(PublicRepresentativeCard);