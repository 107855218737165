import { LOGOUT_SUCCESS } from "../utils/constants";
import { SAVE_SETTING_DATA } from "../utils/constants/redux";

const reducer = (settingData = null, action) => {
  switch (action.type) {
    case SAVE_SETTING_DATA:
      return action.payload;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return settingData;
  }
}

export default reducer;