import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import NepaliCalender from "../../../../components/nepali-calender";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants/index";
import CategorySelect from "../category-select/CategorySelect";
import DepartmentSelect from "../DepartmentSelect/DepartmentSelect";
import PostSelect from "../post-select/PostSelect";
import styles from "./style";

export default function AddCurrentServiceDetails({ onCurrentServiceDetailsUpdate, currentServiceDetails, subDepartmentList, ...props }) {
  const classes = styles();
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [subDepartmentOptions, setSubDepartmentOptions] = useState([]);
  const [clearSubDepartmentId, setClearSubDepartmentId] = useState(false);
  const staffId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    register({ name: "applicationDate" }, { required: true });
    register({ name: "decisionDate" }, { required: true });
    register({ name: "officeAttendanceDate" }, { required: true });
    register({ name: "appointmentToCurrentPostDate" }, { required: true });
    register({ name: "categoryAndLabel" }, { required: true });
    register({ name: "post" }, { required: true });
    register({ name: "departmentId" });
    register({ name: "subDepartmentId" });
    register({ name: "propertyDetailsSubmissionDate" });
    register({ name: "performanceAppraisalSubmissionDate" });
  }, [register]);

  const watchFields = watch(["departmentId", "propertyDetailsSubmission", "performanceAppraisalSubmission"]);

  useEffect(() => {
    if (currentServiceDetails) {
      setValue("applicationDate", currentServiceDetails.applicationDate);
      setValue("decisionDate", currentServiceDetails.decisionDate);
      setValue("officeAttendanceDate", currentServiceDetails.officeAttendanceDate);
      setValue("appointmentToCurrentPostDate", currentServiceDetails.appointmentToCurrentPostDate);
      setValue("propertyDetailsSubmissionDate", currentServiceDetails.propertyDetailsSubmissionDate);
      setValue("performanceAppraisalSubmissionDate", currentServiceDetails.performanceAppraisalSubmissionDate);
    }
  }, [currentServiceDetails]);

  useEffect(() => {
    let subDepartments = [];
    subDepartmentList.forEach(item => {
      if (item.departmentId === watchFields.departmentId) {
        subDepartments.push({ value: item.id, label: item.subDivisionName })
      }
    })
    setSubDepartmentOptions(subDepartments)
  }, [watchFields.departmentId, subDepartmentList])

  const onSubmit = data => {
    data.staffId = AppUtils.getUrlParam(ID);
    data.propertyDetailsSubmissionDate = watchFields.propertyDetailsSubmission ? (data.propertyDetailsSubmissionDate) : null;
    data.performanceAppraisalSubmissionDate = watchFields.performanceAppraisalSubmission ? (data.performanceAppraisalSubmissionDate) : null;
    let postData = {
      id: staffId,
      currentServiceInfo: data
    }
    
    PIS.post(API_URL.staff, postData)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          onCurrentServiceDetailsUpdate();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const handleDepartmentAndSubDepartmentIdChange = (name, value) => {
    name === "subDepartmentId" ? setClearSubDepartmentId(false) : setClearSubDepartmentId(true);
    setValue(name, value)
  }

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli)
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>

          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Tooltip title="जस्तै: स्वास्थ्य तथा जनसंख्या मन्त्रालय ,काठमाण्डौ" placement="top" arrow>
                <TextField
                  label="माथिल्लो निकाय"
                  name="upperBody"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={currentServiceDetails.upperBody}
                  fullWidth
                />
              </Tooltip>
              {errors.upperBody && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <TextField
                label="जिल्ला"
                name="district"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register({
                  required: true
                })}
                defaultValue={currentServiceDetails.district}
                fullWidth
              />
              {errors.district && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="जस्तै: उपस्वास्थ्य चौकी , बुद्धनगर, काठमाडौं" placement="top" arrow>
                <TextField
                  label="कार्यालय"
                  name="office"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={currentServiceDetails.office}
                  fullWidth
                />
              </Tooltip>
              {errors.office && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="जस्तै: नेपाल स्वास्थ्य सेवा / हेल्थ इन्सपेक्सन" placement="top" arrow>
                <TextField
                  label="सेवा/समुह/उप-समूह"
                  name="serviceOrGroup"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={currentServiceDetails.serviceOrGroup}
                  fullWidth
                />
              </Tooltip>
              {errors.serviceOrGroup && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Tooltip title="जस्तै: नियुक्ति" placement="top" arrow>
                <TextField
                  label="सेवा परिमाण"
                  name="serviceVolume"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={currentServiceDetails.serviceVolume}
                  fullWidth
                />
              </Tooltip>
              {errors.serviceVolume && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="जस्तै: प्राबिधिक" placement="top" arrow>
                <TextField
                  label="सेवाको किसिम"
                  name="serviceType"
                  type="text"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: true
                  })}
                  defaultValue={currentServiceDetails.serviceType}
                  fullWidth
                />
              </Tooltip>
              {errors.serviceType && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <DepartmentSelect
                defaultValue={currentServiceDetails?.departmentId}
                onChange={handleDepartmentAndSubDepartmentIdChange}
              />
            </Grid>
            <Grid item xs>
              <CustomSelect
                label="उपशाखा"
                name="subDepartmentId"
                options={subDepartmentOptions}
                defaultValue={currentServiceDetails?.subDepartmentId}
                onChange={handleDepartmentAndSubDepartmentIdChange}
                clearSelect={clearSubDepartmentId}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <CategorySelect
                onChange={handleCustomSelectChange}
                defaultValue={currentServiceDetails.categoryAndLabel}
                error={errors.categoryAndLabel}
              />
            </Grid>
            <Grid item xs>
              <PostSelect
                onChange={handleCustomSelectChange}
                defaultValue={currentServiceDetails.post}
                error={errors.post}
              />
            </Grid>
            <Grid item xs>
              <Tooltip title="लागू हुने मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="applicationDate"
                    id="applicationDate"
                    label="लागू हुने मिति"
                    onChange={handleDateChange}
                    defaultDate={currentServiceDetails?.applicationDate}
                  />
                </Box>
              </Tooltip>
              {errors.applicationDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs>
              <Tooltip title="निर्णय मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="decisionDate"
                    id="decisionDate"
                    label="निर्णय मिति"
                    onChange={handleDateChange}
                    defaultDate={currentServiceDetails?.decisionDate}
                  />
                </Box>
              </Tooltip>
              {errors.decisionDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs={3}>
              <Tooltip title="कार्यालयमा हाजिरी मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="officeAttendanceDate"
                    id="officeAttendanceDate"
                    label="कार्यालयमा हाजिरी मिति"
                    onChange={handleDateChange}
                    defaultDate={currentServiceDetails?.officeAttendanceDate}
                  />
                </Box>
              </Tooltip>
              {errors.officeAttendanceDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="हालको पदमा नियूक्ती मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="appointmentToCurrentPostDate"
                    id="appointmentToCurrentPostDate"
                    label="हालको पदमा नियूक्ती मिति"
                    onChange={handleDateChange}
                    defaultDate={currentServiceDetails?.appointmentToCurrentPostDate}
                  />
                </Box>
              </Tooltip>
              {errors.appointmentToCurrentPostDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" defaultChecked={currentServiceDetails?.propertyDetailsSubmissionDate} />}
                name="propertyDetailsSubmission"
                label="सम्पत्ति विवरण बुझाएको"
                inputRef={register}
              />
            </Grid>
            {watchFields.propertyDetailsSubmission && <Grid item xs>
              <Tooltip title="सम्पत्ति विवरण बुझाएको मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="propertyDetailsSubmissionDate"
                    id="property-details-submission-date"
                    label="सम्पत्ति विवरण बुझाएको मिति"
                    onChange={handleDateChange}
                    defaultDate={currentServiceDetails?.propertyDetailsSubmissionDate}
                  />
                </Box>
              </Tooltip>
              {errors.propertyDetailsSubmissionDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>}
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" defaultChecked={currentServiceDetails?.performanceAppraisalSubmissionDate} />}
                name="performanceAppraisalSubmission"
                label="कार्य सम्पादन मुल्याङ्कन बुझाएको"
                inputRef={register}
              />
            </Grid>
            {watchFields.performanceAppraisalSubmission && <Grid item xs={3}>
              <Tooltip title="कार्य सम्पादन मुल्याङ्कन बुझाएको मिति" placement="top" arrow>
                <Box>
                  <NepaliCalender
                    name="performanceAppraisalSubmissionDate"
                    id="performance-appraisal-submission-date"
                    label="कार्य सम्पादन मुल्याङ्कन बुझाएको मिति"
                    onChange={handleDateChange}
                    defaultDate={currentServiceDetails?.performanceAppraisalSubmissionDate}
                  />
                </Box>
              </Tooltip>
              {errors.performanceAppraisalSubmissionDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>}
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !currentServiceDetails && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {currentServiceDetails ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
