import { Box, Button, Grid, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { REQUIRED_FIELD } from '../../../../utils/constants';
import style from "../style";

export default function AddLeaveDaysDetails({ defaultValues, handleLeaveDetailsSubmit }) {
  const { register, handleSubmit, errors } = useForm();
  const classes = style();

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(handleLeaveDetailsSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.immediateOrFestivalLeaveTotalDays}
                label="भैपरी आउने पर्व बिदा"
                name="immediateOrFestivalLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.immediateOrFestivalLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.homeLeaveTotalDays}
                label="घर बिदा"
                name="homeLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.homeLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.sickLeaveTotalDays}
                label="बिरामी बिदा"
                name="sickLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.sickLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.pregnancyOrPregnancyCareLeaveTotalDays}
                label="प्रसूति बिदा/प्रसूती स्याहार बिदा"
                name="pregnancyOrPregnancyCareLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.pregnancyOrPregnancyCareLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.deathRitualLeaveTotalDays}
                label="किरिया बिदा"
                name="deathRitualLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.deathRitualLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.studyLeaveTotalDays}
                label="अध्ययन बिदा"
                name="studyLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.studyLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={defaultValues?.abnormalLeaveTotalDays}
                label="असाधारण बिदा"
                name="abnormalLeaveTotalDays"
                type="text"
                variant="outlined"
                inputProps={{ "step": "any" }}
                size="small"
                inputRef={register({ required: true })}
                fullWidth
              />
              {errors.abnormalLeaveTotalDays && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !defaultValues && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {defaultValues ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  )
}