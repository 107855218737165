import { makeStyles } from "@material-ui/core/styles";
const styles = makeStyles(theme => ({
  subPostHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between",
    borderBottom: "1px solid " + theme.palette.divider,
  },
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.divider,
  },
  row: {
    marginBottom: theme.spacing(1),
    position: "relative",
  },
  hiddenInput: {
    display: "none",
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  btnContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderTop: "1px solid " + theme.palette.divider,
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  }
}));
export default styles;