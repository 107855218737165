import { Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomModal from "../../../../components/modal/CustomModal";
import { REQUIRED_FIELD } from "../../../../utils/constants";

export default function AddPostModal({ showAddPostModal, handleAddPostModalClose, handleModalSubmit, ...props }) {
  const { errors, handleSubmit, reset, register } = useForm();

  useEffect(() => {
    !showAddPostModal && reset({});
  }, [showAddPostModal, reset])

  const onSubmit = data => {
    handleModalSubmit(data);
  };

  return (
    <CustomModal
      title="पद थप्नुहोस।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddPostModal}
      onModalClose={handleAddPostModalClose}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <TextField
            label="पद:"
            name="name"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.name}
            inputRef={register({
                required: true
              })}
            fullWidth
          />
          {errors.name && (<span className="error-message">{REQUIRED_FIELD}</span>)}
        </Grid>
      </Grid>
    </CustomModal>
  );

}