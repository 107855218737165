import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  successContainer: {
    background: "#008000",
    padding: theme.spacing(5) 
  },
  errorContainer: {
    background: "#FF6347",
    padding: theme.spacing(5)
  }
}));
export default styles;