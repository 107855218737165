import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { AppUtils } from "../../../../../utils/appUtils";
import { NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { AppMisc } from "../../../../../misc/appMisc";

export default function AwardAndTestimonialDetail({ awardAndTestimonialDetails }) {
  return (
    <Box p={2}>
      <TableContainer>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Educational Details">
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>क्र.स</TableCell>
              <TableCell>विभूषण,प्रशंसा पत्रको विवरण</TableCell>
              <TableCell>प्राप्त मिति</TableCell>
              <TableCell>विभूषण / प्रशंसा पत्र पाएको कारण</TableCell>
              <TableCell>सहुलियत</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {awardAndTestimonialDetails.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (
              awardAndTestimonialDetails.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                  <TableCell>{row.awardOrTestimonialDescription}</TableCell>
                  <TableCell>{AppMisc.getDateLabelFromMillisecond(row.dateReceived)}</TableCell>
                  <TableCell>{row.reasonToRecieve}</TableCell>
                  <TableCell>{row.convenience}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
