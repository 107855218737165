import { makeStyles } from "@material-ui/core/styles";
const styles = makeStyles(theme => ({
  scroll: {
    overflowX: "scroll"
  },
  searchField: {
    maxWidth: theme.spacing(16),
    height: theme.spacing(4),
    padding: theme.spacing(1),
    margin: theme.spacing(0.5)
  },
  tableHead: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  emptyMessage:{
    padding: theme.spacing(0.5),
    textAlign: "center",
  }
}));
export default styles;