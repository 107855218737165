import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettingData } from "../../../../../action/settingData";
import { AppMisc } from "../../../../../misc/appMisc";
import { DateUtils } from "../../../../../utils/dateUtils";
import { USER_INFO } from "../../../../../utils/constants"
import { SessionStorage } from "../../../../../utils/storage/sessionStorage"
import styles from "../style";

export default function BackCard({ staffInfo, ...props }) {
  const classes = styles();
  const userInfo = SessionStorage.getItem(USER_INFO) || {};
  const dispatch = useDispatch();
  const [addressInfo, setAddressInfo] = useState([]);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [appointedServiceInfo, setAppointedServiceInfo] = useState({})

  const defaultValues = useSelector(state => state.settingData)

  useEffect(() => {
    !defaultValues && dispatch(getSettingData())
  }, [defaultValues])

  function getAddressLabel(address) {
    if (address) {
      return `${AppMisc.getMunicipalityName(address.permanentAddressPalikaName)}-${address?.permanentAddressWardNumber && address?.permanentAddressWardNumber}, ${address?.permanentAddressGaunOrTole}`
    }
    return "-";
  }

  function getGenderLabel(genderInfo) {
    if (genderInfo) {
      let genderLabel = genderInfo[0] + genderInfo?.slice(1).toLowerCase();
      return genderLabel;
    }
    return "-";
  }

  useEffect(() => {
    setAddressInfo(staffInfo.addressDetail);
    setPersonalInfo(staffInfo.personalInfo || {});
    setAppointedServiceInfo(staffInfo.appointedServiceInfo || {});
  }, [staffInfo])

  return (
    <Box className={classes.cardWrapper}>
      <Box className={classes.card}>
        <Box className={classes.cardBody}>
          <Box className={classes.cardContentBack}>
            <Box>
              <Typography>Name: {personalInfo.fullNameInEnglish}</Typography>
              <Typography>Gender: {getGenderLabel(personalInfo.gender)}</Typography>
              <Typography>Designation: {appointedServiceInfo?.categoryAndLabel}</Typography>
              <Typography>Post: {AppMisc.getLabelFromValue(staffInfo.currentServiceInfo?.post, props.postOptions)}</Typography>
              <Typography>Office: {AppMisc.getMunicipalityName(userInfo?.officeName)}</Typography>
              <Typography>Blood Group: {staffInfo.othersInfo?.bloodGroup || "-"}</Typography>
              <Typography>Phone Number: {staffInfo.othersInfo?.mobileNumber || "-"}</Typography>
              <Typography>Birth Date (AD): {personalInfo.dateOfBirthInBS ? DateUtils.getAdDateFromBs(personalInfo.dateOfBirthInBS) : "-"}</Typography>
              <Typography>Citizenship Number: {personalInfo.citizenshipNumber}</Typography>
              <Typography>Address: {getAddressLabel(addressInfo)}</Typography>
            </Box>
            <Box className={classes.backLogoSignature}>
              {defaultValues?.stampUrl &&
                <>
                  <Typography>Office Stamp</Typography>
                  <Box paddingBottom="10px" className={classes.logo}>
                    <img className={classes.officeLogo} src={defaultValues?.stampUrl.absolutePath} alt="logo" />
                  </Box>
                </>
              }
              <Typography>Authorized Signature</Typography>
              <Box>
                {defaultValues?.signatureUrl &&
                  <img className={classes.signatureBack} src={defaultValues?.signatureUrl.absolutePath} alt="signature" />
                }
              </Box>
            </Box>
          </Box>
          <Box className={classes.cardLostText}>
            <Typography variant="caption">
              If anyone finds this identity card, please submit it to the concerned office, district administration office or police office.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}