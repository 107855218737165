
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import styles from "../../style";

export default function StaffCurrentServiceDetails({ categoryList = [], postOptions = [], subDepartmentList = [], ...props }) {
  const classes = styles();

  const findDepartmentName = (departmentId) => {
    return props.departmentList?.find(obj => obj.id === departmentId)?.name || "-"
  }

  const findSubDepartmentName = subDepartmentId => {
    return subDepartmentList.find(obj => obj.id === subDepartmentId)?.subDivisionName || "-"
  }

  return (
    <Box className={classes.root} p={2}>
      <Grid container>
        <TableContainer className={classes.currentServiceDetailsTable}>
          <Table size="small" className="table-bordered" aria-label="Staff Current Service Details">
            <TableBody>
              <TableRow>
                <TableCell>माथिल्लो निकाय: {props.currentServiceDetails && (props.currentServiceDetails.upperBody || "-")}</TableCell>
                <TableCell>जिल्ला: {props.currentServiceDetails && (props.currentServiceDetails.district || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>कार्यालय: {props.currentServiceDetails && (props.currentServiceDetails.office || "-")}</TableCell>
                <TableCell>सेवा परिमाण: {props.currentServiceDetails && (props.currentServiceDetails.serviceVolume || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>सेवा/समुह/उप-समूह: {props.currentServiceDetails && (props.currentServiceDetails.serviceOrGroup || "-")}</TableCell>
                <TableCell>सेवाको किसिम: {props.currentServiceDetails && (props.currentServiceDetails.serviceType || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>श्रेणी/तह: {AppMisc.getLabelFromValue(props.currentServiceDetails?.categoryAndLabel, categoryList)}</TableCell>
                <TableCell>पद: {AppMisc.getLabelFromValue(props.currentServiceDetails?.post, postOptions)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>लागू हुने मिति: {props.currentServiceDetails && (props.currentServiceDetails.applicationDateInBS || "-")}</TableCell>
                <TableCell>निर्णय मिति: {props.currentServiceDetails && (props.currentServiceDetails.decisionDateInBS || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>कार्यालयमा हाजिरी मिति: {props.currentServiceDetails && (props.currentServiceDetails.officeAttendanceDateInBS || "-")}</TableCell>
                <TableCell>हालको पदमा नियूक्ती: {props.currentServiceDetails && (props.currentServiceDetails.appointmentToCurrentPostDateInBS || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>विभागको नाम : {findDepartmentName(props.currentServiceDetails?.departmentId)}</TableCell>
                <TableCell>उपशाखाको नाम: {findSubDepartmentName(props.currentServiceDetails?.subDepartmentId)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>सम्पत्ति विवरण बुझाएको मिति: {props.currentServiceDetails && (props.currentServiceDetails?.propertyDetailsSubmissionDateInBS || "-")}</TableCell>
                <TableCell>कार्य सम्पादन मुल्याङ्कन बुझाएको मिति: {props.currentServiceDetails && (props.currentServiceDetails?.performanceAppraisalSubmissionDateInBS || "-")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
