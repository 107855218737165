import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Check, Close, Edit } from '@material-ui/icons'
import React from 'react'
import { AppMisc } from '../../../../misc/appMisc'
import { NO_RECORDS_FOUND } from '../../../../utils/constants'

export default function OfficeUserList(props) {
  function editRow(id) {
    props.editRow(id);
  }

  return (
    <TableContainer>
      <Table classes={{ root: "table-bordered" }} size="small" aria-label="Office User List">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>नाम</TableCell>
            <TableCell>ईमेल</TableCell>
            <TableCell>भूमिका</TableCell>
            <TableCell>सक्रिय／निष्क्रिय</TableCell>
            <TableCell>सम्पादन</TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {props.tableData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center" size="medium">{NO_RECORDS_FOUND}</TableCell>
            </TableRow>
          ) : (
            props.tableData.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.fullName}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{AppMisc.getUserRole(row.role)}</TableCell>
                <TableCell>{row.active === false ? (<Chip size="small" color="secondary" icon={<Close />} />) : (<Chip color="primary" size="small" icon={<Check />} />)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => editRow(row.id)}>
                    <Edit fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
