
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import styles from "../../style";

export default function StaffAddressDetails(props) {
  const classes = styles();

  return (
    <Box className={classes.root} p={2}>
      <Grid container>
        <TableContainer className={classes.addressDetailsTable}>
          <Typography className={classes.tableCaption} variant="subtitle2">स्थायी ठेगाना:</Typography>
          <Table size="small" className="table-bordered" aria-label="Staff Permanent Address Details">
            <TableBody>
              <TableRow>
                <TableCell>प्रदेश: {props.addressDetails && AppMisc.getProvinceName(props.addressDetails.permanentAddressProvince)}</TableCell>
                <TableCell>जिल्ला: {props.addressDetails && AppMisc.getDistrictName(props.addressDetails.permanentAddressDistrict)}</TableCell>
                <TableCell>गाउँ/नगरपालिका: {props.addressDetails && AppMisc.getMunicipalityName(props.addressDetails.permanentAddressPalikaName)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>वडा नं.: {props.addressDetails && (props.addressDetails.permanentAddressWardNumber || "-")}</TableCell>
                <TableCell>गाँउ/टोल: {props.addressDetails && (props.addressDetails.permanentAddressGaunOrTole || "-")}</TableCell>
                <TableCell>घर नं.: {props.addressDetails && (props.addressDetails.permanentAddressHomeNumber || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>टेलीफोन नं.: {props.addressDetails && (props.addressDetails.permanentAddressPhoneNumber || "-")}</TableCell>
                <TableCell colSpan={2}>अन्य संपर्क विवरण: {props.addressDetails && (props.addressDetails.permanentAddressOthersContactDetail || "-")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography className={classes.tableCaption} variant="subtitle2">अस्थायी ठेगाना:</Typography>
          <Table size="small" className="table-bordered" aria-label="Staff Temporary Address Details">
            <TableBody>
              <TableRow>
                <TableCell>प्रदेश: {props.addressDetails && AppMisc.getProvinceName(props.addressDetails.temporaryAddressProvince || "-")}</TableCell>
                <TableCell>जिल्ला: {props.addressDetails && AppMisc.getDistrictName(props.addressDetails.temporaryAddressDistrict || "-")}</TableCell>
                <TableCell>गाउँ/नगरपालिका: {props.addressDetails && AppMisc.getMunicipalityName(props.addressDetails.temporaryAddressPalikaName || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>वडा नं.: {props.addressDetails && (props.addressDetails.temporaryAddressWardNumber || "-")}</TableCell>
                <TableCell>गाँउ/टोल: {props.addressDetails && (props.addressDetails.temporaryAddressGaunOrTole || "-")}</TableCell>
                <TableCell>घर नं.: {props.addressDetails && (props.addressDetails.temporaryAddressHomeNumber || "-")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>टेलीफोन नं.: {props.addressDetails && (props.addressDetails.temporaryAddressPhoneNumber || "-")}</TableCell>
                <TableCell colSpan={2}>अन्य संपर्क विवरण: {props.addressDetails && (props.addressDetails.temporaryAddressOthersContactDetail || "-")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box >
  );
}
