import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import { Close, Create } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import PIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import AddLeaveDaysDetails from './helpers/AddLeaveDaysDetails';
import LeaveDetailsList from './helpers/LeaveDetailsList';
import style from "./style";

const LeaveDetails = () => {
  const classes = style();
  const [leaveDetailsList, setLeaveDetailsList] = useState({});
  const [editLeaveDaysDetails, setEditLeaveDaysDetails] = useState(false);

  useEffect(() => {
    getLeaveTypesList();
  }, [])

  const handleLeaveDaysDetailsEditCancel = () => {
    setEditLeaveDaysDetails(false);
  }

  const handleLeaveDaysDetailsEdit = () => {
    setEditLeaveDaysDetails(!editLeaveDaysDetails);
  }

  const getLeaveTypesList = () => {
    PIS.get(API_URL.leaveDetails)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setLeaveDetailsList(jsondata.data);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const onLeaveDaysDetailsSubmit = data => {
    data.id = leaveDetailsList?.id;
    PIS.post(API_URL.leaveDetails, data)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          getLeaveTypesList();
          setEditLeaveDaysDetails(false);
        }
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  return (
    <>
      <Box className={classes.subPostHeading}>
        <Typography variant="h6">जम्मा बिदा लिन मिल्ने विवरण</Typography>
      </Box>
      <Paper className={classes.paper}>
        <Box className={classes.paperHeader}>
          <Typography variant="h6">लिन मिल्ने बिदा विवरण</Typography>
          {editLeaveDaysDetails ?
            (
              <IconButton aria-label="cancel" onClick={handleLeaveDaysDetailsEditCancel}>
                <Close />
              </IconButton>
            ) : (
              <IconButton className="print-none" aria-label="edit" onClick={handleLeaveDaysDetailsEdit}>
                <Create />
              </IconButton>
            )
          }
        </Box>
        {
          editLeaveDaysDetails ?
            (
              <AddLeaveDaysDetails
                handleLeaveDetailsSubmit={onLeaveDaysDetailsSubmit}
                defaultValues={leaveDetailsList}
              />
            ) : (
              <LeaveDetailsList leaveDetailsList={leaveDetailsList} />
            )
        }
      </Paper>
    </>
  )
}

export default LeaveDetails;
