import { Box, Typography } from "@material-ui/core";
import React from "react";
import ChangePassword from "../../../components/change-password/ChangePassword";
import UserInfo from "./helpers/user-info/UserInfo";
import styles from "./style";

export default function UserProfile(props) {
  const classes = styles();

  return (
    <Box className={classes.profilePage}>
      <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
        <Typography variant="h6">प्रयोगकर्ता सेटिङ सम्पादन गर्नुहोस् ।</Typography>
      </Box>
      <Box>
        <UserInfo />
      </Box>
      <Box mt={2} py={2}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography variant="h6"> पासवर्ड परिवर्तन गर्नुहोस् ।</Typography>
        </Box>
        <ChangePassword />
      </Box>
    </Box>
  );
}
