import PIS, { API_URL } from "../api/api";
import AddAlertMessage from "../components/alert/Alert";
import { ERROR, SOMETHING_WENT_WRONG, USER_INFO } from "../utils/constants";
import { SAVE_USER_INFO } from "../utils/constants/redux";
import { SessionStorage } from "../utils/storage/sessionStorage";

export const getUserInfo = () => async (dispatch) => {
  try {
    let response = await PIS.get(API_URL.user);
    let jsondata = response.data;
    SessionStorage.setItem(USER_INFO, jsondata.data);
    if (jsondata) {
      dispatch({ type: SAVE_USER_INFO, payload: jsondata.data })
    }
  } catch (error) {
    AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
  }
}
