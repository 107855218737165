import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import CustomTable from "../../../../components/custom-table/CustomTable";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants";

export default function SubDepartmentsList({ tableData, onEditRow, onDeleteRow, ...props }) {
  const [departmentData, setDepartmentsData] = useState([]);

  const getDepartmentdata = () => {
    PIS.get(API_URL.department)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setDepartmentsData(jsondata.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  useEffect(() => {
    getDepartmentdata()
  }, [])

  function getDepartmentName(id) {
    return departmentData.find(data => data.id === id)?.name;
  }

  function buildColumns() {
    let columns = [
      {
        Header: "विभाग／शाखा को नाम",
        accessor: "departmentId",
        Cell: (row) => (
          <>
            {getDepartmentName(row.value)}
          </>
        )
      },
      {
        Header: "उप-शाखा/इकाइ",
        accessor: "subDivisionName",
      },
    ]
    if (props.showActionColumn) {
      columns.push({
        Header: "सम्पादन",
        accessor: "id",
        className: 'print-none',
        Cell: (row) => (
          <>
            <IconButton onClick={() => onEditRow(row.value)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onDeleteRow(row.value)} aria-label="delete">
              <Delete fontSize="small" color="secondary" />
            </IconButton>
          </>
        )
      })
    }
    return columns;
  }

  const columns = buildColumns();

  return (
    <CustomTable columns={columns} data={tableData} />
  );
}