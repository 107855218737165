import { Box, IconButton, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import { AppMisc } from "../../misc/appMisc";
import CustomTable from "../custom-table/CustomTable";

export default function UserList({ onEditRow, ...props }) {

  function buildColumns() {
    let columns = [
      {
        Header: "नाम",
        accessor: "fullName",
      },
      {
        Header: "ईमेल",
        accessor: "email",
      },
      {
        Header: "भूमिका",
        accessor: "role",
        Cell: (row) => (
          <Typography>{AppMisc.getUserRole(row.value)}</Typography>
        )
      },
    ]
    if (props.showActionColumn) {
      columns.push({
        Header: "सम्पादन",
        accessor: "id",
        className: 'print-none',
        Cell: (row) => (
          <Box className="print-none">
            <IconButton onClick={() => onEditRow(row.value)} aria-label="edit">
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        )
      })
    }
    return columns;
  }

  const columns = buildColumns();

  return (
    <>
      <CustomTable columns={columns} data={props.tableData} />
    </>
  );
}