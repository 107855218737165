import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import NepaliCalender from "../../../../components/nepali-calender";
import { PUNISHMENT_TYPES, REQUIRED_FIELD } from "../../../../utils/constants/index";
import styles from "./style";

const AddDepartmentalPunishmentDetails = ({ onDepartmentalPunishmentDetailsSubmit, modalDefaultValue }) => {
  const classes = styles();
  const { register, handleSubmit, watch, errors, reset, setValue } = useForm();

  useEffect(() => {
    register({ name: "punishmentAnnouncedDate" }, { required: true });
    register({ name: "sentenceReleaseDate" }, { required: true });
    register({ name: "punishmentType" }, { required: true })
  }, [register]);

  useEffect(() => {
    if (modalDefaultValue) {
      setValue("punishmentAnnouncedDate", modalDefaultValue.punishmentAnnouncedDate);
      setValue("sentenceReleaseDate", modalDefaultValue.sentenceReleaseDate);
      setValue("punishmentType", modalDefaultValue.punishmentType);
    }
  }, [])

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const handlePunishmentTypeChange = (name, value) => {
    setValue(name, value);
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onDepartmentalPunishmentDetailsSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs={3}>
              <CustomSelect
                options={PUNISHMENT_TYPES}
                defaultValue={modalDefaultValue?.punishmentType}
                placeholder="सजायको प्रकार"
                label="सजायको प्रकार"
                name="punishmentType"
                onChange={handlePunishmentTypeChange}
                creatable
              />
              {errors.punishmentType && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={3}>
              <Box>
                <NepaliCalender
                  defaultDate={modalDefaultValue?.punishmentAnnouncedDate}
                  id="punishmentAnnouncedDate"
                  name="punishmentAnnouncedDate"
                  label="सजायको आदेश मिति"
                  onChange={handleDateChange}
                />
                {errors.punishmentAnnouncedDate && <span className="error-message">{REQUIRED_FIELD}</span>}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={modalDefaultValue?.post}
                label="पद"
                name="post"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={modalDefaultValue?.label}
                label="तह"
                name="label"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={modalDefaultValue?.serviceOrGroup}
                label="सेवा समूह"
                name="serviceGroup"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={modalDefaultValue?.office}
                label="कार्यालय"
                name="office"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <NepaliCalender
                defaultDate={modalDefaultValue?.sentenceReleaseDate}
                id="sentenceReleaseDate"
                name="sentenceReleaseDate"
                label="सजाय फुकुवा मिति"
                onChange={handleDateChange}
              />
              {errors.sentenceReleaseDate && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !modalDefaultValue && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {modalDefaultValue ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  )
}

export default AddDepartmentalPunishmentDetails;
