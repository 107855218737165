import { Box, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Link, TextField } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PIS, { API_URL } from "../../../api/api";
import logo from "../../../assets/img/logo.png";
import AddAlertMessage from "../../../components/alert/Alert";
import Spinner from "../../../components/loader/Loader";
// context
import { useUserDispatch } from "../../../context/UserContext";
import { ENTER_VALID_EMAIL, IS_SESSION_EXPIRED, LOGIN_FAILURE, LOGIN_SUCCESS, REQUIRED_FIELD, SESSION_EXPIRED, SOMETHING_WENT_WRONG, USER_ROLE } from "../../../utils/constants/index";
import { Cookies } from "../../../utils/storage/cookies";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import styles from "./style";

export default function LoginForm(props) {
  const classes = styles();
  const { register, handleSubmit, errors } = useForm();

  // global
  var userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (SessionStorage.getItem(IS_SESSION_EXPIRED) === "true") {
      AddAlertMessage({
        type: "error",
        message: SESSION_EXPIRED
      });
      SessionStorage.removeItem(IS_SESSION_EXPIRED);
    }
  }, []);

  const onSubmit = data => {
    setIsLoading(true);
    PIS.post(API_URL.login, data)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.type === "success") {
          let userRole = Cookies.readCookie(USER_ROLE);
          !userRole && data && Cookies.writeCookie(USER_ROLE, data.role, 6 * 24);
          userDispatch({ type: LOGIN_SUCCESS });
          props.history.push("/");
        } else {
          userDispatch({ type: LOGIN_FAILURE });
          AddAlertMessage({ type: data.type, message: data.message });
        }
      })
      .catch(error => {
        setIsLoading(false);
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
        userDispatch({ type: LOGIN_FAILURE });
      });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box textAlign="center" my={3}>
        <img src={logo} alt="PIS Logo" width="124" />
        <Box fontSize="h5.fontSize">कर्मचारी सूचना व्यवस्थापन प्रणाली</Box>
      </Box>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="ईमेल"
                margin="normal"
                variant="outlined"
                name="email"
                inputRef={register({
                  required: true,
                  pattern: /\S+@\S+\.\S+/
                })}
              />
              {errors.email && errors.email.type === "required" && (<span className="error-message">{REQUIRED_FIELD}</span>)}
              {errors.email && errors.email.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                label="पासवर्ड"
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                name="password"
                inputRef={register({
                  required: true
                })}
              />
              {errors.password && (
                <span className="error-message">{REQUIRED_FIELD}</span>
              )}
            </Grid>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="मलाई सम्झनुहोस्।"
              name="rememberMe"
              inputRef={register}
            />
            <Grid item xs={12} className={classes.loginBtnContainer}>
              {isLoading ? (
                <Spinner />
              ) : (
                  <Button
                    endIcon={<ExitToAppIcon />}
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    साइन इन गर्नुहोस्
                  </Button>
                )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Box textAlign="right" pt={1}>
        <Link
          href="/forget-password"
          underline="none"
          color="textSecondary"
          className={classes["forget-password-label"]}
        >
          पासवर्ड बिर्सनु भयो ?
        </Link>
      </Box>
    </Container>
  );
}
