
import { Avatar, Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import React, { forwardRef, useEffect, useState } from "react";
import PIS, { API_URL } from "../../../../../api/api";
import AddAlertMessage from "../../../../../components/alert/Alert";
import { AppUtils } from "../../../../../utils/appUtils";
import { ERROR, FILE_SIZE_EXCEED, GENDER_OPTIONS, ID, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../../utils/constants";
import styles from "../../style";
import { DateUtils } from "../../../../../utils/dateUtils";

function StaffPersonalDetails(props, ref) {
  const classes = styles();
  const staffId = AppUtils.getUrlParam(ID);
  const [photoUrl, setPhotoUrl] = useState();

  useEffect(() => {
    setPhotoUrl(props.personalDetails?.photo?.absolutePath);
  }, [props.personalDetails])

  function handlePhotoChange(e) {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (10 * 1024 * 1024);
      if (fileSizeValid) {
        // call photo upload API
        var postdata = new FormData();
        postdata.append("staffId", staffId)
        postdata.append("photo", file);

        PIS.post(API_URL.staff + "/upload-photo", postdata)
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              props.getStaffData();
              setPhotoUrl(jsondata.data);
            };
            AddAlertMessage({
              type: jsondata.type,
              message: jsondata.message,
            });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  function getGenderLabel(gender) {
    return gender ? GENDER_OPTIONS.find(obj => obj.value === gender).label : "-";
  }

  return (
    <Box className={classes.root} p={2} ref={ref}>
      <Grid container>
        <Grid item xs={9}>
          <TableContainer className={classes.personalDetailsTable}>
            <Table size="small" className="table-bordered" aria-label="Personal Details">
              <TableBody>
                <TableRow>
                  <TableCell>कर्मचारी संकेत नं.: {props.personalDetails && (props.personalDetails.staffCodeNumber || "-")}</TableCell>
                  <TableCell>लिङ्ग: {getGenderLabel(props.personalDetails && props.personalDetails.gender)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>पुरा नाम नेपालीमा: {props.personalDetails && (props.personalDetails.fullNameInNepali || "-")}</TableCell>
                  <TableCell>उमेर: {props.personalDetails && (props.personalDetails.age || "-")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>पुरा नाम अङ्ग्रेजीमा: {props.personalDetails && (props.personalDetails.fullNameInEnglish || "-")}</TableCell>
                  <TableCell>ईमेल: {props.personalDetails && (props.personalDetails.email || "-")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>जन्म मिति (बि.सं.): {props.personalDetails && (props.personalDetails.dateOfBirthInBS || "-")}</TableCell>
                  <TableCell>जन्म मिति (ई.सं.): {props.personalDetails && (props.personalDetails.dateOfBirthInBS ? DateUtils.getAdDateFromBs(props.personalDetails.dateOfBirthInBS) : "-")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>नागरिकता नं.: {props.personalDetails && (props.personalDetails.citizenshipNumber || "-")}</TableCell>
                  <TableCell>राष्ट्रीय परिचय पत्र नं.: {props.personalDetails && (props.personalDetails.nationalIdentityCardNumber || "-")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3} className={classes.avatarContainer}>
          <Box>
            <Avatar variant="rounded" alt="Staff Photo" src={photoUrl} className={classes.largeAvatar} />
          </Box>
          {!props.hideUploadButton && (
            <Box className={`print-none ${classes.filesContainer}`}>
              <input name="photo" accept="image/*" className={classes.hiddenInput} id="photo" type="file" onChange={handlePhotoChange} />
              <label htmlFor="photo">
                <Tooltip title="कृपया फोटो चयन गर्दा, दुबै कान देखिने पासपोर्ट साइजको 10MB भन्दा कम साइज भएको फोटो चयन गर्नुहोस्।" placement="top" arrow>
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </label>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default forwardRef(StaffPersonalDetails)
