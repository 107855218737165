import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, Publish as PublishIcon } from '@material-ui/icons';
import React from "react";
import { AppMisc } from "../../../../../misc/appMisc";
import { EXPENSES_TYPE, NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { DateUtils } from "../../../../../utils/dateUtils";
import styles from "../../style";


const StaffMedicalExpensesDetails = ({ handleMedicalExpensesDetailsEdit, handleMedicalExpensesFileChange, getMedicalExpensesRowId, getMedicalExpensesRowDeleteId, getMedicalExpensesDocumentsList, medicalExpensesDetails }) => {
  const classes = styles();

  return (
    <Box p={2}>
      <TableContainer >
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" >
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>उपचार</TableCell>
              <TableCell>उपचार खर्च लिएको मिति</TableCell>
              <TableCell>लिएको रकम</TableCell>
              <TableCell>प्रमाणित गर्ने अधिकारी</TableCell>
              <TableCell >सम्बन्धित कागजात </TableCell>
              <TableCell >कैफियत</TableCell>
              {AppMisc.isRoleStaff() && medicalExpensesDetails.length !== 0 && <TableCell>सम्पादन</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {
              medicalExpensesDetails?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              ) : (
                medicalExpensesDetails.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.expensesType ? AppMisc.getLabelFromValue(row.expensesType, EXPENSES_TYPE) : "-"}</TableCell>
                    <TableCell>{row.amountTakenDate ? DateUtils.getDateFromMilliseconds(row.amountTakenDate) : "-"}</TableCell>
                    <TableCell>{row.amountTaken ? row.amountTaken : "-"}</TableCell>
                    <TableCell>{row.verifiedBy ? row.verifiedBy : "-"}</TableCell>
                    <TableCell>
                      <Box p={2}>
                        {
                          row.documents?.length ? (
                            <Grid className="print-none" alignItems="center" spacing={2}>
                              {getMedicalExpensesDocumentsList(row.documents, row.id)}
                            </Grid>
                          ) : "-"
                        }
                      </Box>
                    </TableCell>
                    <TableCell>{row.remark ? row.remark : "-"}</TableCell>
                    {AppMisc.isRoleStaff() && <TableCell>
                      <Box className="print-none" display="flex" justifyContent="center">
                        <IconButton className={classes.actionBtn} onClick={() => handleMedicalExpensesDetailsEdit(row.id)} aria-label="edit">
                          <CreateIcon fontSize="small" />
                        </IconButton>
                        <Box className={classes.paperActionButtons}>
                          <input id={`uploadMedicalExpensesDocuments~${row.id}`} className={classes.hiddenInput} type="file" accept=".pdf,.doc,.ppt,.pptx,.txt,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.gif,.bmp,.ico" onClick={(e) => { e.currentTarget.value = null }} onChange={(event) => handleMedicalExpensesFileChange(event)} multiple />
                          <label htmlFor={`uploadMedicalExpensesDocuments~${row.id}`}>
                            <Tooltip title="सम्बन्धित कागजातहरु अपलोड गर्नुहोस्।" arrow placement="top">
                              <IconButton onClick={() => getMedicalExpensesRowId(row.id)} aria-label="upload files" component="span">
                                <PublishIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </Box>
                        <IconButton className={classes.actionBtn} color="secondary" onClick={() => getMedicalExpensesRowDeleteId(row.id)} aria-label="delete">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>}
                  </TableRow>
                ))
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default StaffMedicalExpensesDetails;
