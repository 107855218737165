import React, { useEffect, useState } from 'react'
import PIS, { API_URL } from '../../../../api/api';
import AddAlertMessage from '../../../../components/alert/Alert';
import CustomSelect from '../../../../components/custom-select/CustomSelect'
import { ERROR, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from '../../../../utils/constants';

export default function CategorySelect(props) {
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState();

  useEffect(() => {
    getAllCategoriesList();
  }, [])

  useEffect(() => {
    setShowErrorMessage(Boolean(props.error))
  }, [props.error])

  const getAllCategoriesList = () => {
    PIS.get(API_URL.category)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setCategoryOptions((jsondata.data || []).map(item => {
            return { value: item.id, label: item.category }
          }))
        }
        else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <CustomSelect
        label="श्रेणी/तह"
        name="categoryAndLabel"
        options={categoryOptions || []}
        defaultValue={categoryOptions && props.defaultValue}
        onChange={props.onChange}
        creatable
      />
      {showErrorMessage && (<span className="error-message">{REQUIRED_FIELD}</span>)}
    </>
  )
}
