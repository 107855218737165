import React, { useEffect, useState } from 'react'
import PIS, { API_URL } from '../../../../api/api';
import AddAlertMessage from '../../../../components/alert/Alert';
import CustomSelect from '../../../../components/custom-select/CustomSelect'
import { ERROR, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from '../../../../utils/constants';

export default function PostSelect(props) {
  const [postOptions, setPostOptions] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    getAllPostList()
  }, [])

  useEffect(() => {
    setShowErrorMessage(Boolean(props.error))
  }, [props.error])

  const getAllPostList = () => {
    PIS.get(API_URL.post)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPostOptions((jsondata.data || []).map(item => {
            return { value: item.id, label: item.name }
          }));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  return (
    <>
      <CustomSelect
        label="पद"
        name="post"
        options={postOptions || []}
        defaultValue={postOptions && props.defaultValue}
        onChange={props.onChange}
        creatable
      />
      {showErrorMessage && (<span className="error-message">{REQUIRED_FIELD}</span>)}
    </>
  )
}
